import React, { Component } from 'react';

import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import CommonButton from '../../common/CommonButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { EMPTY_VALUE, MT_UNIT } from '../../../common/constants';
import { required } from '../../../common/validators';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import { raiseAmendRequest, confirmAmend, rejectAmend } from '../../../actions/companies/contracts';
import { isLoading } from '../../../actions/main';
import get from 'lodash/get';
import { RejectionReasonDialog } from '../../rejections/RejectionReasonDialog';
import {
  getDaysToAdd,
  getGradeName,
  getWeekDays,
  isShallowEqual,
  nextEndDayFrom,
  combineObjectKeysWithMultiValue,
  compareObjectWithNonEqualValues,
  removeObjectValues,
  getCountryFormats,
  getCountryConfig,
  getCountryCurrency,
} from '../../../common/utils';
import moment from 'moment';
import isNaN from 'lodash/isNaN';
import camelCase from 'lodash/camelCase';
import mapValues from 'lodash/mapValues';
import some from 'lodash/some';
import isArray from 'lodash/isArray';
import filter from 'lodash/filter';
import set from 'lodash/set';
import CardMultivalue from '../../common/CardMultiValue';
import Tooltip from "../../../common/Tooltip";

const styles = () => ({
  subTitle: {
    fontSize: 15,
    fontWeight: 600,
    color: '#112c42',
  },
  columnTitle:{
    fontSize: 15,
    fontWeight: 600,
    textAlign: 'center',
    border: '1px solid #000',
    width: '35%'
  },
  listItem: {
    display: 'block',
    height: 75,
    padding: 0,
  },
  secondaryListItem: {
    padding: 0,
  },
  primaryListItem: {
    padding: 0,
  },
});

const REQUIRED_FIELDS = 2;

class AmendContract extends Component {
  constructor(props){
    super(props);

    this.classes = this.props.classes;
    this.tonnage = get(this.props.contract, 'tonnage');
    this.price = get(this.props.contract, 'price');
    this.contractId = get(this.props.contract, 'id');
    this.amendRequestTonnage = get(this.props.contract, 'amendedTonnage');
    this.amendRequestPrice = get(this.props.contract, 'amendedPrice');
    this.isAmendRequestPending = get(this.props.contract, 'isAmendRequestPending');
    this.amendable = get(this.props.contract, 'amendable');

    this.state = {
      countryConfig: getCountryConfig(),
      countryFormats: getCountryFormats(),
      rejectDialogOpen: {
        value: false,
        validators: [],
        errors: [],
      },
      amendedTonnage: {
        value: '',
        validators: [required()],
        errors: [],
      },
      amendedPrice: {
        value: '',
        validators: [required()],
        errors: [],
      },
      rejectionReason: {
        value: '',
        validators: [],
        errors: [],
      },
    };

    this.contractValueRepresentation = this.contractValueRepresentation.bind(this);
    this.handleTextFieldChange = this.handleTextFieldChange.bind(this);
    this.addContractAcceptanceRequestForAmend = this.addContractAcceptanceRequestForAmend.bind(this);
    this.confirmAmendRequestWrapper = this.confirmAmendRequestWrapper.bind(this);
    this.rejectAmendRequestWrapper = this.rejectAmendRequestWrapper.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.closeSidebar = this.props.closeSidebar;
    this.raiseAmendRequest = this.props.raiseAmendRequest;
    this.confirmAmendRequest = this.props.confirmAmendRequest;
    this.rejectAmendRequest = this.props.rejectAmendRequest;
    this.isLoading = this.props.isLoading;
  }

  amendViedGrid = (payload, sectionName) => (
    <React.Fragment>
      <CardMultivalue
        payload={payload}
        sectionName={sectionName}
        subTitleClass={this.props.classes.subTitle}
        showContract={this.props.showCurrentContract}
      />
    </React.Fragment>
  );

  handleRejectClickOpen = () => {
    const newState = { ...this.state };
    newState.rejectDialogOpen.value = true;
    newState.rejectionReason.validators = [required()];
    if(!newState.rejectionReason.value){
      newState.rejectionReason.errors = [];
    }
    this.setState(newState);
  };

  handleRejectClose = () => {
    this.setState({ rejectDialogOpen: { errors: [], value: false, validators: [] } });
  };

  handleRejectSubmit = event => {
    this.setReasonErrors();
    const data = { rejectionReason: this.state.rejectionReason.value };
    if (this.state.rejectionReason.errors.length === 0) {
      this.rejectAmendRequestWrapper(data);
    }
    event.preventDefault();
  };

  handleReasonChange = event => {
    const value = event.target.value;
    const newState = {...this.state};
    newState.rejectionReason.value = value;
    this.setState(newState, () => this.setReasonErrors());
  };

  setReasonErrors(errors) {
    const newState = {...this.state};
    newState.rejectionReason.errors = errors || this.getReasonErrors();
    this.setState(newState);
  }

  getReasonErrors() {
    const errors = [];
    const value = get(this.state, `rejectionReason.value`);
    const validators = get(this.state, `rejectionReason.validators`, []);
    validators.forEach(validator => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    return errors;
  }

  handleTextFieldChange(event){
    const newState = {...this.state};
    set(newState, event.target.id + '.value', event.target.value);
    this.setState(newState);
  }

  contractValueRepresentation(tonnage, price) {
    const _val = parseFloat(tonnage) * parseFloat(price);
    const { contract } = this.props;
    return isNaN(_val) ? '-' :  `${contract?.currency || getCountryCurrency()} ${parseFloat(_val).toFixed(2)}`;
  }

  addContractAcceptanceRequestForAmend(){
    this.setFieldErrors();
    if (this.isFormValid()){
      const payload = {
        tonnage: this.state.amendedTonnage.value || this.tonnage,
        price: this.state.amendedPrice.value || this.price,
      };
      this.isLoading('contractDetail');
      this.closeSidebar();
      this.raiseAmendRequest(this.contractId, payload);
    }
  }

  confirmAmendRequestWrapper(){
    this.closeSidebar();
    this.confirmAmendRequest(this.contractId, has(this.props, 'reload') ? this.props.reload : true);
  }

  rejectAmendRequestWrapper(data){
    this.closeSidebar();
    this.rejectAmendRequest(this.contractId, data, has(this.props, 'reload') ? this.props.reload : true);
  }

  handleBlur(event){
    const newState = {...this.state};
    this.getFieldErrors(newState[event.target.id], newState);
    this.setState(newState);
  }

  setFieldErrors(){
    const newState = {...this.state};
    forEach(newState, (field) => {
      this.getFieldErrors(field, newState);
    });
    this.setState(newState);
  }

  getFieldErrors(field, currState){
    field.errors = [];
    if (has(field, 'value') && has(field, 'validators')){
      field.validators.forEach((validator) => {
        if (validator.isInvalid(field.value)){
          field.errors.push(validator.message);
        }
      });
    }
    let invalidFields = 0;
    forEach(currState, (stateField) => {
      if (has(stateField, 'value') && has(stateField, 'validators')){
        stateField.validators.forEach((validator) => {
          if (validator.isInvalid(stateField.value)){
            invalidFields += 1;
          }
        });
      }
    });
    if (invalidFields < REQUIRED_FIELDS){
      forEach(currState, (stateField) => {
        stateField.errors = [];
      });
    }
  }

  isFormValid(){
    let formValid = true;
    const currentState = {...this.state};
    forEach(currentState, (field) => {
      if (field.errors.length !== 0){
        formValid = false;
      }
    });
    return formValid;
  }

  getSectionAsPerGrid = (payload, sectionName) => {
    return (
      <div className="col-md-12 amend-section">
        <Typography variant="subheading" className={this.classes.subTitle}>
          {sectionName}
        </Typography>
        <ul>
          {Object.keys(payload).map(function(key, index) {
             return (
               <li key={index}>
                 <Tooltip
                   className="field-label ellipses"
                   tooltipText={key}
                   textContent={key}
                 />
                 {<Tooltip
                    className="field-value ellipses"
                    tooltipText={payload[key]}
                    textContent={payload[key]}
                 />}
               </li>
             );
           })}
        </ul>
      </div>
    );
  };

  getConsigneesDetails(contract, consigneesWithSites){
    const consigneeDetails = {};
    let consignees = consigneesWithSites;
    if(!isEmpty(consignees)) {
      if (!isArray(consignees)) {
        consignees = [consignees];
      }
      forEach(consignees, (consignee, index) => {
        const consigneeName = get(consignee, 'handler.entity') == 'farm' ? get(consignee, 'handler.displayName') : get(consignee, 'handler.name');
        const consigneeKey = consignees.length > 1 ? 'Delivery Site' + (index + 1) : 'Delivery Site';
        consigneeDetails[consigneeKey] = consigneeName || EMPTY_VALUE;

        if (!isEmpty(consignee.sites)) {
          forEach(consignee.sites, (site, siteIndex) => {
            const siteKey = consignee.sites.length > 1 ? 'Delivery Storage' + (siteIndex + 1) : 'Delivery Storage';
            var ld = site.ld;
            if (!ld) {
              ld = get(site, 'location.ld');
            }
            var locationName = get(site, 'location.name');
            if (ld) {
              locationName += ' (' + get(contract, 'siteLdLabel', 'LD') + ': ' + ld + ')';
            }
            consigneeDetails[siteKey] = locationName || EMPTY_VALUE;
          });
        } else {
          consigneeDetails['Delivery Storage'] = EMPTY_VALUE;
        }
      });
    }
    return consigneeDetails;
  }

  getConsignorsDetails(contract, consignorWithSites){
    let consignors = consignorWithSites;
    const consignorDetails = {};
    if(!isEmpty(consignors)) {
      if (!isArray(consignors)) {
        consignors = [consignors];
      }
      forEach(consignors, (consignor, index) => {
        const consignorName = get(consignor, 'handler.entity') == 'farm' ? get(consignor, 'handler.displayName') : get(consignor, 'handler.name');
        const consignorKey = consignors.length > 1 ? 'Pickup Site' + (index + 1) : 'Pickup Site';
        consignorDetails[consignorKey] = consignorName || EMPTY_VALUE;

        if (!isEmpty(consignor.sites)) {
          forEach(consignor.sites, (site, siteIndex) => {
            const siteKey = consignor.sites.length > 1 ? 'Pickup Storage' + (siteIndex + 1) : 'Pickup Storage';
            var ld = site.ld;
            if (!ld) {
              ld = get(site, 'location.ld');
            }
            var locationName = get(site, 'location.name');
            if (ld) {
              locationName += ' (' + get(contract, 'consignorSiteLdLabel', 'LD') + ': ' + ld + ')';
            }
            consignorDetails[siteKey] = locationName || EMPTY_VALUE;
          });
        } else {
          consignorDetails['Pickup Storage'] = EMPTY_VALUE;
        }
      });
    }
    return consignorDetails;
  }

  getSiteNames(sites, showLD = false) {
    const locations = [];
    sites.forEach(site => {
      var locationName = site.location.name;
      if (showLD) {
        const ld = get(site, 'location.ld');
        if (ld) {
          locationName += ' (LD: ' + ld + ')';
        }
      }
      locations.push(locationName);
    });
    if (!isEmpty(locations)) {
      return locations.join(', ');
    } else {
      return null;
    }
  }

  contractValue(contract) {
    const tonnage = get(contract, 'tonnage');
    const price = get(contract, 'price');
    let _val = parseFloat(tonnage) * parseFloat(price);
    const currCarry = this.currCarry(contract);
    if (currCarry) {
      _val += parseFloat(currCarry);
    }
    return isNaN(_val) ? null : parseFloat(_val).toFixed(2);
  }

  maxContractValue(contract) {
    const tonnage = get(contract, 'tonnage');
    const price = get(contract, 'price');
    var _val = parseFloat(tonnage) * parseFloat(price);
    if (this.maxCarry(contract)) {
      _val += parseFloat(this.maxCarry(contract));
    }
    return isNaN(_val) ? null : parseFloat(_val).toFixed(2);
  }

  maxCarry(contract) {
    var periodMultiplier = null;
    const carryEndDate = get(contract, 'carryEndDate');
    const carryStartDate = get(contract, 'carryStartDate');
    const carryFrequency = get(contract, 'carryFrequency');
    if (carryEndDate) {
      if (carryFrequency === 'Monthly') {
        periodMultiplier = Math.ceil(moment(carryEndDate).diff(moment(carryStartDate), 'months', true));
      } else if (carryFrequency === 'Fortnightly') {
        periodMultiplier = Math.ceil(moment(carryEndDate).diff(moment(carryStartDate), 'weeks', true) / 2);
      } else if (carryFrequency === 'Weekly') {
        periodMultiplier = Math.ceil(moment(carryEndDate).diff(moment(carryStartDate), 'weeks', true));
      }
    }
    const tonnage = get(contract, 'tonnage');
    const carryRate = get(contract, 'carryRate');
    const maxCarryValue = parseFloat(tonnage) * parseFloat(carryRate) * periodMultiplier;
    return isNaN(maxCarryValue) ? null : parseFloat(maxCarryValue).toFixed(2);
  }

  currCarry(contract) {
    var periodMultiplier = null;
    const today = moment().format('YYYY-MM-DD');
    const carryEndDate = get(contract, 'carryEndDate');
    const carryStartDate = get(contract, 'carryStartDate');
    const carryFrequency = get(contract, 'carryFrequency');
    const endDate = carryEndDate && carryEndDate < today ? carryEndDate : today;

    if (endDate) {
      if (carryFrequency === 'Monthly') {
        periodMultiplier = Math.ceil(moment(endDate).diff(moment(carryStartDate), 'months', true));
      } else if (carryFrequency === 'Fortnightly') {
        periodMultiplier = Math.ceil(moment(endDate).diff(moment(carryStartDate), 'weeks', true) / 2);
      } else if (carryFrequency === 'Weekly') {
        periodMultiplier = Math.ceil(moment(endDate).diff(moment(carryStartDate), 'weeks', true));
      }
    }
    const tonnage = get(contract, 'tonnage');
    const carryRate = get(contract, 'carryRate');
    const currCarryValue = parseFloat(tonnage) * parseFloat(carryRate) * periodMultiplier;
    return isNaN(currCarryValue) || currCarryValue < 0 ? 0 : parseFloat(currCarryValue).toFixed(2);
  }

  estimatedPaymentDueDate(amendedDetails) {
    const selectedPaymentTerm = get(amendedDetails,'paymentTermId') || get(this.props.contract,'paymentTerm');
    const buyer = get(amendedDetails,'buyer') || get(this.props.contract,'buyer');
    var val = null;
    if (has(selectedPaymentTerm, 'id') && buyer) {
      let startOfWeek = get(this.props.contract, 'buyer.company.startOfWeek');
      let endOfWeek = get(this.props.contract, 'buyer.company.endOfWeek');

      const startFrom = camelCase(selectedPaymentTerm.startFrom);
      const paymentStartFromDate = get(amendedDetails,startFrom) || get(this.props.contract, startFrom);
      const periodType = get(selectedPaymentTerm, 'periodType');
      if(periodType == 'weeks') {
        const nextFridayFromDate = nextEndDayFrom(paymentStartFromDate, endOfWeek);
        val = Object.prototype.toString.call(nextFridayFromDate) == '[object Object]' && nextFridayFromDate.add(selectedPaymentTerm.period, 'days').format(this.state.countryFormats.date);
      }
      else if (periodType == 'months') {
        val = moment(paymentStartFromDate).endOf('month').add(selectedPaymentTerm.period, 'days').format(this.state.countryFormats.date);
      }
      else if (periodType == 'days') {
        if(selectedPaymentTerm.name.includes('Business Days')) {
          const startDay = moment(paymentStartFromDate).weekday();
          const weekdays = getWeekDays(startOfWeek, endOfWeek);
          if(startDay || startDay == 0) {
            const daysToAdd = getDaysToAdd(startDay, selectedPaymentTerm.period, weekdays);
            val = moment(paymentStartFromDate).add(daysToAdd, 'days').format(this.state.countryFormats.date);
          }
        } else {
          val = moment(paymentStartFromDate)
            .add(selectedPaymentTerm.period, 'days')
            .format(this.state.countryFormats.date);
        }
      }
      else {
        val = selectedPaymentTerm.name;
      }
      return val === 'Invalid date' ? null : val;
    } else {
      return null;
    }
  }

  isValuationDetailsEqual(valuationDetails, amendedValuationDetails) {
    var isValuationDetailsEqual = true;
    if (
      get(valuationDetails, 'Contract Value') != get(amendedValuationDetails, 'Contract Value') ||
      get(valuationDetails, 'Max Contract Value') != get(amendedValuationDetails, 'Max Contract Value') ||
      get(valuationDetails, 'Est Payment Due Date') != get(amendedValuationDetails, 'Est Payment Due Date') ||
      get(valuationDetails, 'Current Valuation') != get(amendedValuationDetails, 'Current Valuation') ||
      (get(amendedValuationDetails, 'EPR Value') && get(amendedValuationDetails, 'EPR Value') != get(valuationDetails, 'EPR Value')) ||
      (get(amendedValuationDetails, 'Central Levy') && get(amendedValuationDetails, 'eprValue') != get(valuationDetails, 'Central Levy'))
    ) {
      isValuationDetailsEqual = false;
    }
    return isValuationDetailsEqual;
  }

  getDeliveryDetails = (contract , currentDetails) => {
    let deliveryStartDate = get(currentDetails, 'deliveryStartDate') || get(contract, 'deliveryStartDate');
    deliveryStartDate = deliveryStartDate && moment(deliveryStartDate).format(this.state.countryFormats.date);
    let basicDetails = {};
    this.appendField(basicDetails, currentDetails, 'deliveryOnus', 'Delivery Onus');
    if (deliveryStartDate) basicDetails['Delivery Start Date'] = deliveryStartDate;
    let deliveryEndDate = get(currentDetails, 'deliveryEndDate') || get(contract, 'deliveryEndDate');
    deliveryEndDate = deliveryEndDate && moment(deliveryEndDate).format(this.state.countryFormats.date);
    if (deliveryEndDate) basicDetails['Delivery End Date'] = deliveryEndDate;

    const consigneeDetails = get(currentDetails, 'consignees') && this.getConsigneesDetails(contract, get(currentDetails, 'consignees'));
    const consignorDetails = get(currentDetails, 'consignors') && this.getConsignorsDetails(contract, get(currentDetails, 'consignors'));

    return {...basicDetails, ...consigneeDetails, ...consignorDetails};
  };

  isDeliveryDetailsChanged() {
    const amendedDetails = get(this.props.amendDetails, 'amended') || get(this.props.contract, 'amendedDetails.amended');
    return some(['deliveryOnus', 'consignors', 'deliveryStartDate', 'deliveryEndDate', 'consignees'], field => {
      return has(amendedDetails, field);
    });
  }

  appendField(data, amendedDetails, field, fieldLabel) {
    const fieldData = get(amendedDetails, field);
    if (fieldData) {
      data[fieldLabel] = fieldData;
    }
    return data;
  }

  getAmendedDeliveryDetails = (contract ,amendedDetails) => {
    const deliveryStartDate = get(amendedDetails, 'deliveryStartDate') && moment(get(amendedDetails, 'deliveryStartDate')).format(this.state.countryFormats.date);
    let basicDetails = {};
    this.appendField(basicDetails, amendedDetails, 'deliveryOnus', 'Delivery Onus');
    if (deliveryStartDate) basicDetails['Delivery Start Date'] = deliveryStartDate;
    const deliveryEndDate = get(amendedDetails, 'deliveryEndDate') && moment(get(amendedDetails, 'deliveryEndDate')).format(this.state.countryFormats.date);
    if (deliveryEndDate) basicDetails['Delivery End Date'] = deliveryEndDate;

    const consigneeDetails = get(amendedDetails, 'consignees') && this.getConsigneesDetails(contract, get(amendedDetails, 'consignees'));
    const consignorDetails = get(amendedDetails, 'consignors') && this.getConsignorsDetails(contract, get(amendedDetails, 'consignors'));

    return {...basicDetails, ...consigneeDetails, ...consignorDetails};
  };

  hasQuantityBasedCommodity = () => Boolean(get(this.props.contract, 'commodity.isQuantityBased'))
  quantityLabel = () => get(this.props.contract, 'commodity.quantityLabel')

  render() {
    const fieldSizeUnit = this.state.countryConfig.farmField.sizeUnit
    const estimatedYieldUnit = this.props.contract?.contract?.commodity?.priceUnit
    const amendedDetails = get(this.props, 'amendDetails.amended') || get(this.props.contract, 'amendedDetails.amended');
    let currentDetails = get(this.props, 'amendDetails.current') || get(this.props.contract, 'amendedDetails.current');
    let sellerPayload, buyerPayload = {};
    sellerPayload = {
      NGR: get(currentDetails, 'seller.ngrId') ? get(currentDetails, 'seller.ngrId.ngrNumber') : get(currentDetails, 'seller.ngrId'),
      Contact: get(currentDetails, 'seller.contactId') ? get(currentDetails, 'seller.contactId.name') : get(currentDetails, 'seller.contactId'),
    };

    buyerPayload = {
      NGR: get(currentDetails, 'buyer.ngrId') ? get(currentDetails, 'buyer.ngrId.ngrNumber') : get(currentDetails, 'buyer.ngrId'),
      Contact: get(currentDetails, 'buyer.contactId') ? get(currentDetails, 'buyer.contactId.name') : get(currentDetails, 'buyer.contactId'),
    };
    const contract = this.props.contract;
    const currency = contract?.currency || getCountryCurrency()
    if (isEmpty(currentDetails) && !get(this.props, 'useCurrent')) {
      currentDetails = get(this.props.contract);
      sellerPayload = {
        NGR: get(this.props.contract, 'seller.ngr.ngrNumber'),
        Contact: get(this.props.contract, 'seller.contact.name'),
      };

      buyerPayload = {
        NGR: get(this.props.contract, 'buyer.ngr.ngrNumber'),
        Contact: get(this.props.contract, 'buyer.contact.name'),
      };
    };

    let general = {};
    if (this.hasQuantityBasedCommodity()) general[this.quantityLabel()] = get(currentDetails, 'quantity');
    general[get(contract, 'tonnageLabel')] = get(currentDetails, 'tonnage') ? get(currentDetails, 'tonnage') + ` ${MT_UNIT}` : undefined;
    general['Variety'] = get(currentDetails, 'variety.name', get(currentDetails, 'varietyId.details.type'));
    general['Season'] = get(currentDetails, 'season');
    general['Lot No.'] = get(currentDetails, 'lotNumber') ? get(currentDetails, 'lotNumber') : '';
    if (get(contract, 'isAreaContract')) {
      general['Area'] = get(currentDetails, 'area.area') ? get(currentDetails, 'area.area') + ' ' + fieldSizeUnit : '';
      general['Estimated Yield'] = get(currentDetails, 'area.estimatedYield') ? get(currentDetails, 'area.estimatedYield') + ' ' + estimatedYieldUnit : '';
      general['Estimated Tonnage'] = get(currentDetails, 'area.estimatedTonnage') ? get(currentDetails, 'area.estimatedTonnage').toFixed(2) + ` ${MT_UNIT}` : '';
      general['Price By'] = get(currentDetails, 'area.priceBy') ? moment(get(currentDetails, 'area.priceBy')).format(this.state.countryFormats.date) : null;
    }
    if (get(contract, 'isPoolContract')) {
      general[get(contract, 'gradeLabel')] = get(currentDetails, 'poolGrades');
    } else {
      general[get(contract, 'gradeLabel')] = getGradeName(currentDetails);
    }
    general[get(contract, 'priceLabel')] =  `${currency} ${get(currentDetails, 'price')}`;

    const generalDetails = {
      ...general,
      ...{
        'Price Point': get(currentDetails, 'pricePoint.displayName', get(currentDetails, 'pricePointId.displayName')),
        Track: get(contract, 'trackId') ? get(currentDetails, 'trackId.track', '') : null,
        'Payment Scale': get(currentDetails, 'paymentScaleId.name', ''),
        'Payment Term': get(currentDetails, 'paymentTermId.name'),
        Tolerance: get(contract, 'isStrictQuantityBasedCommodity') ? get(currentDetails, 'toleranceId.name').replace(MT_UNIT.toLowerCase(), contract.quantityUnit) : get(currentDetails, 'toleranceId.name'),
        Conveyance: get(contract, 'conveyanceId') ? get(currentDetails, 'conveyanceId.name') : null,
        Inspection: get(currentDetails, 'inspectionId.name'),
        Weight: get(currentDetails, 'weightId.name'),
        Packaging: get(currentDetails, 'packagingId.name'),
        'Market Zone': get(contract, 'marketZoneId') ? get(currentDetails, 'marketZoneId.name') : null,
        Region: get(contract, 'regionId') ? get(currentDetails, 'regionId.name') : null,
        Invoicing: get(currentDetails, 'administration.invoicing'),
      },
    };

    let spreadDetails = null;
    if (!isEmpty(get(currentDetails, 'spread'))) {
      spreadDetails = {};
      forEach(get(currentDetails, 'spread.details'), grade => {
        spreadDetails[grade.name] = `${grade.priceVariation}${currency} ${grade.value ? grade.value : ' -'}`;
      });
    }

    let amendedSpreadDetails = null;
    if (isArray(get(amendedDetails, 'spread.details'))) {
      amendedSpreadDetails = {};
      forEach(get(amendedDetails, 'spread.details'), grade => {
        amendedSpreadDetails[grade.name] = `${grade.priceVariation} ${currency} ${grade.value ? grade.value : ' -'}`;
      });
    }

    let contractTypeDetails = {'Name': get(currentDetails, 'typeId.displayName', null)};

    let amendedContractTypeDetails = { 'Name': get(amendedDetails, 'typeId.displayName', null)} ;

    let carryDetails = {
      'Carry Rate':  get(currentDetails, 'carryRate') ? `${currency} ${get(currentDetails, 'carryRate').toFixed(2)}` : null,
      Frequency: get(currentDetails, 'carryFrequency'),
      'Carry Start Date': get(contract, 'carryStartDate') ? moment(get(currentDetails, 'carryStartDate')).format(this.state.countryFormats.date) : null,
      'Carry End Date': get(contract, 'carryEndDate') ? moment(get(currentDetails, 'carryEndDate')).format(this.state.countryFormats.date) : null,
      'Current Carry Value': get(currentDetails, 'carryCurrent') ? `${currency} ${parseFloat(get(currentDetails, 'carryCurrent')).toFixed(2)}` : null,
      'Max Carry Value': get(contract, 'carryMax') ? `${currency} ${parseFloat(get(currentDetails, 'carryMax')).toFixed(2)}` : null,
    };

    if (!contract.carryRate) {
      carryDetails = mapValues(carryDetails, () => {
        return null;
      });
    }

    let amendedCarryDetails = {
      'Carry Rate': get(amendedDetails, 'carryRate') ? `${currency} ${parseFloat(get(amendedDetails, 'carryRate')).toFixed(2)}` : undefined,
      Frequency: get(amendedDetails, 'carryFrequency'),
      'Carry Start Date': get(amendedDetails, 'carryStartDate') ? moment(get(amendedDetails, 'carryStartDate')).format(this.state.countryFormats.date) : undefined,
      'Carry End Date': get(amendedDetails, 'carryEndDate') ? moment(get(amendedDetails, 'carryEndDate')).format(this.state.countryFormats.date) : undefined,
      'Current Carry Value': get(amendedDetails, 'carryCurrent')
        ? `${currency} ${parseFloat(get(amendedDetails, 'carryCurrent')).toFixed(2)}`
        : undefined,
      'Max Carry Value': get(amendedDetails, 'carryMax') ? `${currency} ${parseFloat(get(amendedDetails, 'carryMax')).toFixed(2)}` : undefined,
    };

    let valuationDetails = {
      'Contract Value': this.contractValue(contract) ? `${currency} ${this.contractValue(currentDetails)}` : null,
      'Max Contract Value': this.maxContractValue(currentDetails) ? `${currency} ${this.maxContractValue(currentDetails)}` : undefined,
      'Est Payment Due Date': this.estimatedPaymentDueDate(),
      'Current Valuation': this.contractValue(contract) ? `${currency} ${this.contractValue(currentDetails)}` : null,
      'EPR Value': contract.eprValue
        ? `${currency} ${parseFloat(get(currentDetails, 'eprValue')).toFixed(2).toString()}`
        : null,
      'Central Levy': get(currentDetails, 'levy')
        ? `${currency} ${parseFloat(get(currentDetails, 'levy')).toFixed(2).toString()}`
        : null,
    };

    let contractValue = this.contractValue(amendedDetails);
    let amendedValuationDetails = {
      'Contract Value': contractValue ? `${currency} ${contractValue}` : undefined,
      'Max Contract Value': this.maxContractValue(amendedDetails) ? `${currency} ${this.maxContractValue(amendedDetails)}` : undefined,
      'Est Payment Due Date': this.estimatedPaymentDueDate(amendedDetails),
      'Current Valuation': contractValue ? `${currency} ${contractValue}` : undefined,
      'EPR Value': get(amendedDetails, 'eprValue')
        ? `${currency} ${parseFloat(get(amendedDetails, 'eprValue')).toFixed(2).toString()}`
        : undefined,
      'Central Levy': get(amendedDetails, 'levy')
        ? `${currency} ${parseFloat(get(amendedDetails, 'levy')).toFixed(2).toString()}`
        : undefined,
    };

    if (this.isValuationDetailsEqual(valuationDetails, amendedValuationDetails)) {
      amendedValuationDetails = mapValues(amendedValuationDetails, () => {
        return undefined;
      });
    }

    const brokerages = get(currentDetails, 'brokerages', get(currentDetails, 'brokeragesForUser'));
    let brokeredBySellerPayload = null,
      brokeredByBuyerPayload = null,
      brokeredBy = null;
    const administration = get(currentDetails, 'administration');
    if (administration) {
      brokeredBy = {
        'Brokered By': get(currentDetails, 'administration.brokeredById.name'),
        'Broker Contact': get(currentDetails, 'administration.brokerContactId.name'),
      };
    }
    if (!isEmpty(brokerages)) {
      forEach(brokerages, brokerage => {
        if (get(brokerage, 'type') === 'Seller') {
          brokeredBySellerPayload = {
            'Payable By': brokerage.type,
            'Fee Type': contract.isStrictQuantityBasedCommodity ? brokerage.feeType.replace(MT_UNIT, contract.quantityUnit) : brokerage.feeType,
            Rate: brokerage.rate && parseFloat(brokerage.rate).toFixed(2),
            'Total Brokerage Fee': `${currency} ${brokerage.totalFee}`,
            'Charged On': brokerage.chargedOn,
          };
        }

        if (get(brokerage, 'type') === 'Buyer') {
          brokeredByBuyerPayload = {
            'Payable By': brokerage.type,
            'Fee Type': contract.isStrictQuantityBasedCommodity ? brokerage.feeType.replace(MT_UNIT, contract.quantityUnit) : brokerage.feeType,
            Rate: brokerage.rate && parseFloat(brokerage.rate).toFixed(2),
            'Total Brokerage Fee': `${currency} ${brokerage.totalFee}`,
            'Charged On': brokerage.chargedOn,
          };
        }

        if (this.props.showCurrentContract === false) {
          brokeredBySellerPayload = {};
          brokeredByBuyerPayload = {};
        }
      });
    }

    const amendedBrokerages = get(amendedDetails, 'brokerages');
    let amendedBrokeredBySellerPayload = null,
      amendedBrokeredByBuyerPayload = null,
      amendedBrokeredBy = null;
    const amendedAdministration = get(amendedDetails, 'administration');
    if (amendedAdministration) {
      amendedBrokeredBy = {
        'Brokered By': get(amendedDetails, 'administration.brokeredById.name'),
        'Broker Contact': get(amendedDetails, 'administration.brokerContactId.name'),
      };
    } else {
      amendedBrokeredBy = {
        'Brokered By': undefined,
        'Broker Contact': undefined,
      };
    }
    if (!isEmpty(amendedBrokerages)) {
      amendedBrokerages.forEach(brokerage => {
        if (get(this.props.contract, 'canViewSellerBrokerage') && brokerage.type === 'Seller') {
          amendedBrokeredBySellerPayload = {
            'Payable By': brokerage.type,
            'Fee Type': brokerage.feeType,
            Rate: brokerage.rate && parseFloat(brokerage.rate).toFixed(2),
            'Total Brokerage Fee':  `${currency} ${brokerage.totalFee}`,
            'Charged On': brokerage.chargedOn,
          };
        }

        if (get(this.props.contract, 'canViewBuyerBrokerage') && brokerage.type === 'Buyer') {
          amendedBrokeredByBuyerPayload = {
            'Payable By': brokerage.type,
            'Fee Type': brokerage.feeType,
            Rate: brokerage.rate && parseFloat(brokerage.rate).toFixed(2),
            'Total Brokerage Fee': `${currency} ${brokerage.totalFee}`,
            'Charged On': brokerage.chargedOn,
          };
        }
      });
    }

    if (isShallowEqual(brokeredBySellerPayload, amendedBrokeredBySellerPayload)) {
      amendedBrokeredBySellerPayload = mapValues(amendedBrokeredBySellerPayload, () => {
        return null;
      });
    }

    if (isShallowEqual(brokeredByBuyerPayload, amendedBrokeredByBuyerPayload)) {
      amendedBrokeredByBuyerPayload = mapValues(amendedBrokeredByBuyerPayload, () => {
        return null;
      });
    }

    const amendedSellerPayload = {
      NGR: get(amendedDetails, 'seller.ngrId') ? get(amendedDetails, 'seller.ngrId.ngrNumber') : get(amendedDetails, 'seller.ngrId'),
      Contact: get(amendedDetails, 'seller.contactId') ? get(amendedDetails, 'seller.contactId.name') : get(amendedDetails, 'seller.contactId'),
    };

    const amendedBuyerPayload = {
      NGR: get(amendedDetails, 'buyer.ngrId') ? get(amendedDetails, 'buyer.ngrId.ngrNumber') : get(amendedDetails, 'buyer.ngrId'),
      Contact: get(amendedDetails, 'buyer.contactId') ? get(amendedDetails, 'buyer.contactId.name') : get(amendedDetails, 'buyer.contactId'),
    };

    let amendedGeneral = {};
    if (this.hasQuantityBasedCommodity()) amendedGeneral[this.quantityLabel()] = get(amendedDetails, 'quantity');
    amendedGeneral[contract.tonnageLabel] = get(amendedDetails, 'tonnage') ? get(amendedDetails, 'tonnage') + ` ${MT_UNIT}` : undefined;
    amendedGeneral['Variety'] = has(amendedDetails, 'varietyId') ? get(amendedDetails, 'varietyId.name', '') : undefined;
    amendedGeneral['Season'] = get(amendedDetails, 'season');
    amendedGeneral['Lot No.'] = get(amendedDetails, 'lotNumber') ? get(amendedDetails, 'lotNumber') : undefined;
    if (contract.isAreaContract) {
      amendedGeneral['Area'] = get(amendedDetails, 'area.area') ? get(amendedDetails, 'area.area') + ' ' + fieldSizeUnit : '';
      amendedGeneral['Estimated Yield'] = get(amendedDetails, 'area.estimatedYield') ? get(amendedDetails, 'area.estimatedYield') + ' ' + estimatedYieldUnit : '';
      amendedGeneral['Estimated Tonnage'] = get(amendedDetails, 'area.estimatedTonnage')
        ? get(amendedDetails, 'area.estimatedTonnage').toFixed(2) + ` ${MT_UNIT}`
        : '';
      amendedGeneral['Price By'] = get(amendedDetails, 'area.priceBy')
        ? moment(get(amendedDetails, 'area.priceBy')).format(this.state.countryFormats.date)
        : undefined;
    }
    if (contract.isPoolContract) {
      amendedGeneral[contract.gradeLabel] = undefined;
    } else {
      amendedGeneral[contract.gradeLabel] = has(amendedDetails, 'plannedGradeId') ? get(amendedDetails, 'plannedGradeId.name', '') : undefined;
    }

    amendedGeneral[contract.priceLabel] = get(amendedDetails, 'price') ? `${currency} ${get(amendedDetails, 'price')}` : undefined;
    let amendedTolerance = get(amendedDetails, 'toleranceId.name');
    if (amendedTolerance && contract.isStrictQuantityBasedCommodity) amendedTolerance = amendedTolerance.replace(MT_UNIT.toLowerCase(), contract.quantityUnit);
    const amendedGeneralPayload = {
      ...amendedGeneral,
      ...{
        'Price Point': get(amendedDetails, 'pricePointId') ? get(amendedDetails, 'pricePointId.displayName') : get(amendedDetails, 'pricePointId'),
        Track: get(amendedDetails, 'trackId') ? get(amendedDetails, 'trackId.track') : get(amendedDetails, 'trackId'),
        'Payment Scale': get(amendedDetails, 'paymentScaleId') ? get(amendedDetails, 'paymentScaleId.name') : get(amendedDetails, 'paymentScaleId'),
        'Payment Term': get(amendedDetails, 'paymentTermId') ? get(amendedDetails, 'paymentTermId.name') : get(amendedDetails, 'paymentTermId'),
        Tolerance: amendedTolerance,
        Conveyance: get(amendedDetails, 'conveyanceId') ? get(amendedDetails, 'conveyanceId.name') : get(amendedDetails, 'conveyanceId'),
        Inspection: get(amendedDetails, 'inspectionId') ? get(amendedDetails, 'inspectionId.name') : get(amendedDetails, 'inspectionId'),
        Weight: get(amendedDetails, 'weightId') ? get(amendedDetails, 'weightId.name') : get(amendedDetails, 'weightId'),
        Packaging: get(amendedDetails, 'packagingId') ? get(amendedDetails, 'packagingId.name') : get(amendedDetails, 'packagingId'),
        'Market Zone': get(amendedDetails, 'marketZoneId') ? get(amendedDetails, 'marketZoneId.name') : get(amendedDetails, 'marketZoneId'),
        Region: get(amendedDetails, 'regionId') ? get(amendedDetails, 'regionId.name') : get(amendedDetails, 'regionId'),
        Invoicing: get(amendedDetails, 'administration.invoicing'),
      },
    };

    let contractNumber = [
      {
        key: 'Contract Number',
        value1: get(currentDetails, 'contractNumber'),
        value2: get(amendedDetails, 'contractNumber'),
      },
    ];

    let contractIdentifier = [
      {
        key: 'Contract Identifier',
        value1: get(currentDetails, 'identifier'),
        value2: get(amendedDetails, 'identifier'),
      },
    ];

    contractNumber = filter(contractNumber, compareObjectWithNonEqualValues);
    contractIdentifier = filter(contractIdentifier, compareObjectWithNonEqualValues);
    let sellerView = combineObjectKeysWithMultiValue(sellerPayload, amendedSellerPayload);
    sellerView = filter(sellerView, removeObjectValues);
    let buyerView = combineObjectKeysWithMultiValue(buyerPayload, amendedBuyerPayload);
    buyerView = filter(buyerView, removeObjectValues);
    let generalView = combineObjectKeysWithMultiValue(generalDetails, amendedGeneralPayload);
    generalView = filter(generalView, removeObjectValues);
    const deliveryDetail = this.getDeliveryDetails(this.props.contract, currentDetails);
    const amendDeliveryDetail = this.getAmendedDeliveryDetails(this.props.contract, amendedDetails);
    let deliveryDetailView = combineObjectKeysWithMultiValue(deliveryDetail, amendDeliveryDetail);
    deliveryDetailView = filter(deliveryDetailView, removeObjectValues);
    let spreadDetailView = combineObjectKeysWithMultiValue(spreadDetails, amendedSpreadDetails);
    let contractTypeView = combineObjectKeysWithMultiValue(contractTypeDetails, amendedContractTypeDetails);
    contractTypeView = filter(contractTypeView, removeObjectValues);
    let carryDetailView = combineObjectKeysWithMultiValue(carryDetails, amendedCarryDetails);
    carryDetailView = filter(carryDetailView, removeObjectValues);
    let valuationView = combineObjectKeysWithMultiValue(valuationDetails, amendedValuationDetails);
    valuationView = filter(valuationView, removeObjectValues);
    let brokeredByView = combineObjectKeysWithMultiValue(brokeredBy, amendedBrokeredBy);
    brokeredByView = filter(brokeredByView, removeObjectValues);
    let brokeredBySellerPayloadView = combineObjectKeysWithMultiValue(brokeredBySellerPayload, amendedBrokeredBySellerPayload);
    brokeredBySellerPayloadView = filter(brokeredBySellerPayloadView, compareObjectWithNonEqualValues);
    let brokeredByBuyerPayloadView = combineObjectKeysWithMultiValue(brokeredByBuyerPayload, amendedBrokeredByBuyerPayload);
    brokeredByBuyerPayloadView = filter(brokeredByBuyerPayloadView, compareObjectWithNonEqualValues);
    let generalConditionsView = {
      key: 'General Conditions',
      value1: contract.generalConditions,
      value2: get(amendedDetails, 'generalConditions'),
    };
    generalConditionsView = filter([generalConditionsView], removeObjectValues);
    let specialConditionsView = {
      key: 'Special Conditions',
      value1: contract.specialConditions,
      value2: get(amendedDetails, 'specialConditions'),
    };
    specialConditionsView = filter([specialConditionsView], removeObjectValues);
    let anyItemsToShow = contractIdentifier.length + contractNumber.length + sellerView.length + buyerView.length + generalView.length +
      deliveryDetailView.length + contractTypeView.length + spreadDetailView.length + carryDetailView.length + valuationView.length +
      brokeredByView.length + brokeredBySellerPayloadView.length + brokeredByBuyerPayloadView.length + generalConditionsView.length + specialConditionsView.length;

    return (
      <Grid container spacing={8} style={{display: 'inline'}}>
        {this.props.contract && anyItemsToShow > 0 && (
          <div className='col-md-12 padding-reset' id='contract-amend-review-form'>
            {this.props.amendTimeStamp && (
              <div className='row left'>
                <div className='col-md-12'>
                  <h5 style={{ margin: '10px 0px' }}>{this.props.amendTimeStamp}</h5>
                </div>
              </div>
            )}
            <Table style={{ marginTop: '10px' }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ borderBottom: '1px solid #000', width: "30%"}}></TableCell>
                  <TableCell className={this.classes.columnTitle}>Current</TableCell>
                  <TableCell className={this.classes.columnTitle}>Amended</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {contractIdentifier.length > 0 && this.amendViedGrid(contractIdentifier)}
                  {
                    contractNumber.length > 0 &&
                    contractNumber[0].value2 != contractIdentifier[0].value2 &&
                    this.amendViedGrid(contractNumber)
                  }
                  {sellerView.length > 0 && this.amendViedGrid(sellerView, 'Seller')}
                  {buyerView.length > 0 && this.amendViedGrid(buyerView, 'Buyer')}
                  {generalView.length > 0 && this.amendViedGrid(generalView, 'General')}
                  {this.isDeliveryDetailsChanged(this.props.contract) &&
                    deliveryDetailView.length > 0 &&
                    this.amendViedGrid(deliveryDetailView, 'Delivery Details')}
                  {!isEmpty(amendedContractTypeDetails) && contractTypeView.length > 0 && this.amendViedGrid(contractTypeView, 'Contract Type')}
                  {!isEmpty(amendedSpreadDetails) && spreadDetailView.length > 0 && this.amendViedGrid(spreadDetailView, 'Spread Details')}
                  {amendedCarryDetails && carryDetailView.length > 0 && this.amendViedGrid(carryDetailView, 'Carry Details')}
                  {amendedValuationDetails && valuationView.length > 0 && this.amendViedGrid(valuationView, 'Valuation Details')}
                  {brokeredByView.length > 0 && this.amendViedGrid(brokeredByView, 'Brokered By')}
                  {brokeredBySellerPayloadView.length > 0 && this.amendViedGrid(brokeredBySellerPayloadView, 'Seller')}
                  {brokeredByBuyerPayloadView.length > 0 && this.amendViedGrid(brokeredByBuyerPayloadView, 'Buyer')}
                  {generalConditionsView.length > 0 && this.amendViedGrid(generalConditionsView)}
                  {specialConditionsView.length > 0 && this.amendViedGrid(specialConditionsView)}
              </TableBody>
            </Table>
          </div>
        )}
        {!anyItemsToShow && <div style={{padding: '10%' }}> Details not available right now.Contact System Administrator. </div>}
        {
          this.props.isFromAuditHistory &&
          <div className='button-container' style={{"textAlign": 'right', marginTop: '10px'}}>
          {this.props.isLatestAmendRequest && this.props.contract.isAmendRequestPending && this.props.contract.amendable ?
              [
                <CommonButton key='reject' label='Reject' secondary variant="contained" onClick={() => this.handleRejectClickOpen()} />,
                <CommonButton key='accept' label='Accept' primary variant="contained" onClick={this.confirmAmendRequestWrapper} />,
              ]
            : [<CommonButton key='close' label='Close' secondary variant="contained" onClick={this.props.closeSidebar} />]
          }
          </div>
        }
        {!this.props.isFromAuditHistory &&
        <div className='button-container' style={{"textAlign": 'right', marginTop: '10px'}}>
           {this.props.contract && this.props.contract.amendable && this.props.contract.isAmendRequestPending
             ? [
                 <CommonButton key='reject' label='Reject' secondary variant="contained" onClick={() => this.handleRejectClickOpen()} />,
                 <CommonButton key='accept' label='Accept' primary variant="contained" onClick={this.confirmAmendRequestWrapper} />,
               ]
             : [<CommonButton key='close' label='Close' secondary variant="contained" onClick={this.props.closeSidebar} />]}
        </div>}

        {this.props.contract && this.props.contract.amendable && (
          <div className='status-actions-wrap'>
            <RejectionReasonDialog
              open={this.state.rejectDialogOpen.value}
              onClose={this.handleRejectClose}
              title='Reject Amend Request'
              value={this.state.rejectionReason.value}
              onChange={this.handleReasonChange}
              helperText={get(this.state, 'rejectionReason.errors[0]', '')}
              onCancel={this.handleRejectClose}
              onReject={this.handleRejectSubmit}
            />
          </div>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = dispatch => ({
  raiseAmendRequest: (contactId, data) => dispatch(raiseAmendRequest(contactId, data)),
  confirmAmendRequest: (contactId, reload) => dispatch(confirmAmend(contactId, null, reload)),
  rejectAmendRequest: (contactId, data, reload) => dispatch(rejectAmend(contactId, data, null, reload)),
  isLoading: component => dispatch(isLoading(component)),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AmendContract));
