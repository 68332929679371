import React from 'react';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

const SearchControls = ({value, onChange, onKeyPress, onSearch, onClear, onVoidChange, includeVoidChecked}) => {
  return (
    <div style={{width: '40%', display: 'inline-flex', alignItems: 'center'}}>
      <div style={{width: '70%', display: 'flex', alignItems: 'center', border: '1px solid darkgray', borderRadius: '4px'}}>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          onChange={onChange}
          id='tableSearch'
          placeholder='Minimum 3 characters...'
          value={value}
          onKeyPress={onKeyPress}
        />
        {
          Boolean(value) &&
            <React.Fragment>
              <Tooltip title='Clear search results'>
                <IconButton sx={{ p: '10px' }} onClick={onClear}>
                  <ClearIcon />
                </IconButton>
              </Tooltip>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            </React.Fragment>
        }
        <IconButton type="button" sx={{ p: '10px' }} color='primary' onClick={onSearch}>
          <SearchIcon />
        </IconButton>
      </div>
      {
        Boolean(onVoidChange) &&
          <Tooltip title='Include void results which are excluded by default'>
          <FormControlLabel
            sx={{marginLeft: '8px'}}
            control={<Checkbox color='secondary' size="small" onChange={onVoidChange} checked={includeVoidChecked}/>}
            label={<Typography sx={{fontSize: '14px'}}>Include Void</Typography>}
          />
        </Tooltip>

      }
    </div>
  );
}

export default SearchControls;
