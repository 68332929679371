
import React from 'react';
import { connect } from 'react-redux';
import CashPricesTable from '../../containers/CashPricesTable';
import Paper from '@mui/material/Paper';
import { getCashPrices, canCreateTitleTransferForCashPrice, cashPricesResponse, resetAutoScrollPosition, setIntervalTime } from '../../actions/companies/cash-board';
import { get, set, map, forEach, find, filter, isEmpty, includes } from 'lodash';
import SideDrawer from '../common/SideDrawer';
import CashStockForm from './CashStockForm';
import { TAB } from './constants';
import CustomEmailDialog from '../common/CustomEmailDialog';
import APIService from '../../services/APIService';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import Button from '@mui/material/Button/Button';
import alertifyjs from 'alertifyjs';
import { isLoading, forceStopLoader } from '../../actions/main';
import Filters from '../common/Filters';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import { defaultViewAction, getCountryConfig, getSavedHandlers } from '../../common/utils';
import CommonListingButton from '../common/CommonListingButton';
class AllCashPrices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openCloseConfirmTransferDialog: false,
            showEmailDialog: false,
            data: undefined,
            employees: undefined,
            showSelectPageDialog: false,
            applyFilters: false,
            openSideDrawer: false,
            filters: {},
            filterValues: {
                commodity__id__in: [],
                grade__id__in: [],
                season__in: [],
                track__in: [],
                buyer__company__id__in: [],
                site__id__in: [],
            },
          isPresentation: false,
          redirectLocation: null,
          scrollPosition: 0,
          autoScrollInterval: null,
        };
        this.myRef = React.createRef();

        this.handleChange = this.handleChange.bind(this);
        this.setOpenCloseConfirmTransferDialog = this.setOpenCloseConfirmTransferDialog.bind(this);
        this.getDisabledPartiesForEmail = this.getDisabledPartiesForEmail.bind(this);
        this.isLaterDisabled = this.isLaterDisabled.bind(this);
        this.exitFullScreen = this.exitFullScreen.bind(this);
    }

    componentDidMount() {
        this.props.getCashPrices();
        document.addEventListener('fullscreenchange', this.exitFullScreen)
        APIService.profiles()
        .filters('all_cash_price')
            .get(this.props.token)
            .then(res => {
                this.setState({
                    filters: get(res, 'all_cash_price', {}),
                });
            });
    }

    componentDidUpdate() {
        if (this.props.resetAutoScrollPosition) {
            if (this.state.scrollPosition !== 0)
                this.setState({scrollPosition: 0})
            this.props.dispatch(resetAutoScrollPosition(false));
        }
        if (!this.state.isPresentation && this.state.autoScrollInterval) {
            clearInterval(this.state.autoScrollInterval);
            this.setState({scrollPosition: 0, autoScrollInterval: null});
        }
        if (this.state.isPresentation && !this.state.autoScrollInterval) {
            const autoScrollInterval = setInterval(this.autoScroll, 500);
            this.setState({ autoScrollInterval });
        }
    }

    componentWillUnmount() {
        this.props.setCashPricesResponse([]);
        this.props.closeSideDrawer(false);
    }

    autoScroll = () => {
        const scrollContainer = document.getElementById('cash-price-table');
        let newScrollPosition = this.state.scrollPosition + (getCountryConfig()?.cashPriceScrollSpeed || 5);
        scrollContainer.scrollTo(this.state.scrollPosition, newScrollPosition);
        this.setState({ scrollPosition: newScrollPosition });
    }

    exitFullScreen() {
        if (document.fullscreenElement) {
            if (this.state.isPresentation) {
                this.props.dispatch(setIntervalTime(null));
                this.setState({isPresentation: false});
                document.exitFullscreen();
            }
            else {
                this.setState({isPresentation: true});
            }
        }
        else {
            this.props.dispatch(setIntervalTime(null));
            this.setState({isPresentation: false});
        }
    }

    handleChange(value, id) {
        const newState = { ...this.state };
        set(newState, `${id}`, value);
        this.setState(newState);
    }

    setOpenCloseConfirmTransferDialog(data) {
        APIService.cash_board().appendToUrl(`cash-prices/${this.props.selectedCashPrice.id}/active/check/`).request(
            'GET', data, null,
        ).then(response => {
            if (response.data.result) {
                this.setState({
                    openCloseConfirmTransferDialog: true,
                    data: data,
                });
            }
            else
                alertifyjs.error('Cash Price is not active.');
        });
    }

    async handleEmail() {
        if (this.state.openCloseConfirmTransferDialog) {
            let buyerCompanyId = get(this.props, 'selectedCashPrice.buyer.companyId');
            let sellerCompanyId = get(this.state.data, 'seller.companyId');
            let companyQueryString = map({ buyerCompanyId, sellerCompanyId }, id => `company_ids=${id}`).join('&');
            let siteCompanyId = get(this.props, 'selectedCashPrice.siteCompanyId');
            if (siteCompanyId && !includes([buyerCompanyId, sellerCompanyId], siteCompanyId)) {
                companyQueryString += `&company_ids=${siteCompanyId}`;
            }
            await APIService.profiles().appendToUrl(`employees-signature/?${companyQueryString}`).get(
                this.props.token).then(
                    res => {
                      this.setState({ openCloseConfirmTransferDialog: false, showEmailDialog: true, employees: res, redirectLocation: `cash-price/${this.props.selectedCashPrice?.id}/title-transfers` });
                    });
        }
        else {
            this.setState({ openCloseConfirmTransferDialog: false, showEmailDialog: false, employees: {}, showSelectPageDialog: false },
                () => { document.location.hash = this.state.redirectLocation; }
            );
        }
    }

    getPartyEmails = () => {
        const { employees } = this.state;
        let companyAdminsForSite = get(this.props, 'selectedCashPrice.companyAdminsForSite');
        let data = {
            buyer: get(find(employees, { id: get(this.props, 'selectedCashPrice.buyer.contactId') }), 'email'),
            seller: get(find(employees, { id: get(this.state.data, 'seller.contactId') }), 'email'),
        };
        if (companyAdminsForSite) {
            let sitePartyEmails = [];
            companyAdminsForSite.map(employeeId => {
              let email = get(find(employees, { id: employeeId }), 'email');
                if (email && !isEmpty(email))
                    sitePartyEmails.push(email);
            });
            data['site'] = sitePartyEmails;
        }
        return data;
    };

    async getPartyContacts() {
        const { employees } = this.state;
        const contacts = {};
        const parties = this.getEmailPopupPartiesCompanyIds();
        forEach(['seller', 'buyer', 'site'], party => {
            contacts[party] = filter(employees, { companyId: parties[party] });
        });
        return contacts;
    }

    getEmailPopupPartiesCompanyIds() {
        const { data } = this.state;
        const parties = ['seller', 'buyer', 'site'];
        const ids = {};
        forEach(parties, party => {
            if (party === 'buyer')
                ids.buyer = get(this.props, 'selectedCashPrice.buyer.companyId');
            if (party === 'seller')
                ids.seller = get(data, 'seller.companyId');
            if (party === 'site')
              ids.site = get(find(getSavedHandlers(), { id: get(this.props, 'selectedCashPrice.siteId') }), 'companyId');
        });
        return ids;
    }

    getEmailSubject() {
        const { data } = this.state;
        let seller = get(find(this.props.allCompanyParties, { id: get(data, 'seller.companyId') }), 'name');
        if (data.seller.companyId != this.props.user.companyId)
            return `${this.props.user.company.entityName} A/C ${seller} Title Transfer #${get(data, 'identifier')} (${get(data, 'tonnage')} MT) against cash price (${this.props.selectedCashPrice.siteName} | ${this.props.selectedCashPrice.track} | ${this.props.selectedCashPrice.commodityName} | ${this.props.selectedCashPrice.gradeName} | ${this.props.selectedCashPrice.season}).`;
        return `${seller} Title Transfer #${get(data, 'identifier')} (${get(data, 'tonnage')} MT) against cash price (${this.props.selectedCashPrice.siteName} | ${this.props.selectedCashPrice.track} | ${this.props.selectedCashPrice.commodityName} | ${this.props.selectedCashPrice.gradeName} | ${this.props.selectedCashPrice.season}).`;
    }

    closeEmailDialog = (communicationData, justClose) => {
        if (justClose) {
            this.setState({ showEmailDialog: false, employees: false, data: {} });
        }
        else if (this.state.showEmailDialog) {
            const data = this.state.data;
            this.props.dispatch(isLoading('CustomEmailDialog'));
            if (communicationData) {
                data['communication'] = communicationData;
            }
          this.setState({ showEmailDialog: false }, () => {
            const id = this.props.selectedCashPrice.id
                APIService.cash_board(id).appendToUrl('title-transfers/').request(
                    'POST', data, null,
                ).then(response => {
                    this.props.dispatch(isLoading('CustomEmailDialog'));
                    if (response.data) {
                        this.props.closeSideDrawer(false);
                        this.setState({
                            successTransferText: `Title Transfer #${response.data.identifier} completed against the Cash price.
                            Contract has been generated for the same. Do you want to check the Title Transfer or stay on Cash Prices?`,
                          redirectLocation: `cash-price/${id}/title-transfers`,
                            showSelectPageDialog: true,
                            showEmailDialog: false,
                        }, () => {
                            alertifyjs.success('Title Transfer Created');
                            this.props.getCashPrices();
                            this.props.dispatch(forceStopLoader());
                        });
                    }
                }).catch(res => {
                    alertifyjs.error(get(res, 'response.data.errors', 'An Error Occurred'), 3);
                    this.props.dispatch(forceStopLoader());
                });
            });
        }
    };

    handleDialog(confirmTransfer) {
        if (confirmTransfer) {
            this.setState({ openCloseConfirmTransferDialog: false });
            return;
        }
        this.setState({ showSelectPageDialog: false });
    }

    getSelectedParties() {
      const stocksManagement = get(find(getSavedHandlers(), { id: get(this.props.selectedCashPrice, 'siteId') }), 'stocksManagement');
        if (stocksManagement) {
          return ['buyer', 'seller', 'site'];
        }
        return ['buyer', 'seller'];
    }

    handleFilters = bool => {
        this.setState({
            applyFilters: bool,
            openSideDrawer: bool,
        });
    };

  handleFilterState = (key, value) => {
    this.setState({ [key]: value }, () => {
      if (key === 'applyFilters') {
        const { filters } = this.state;
        APIService.profiles()
          .filters()
          .post({ all_cash_price: filters }, this.props.token)
          .then(res => {
            this.setState({filters: res?.filters?.all_cash_price || {}}, () => {
              this.props.isLoading();
              this.props.setCashPricesResponse([]);
              this.props.getCashPrices();
            })
          });
      }
    });
  };

    getDisabledPartiesForEmail() {
      const stocksManagement = get(find(getSavedHandlers(), { id: get(this.props.selectedCashPrice, 'siteId') }), 'stocksManagement');
        if (stocksManagement) {
          return ['buyer', 'site'];
        }
        return [];
      }
    isLaterDisabled() {
        let disabledParties = this.getDisabledPartiesForEmail();
        if (disabledParties.length > 0)
            return true;
        return false;
    }

    applyFullScreen = () => {
        let element = document.getElementById('cash-price-table');
        element.requestFullscreen();
    }

    getActionsOptionMapperListItems() {
      return [defaultViewAction];
    }

    render() {
      const { openCloseConfirmTransferDialog, showSelectPageDialog, showEmailDialog } = this.state;
      const hasFilters = !isEmpty(Object.entries(this.state.filters).filter(val => val[1].length !== 0))
        return (
          <Paper className='paper-table-paginated'>
            <div>

                    <div style={{float: 'right', marginRight: '10px'}}>
                        <Tooltip title='Apply filters' placement='top'>
                            <Button
                                value={this.state.applyFilters}
                                variant="contained"
                                type='button'
                                onClick={() => this.handleFilters(true)}
                                color='primary'
                                className='add-button'
                                style={{ float: 'right', marginLeft: '10px' }}
                            >
                                <FilterListIcon style={{ paddingRight: '5px' }} />
                                FILTERS{' '}
                                {+hasFilters
                                    ? `(${Object.entries(this.state.filters).filter(val => val[1].length !== 0).length})`
                                    : ''}
                            </Button>
                        </Tooltip>
                        <Button
                            id='fullscreen-btn'
                            variant="contained"
                            type='button'
                            onClick={() => this.applyFullScreen()}
                            color='primary'
                            className='add-button'
                        > Presentation</Button>
                        {this.state.applyFilters && (
                            <SideDrawer isOpen={this.state.openSideDrawer} title='Filters' size='big' onClose={() => this.handleFilters(false)} app='filters'>
                                <Filters
                                    isLoading={this.props.isLoading}
                                    forceStopLoader={this.props.forceStopLoader}
                                    handleFilterState={this.handleFilterState}
                                    filters={this.state.filters}
                                    tracks={this.props.tracks}
                                  filterValues={this.state.filterValues}
                                  allCompanies
                                />
                            </SideDrawer>
                        )}
                    </div>
                    <div style={{ float: 'right', marginRight: '10px'}}>
                <CommonListingButton
                  showMenus
                  showDownLoadIcon={false}
                  optionMapper={this.getActionsOptionMapperListItems()}
                  title='Actions'
                  name='Actions'
                />
              </div>
                    <div id='cash-price-table' ref={this.myRef} style={this.state.isPresentation ? {overflow: "auto"}: {}}>
                    <CashPricesTable
                        isAllCashPriceTab={TAB.ALL_CASH_PRICE}
                        userCompanyId={this.props.user.companyId}
                      isPresentation={this.state.isPresentation}
                      filters={this.state.filters}
                      hasFilters={hasFilters}
                    />
                    </div>
                    {
                        this.props.canCreateTTForCashPrice && this.props.selectedCashPrice &&
                        <SideDrawer
                            isOpen={this.props.canCreateTTForCashPrice}
                            title="Cash Stock"
                            onClose={this.props.closeSideDrawer}
                            size='xlarge'>
                            <CashStockForm closeDrawer={this.props.closeSideDrawer} handleConfirmTransferDialog={this.setOpenCloseConfirmTransferDialog} />
                        </SideDrawer>
                    }
                    <Dialog open={openCloseConfirmTransferDialog || showSelectPageDialog} >
                        <DialogTitleWithCloseIcon
                            style={{ marginBottom: '10px', height: '58px', paddingTop: '12px' }}>
                            {openCloseConfirmTransferDialog ? 'Confirm Transfer' : 'Transfer Successful'}
                        </DialogTitleWithCloseIcon>
                        <DialogContent dividers style={{ borderBottom: ' 1px solid #e6e6e6' }}>
                            <DialogContentText>
                                {openCloseConfirmTransferDialog ?
                                    <span>
                                        <p> {'Please verify all the transfer information is correct before confirming.'} </p>
                                        <span> By confirming you agree to <a href="#/web/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms & Conditions</a></span>
                                    </span> :
                                    this.state.successTransferText
                                }
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleDialog(openCloseConfirmTransferDialog)}>
                                {openCloseConfirmTransferDialog ? 'Cancel' : 'Stay on Cash Prices'}
                            </Button>
                            <Button onClick={() => this.handleEmail()} color="primary">
                                {openCloseConfirmTransferDialog ? 'Confirm' : 'Show Title Transfer'}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {showEmailDialog &&
                        <CustomEmailDialog
                            parties={['buyer', 'seller', 'site']}
                            selectedParties={this.getSelectedParties()}
                            title="Email PDF copies to"
                            partyEmails={this.getPartyEmails()}
                            partyContacts={this.getPartyContacts()}
                            subject={this.getEmailSubject()}
                            noBody={true}
                            footer={'Title Transfer PDF will be automatically sent as part of the email'}
                            open={showEmailDialog}
                            onClose={this.closeEmailDialog}
                            disableAcceptanceRequired={true}
                            disabledPartiesForEmail={this.getDisabledPartiesForEmail()}
                            disableLater={this.isLaterDisabled()}
                        />
                    }
                </div>
            </Paper>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.main.user.user,
        tracks: state.main.tracks,
        allCompanyParties: state.companies.companies.companyParties,
        canCreateTTForCashPrice: state.companies.cashBoard.canCreateTTForCashPrice,
        selectedCashPrice: state.companies.cashBoard.selectedCashPrice,
        resetAutoScrollPosition: state.companies.cashBoard.resetAutoScrollPosition,
    };
};

const mapDispatchToProps = dispatch => ({
    getCashPrices: () => dispatch(getCashPrices('', true, null, 'all')),
    closeSideDrawer: () => dispatch(canCreateTitleTransferForCashPrice()),
    setCashPricesResponse: () => dispatch(cashPricesResponse([])),
    isLoading: (component) => dispatch(isLoading(component)),
    forceStopLoader: () => dispatch(forceStopLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllCashPrices);
