import { get, find, isArray, isEmpty, includes, cloneDeep } from 'lodash';
import APIService from '../../services/APIService';
import {
  MOVEMENTS_TABLE_OPTIONS_ITEMS,
  FO_FA_FSA_TABLE_OPTIONS_ITEMS,
  CALL_ON_GRAIN_ORDER_TABLE_OPTIONS_ITEMS,
  PACK_ORDER_TYPE_ID,
  REQUEST_ORDER_TYPE_IDS
} from "../../common/constants";
import {
  getIndexOfSpliceItem, spliceItem, isSystemCompany, regeneratePDF, getCountryFormats,
  getCountryLabel, isStaff
} from "../../common/utils";
import moment from 'moment';
import alertifyjs from 'alertifyjs';
import { VENDOR_DEC_NOT_ALLOWED_ERROR_MESSAGE_TEMPLATE } from './Constants';

export const getFreightOrderActionMenuOptions = (order, subItems, clickedOption) => {
  const {
    isAmendRequestPending, canGrantCustomerPermission, editButtonLabel, canCustomerCreateMovements, canViewPickup, canViewDelivery
  } = order;
  const canOrCannotCreateFreightMovementsLabel = canCustomerCreateMovements ? 'Disallow Customer to Create Movements': 'Allow Customer to Create Movements';
  const orderTypeId = get(order, 'typeId');
  let freightOrderOptionsItems = includes(REQUEST_ORDER_TYPE_IDS, orderTypeId) ? CALL_ON_GRAIN_ORDER_TABLE_OPTIONS_ITEMS() : FO_FA_FSA_TABLE_OPTIONS_ITEMS();
  if(!canViewDelivery || !canViewPickup)
    spliceItem(freightOrderOptionsItems, {key: 'vendor_dec'})
  if (!get(order, 'canAssignFreightAllocation') || orderTypeId === PACK_ORDER_TYPE_ID)
    spliceItem(freightOrderOptionsItems, {key: 'freight_allocations'});
  if (!get(order, 'showFreightInvoiceTab') || orderTypeId === PACK_ORDER_TYPE_ID)
    spliceItem(freightOrderOptionsItems, {key: 'invoices'});
  if (get(order, 'canMarkMovementsDelivered'))
    freightOrderOptionsItems.push({key:'mark_movements_delivered', text: 'Mark Movements Delivered'});
  if (isAmendRequestPending)
    freightOrderOptionsItems.push({key: 'review_amendment', text: 'Review Amendment'});
  if (canGrantCustomerPermission && orderTypeId !== PACK_ORDER_TYPE_ID)
    freightOrderOptionsItems.push(
      {
        key: 'can_or_cannot_customer_create_freight_movements',
        text: canOrCannotCreateFreightMovementsLabel
      }
    );
  if (editButtonLabel) {
    const amendIndex = getIndexOfSpliceItem(freightOrderOptionsItems, {key: 'amend'});
    freightOrderOptionsItems[amendIndex].text = editButtonLabel;
  }
  if(isSystemCompany())
    freightOrderOptionsItems.push({key: 'regenerate_pdf', text: 'Regenerate PDF'});
  if(isStaff())
    freightOrderOptionsItems.push({ key: 'assign_to', text: 'Assign To'})

  const option = find(freightOrderOptionsItems, {key: get(clickedOption, 'key')});
  if(option && isArray(subItems) && !isEmpty(subItems))
    option.subItems = subItems;

  const PDFOption = getIndexOfSpliceItem(freightOrderOptionsItems, {key: 'download_creator_pdf'});
  if (PDFOption)
    freightOrderOptionsItems[PDFOption].data.id = get(order, 'id');
  if (orderTypeId === PACK_ORDER_TYPE_ID) {
    spliceItem(freightOrderOptionsItems, {key: 'freight_movements'});
    let obj = {
      key: 'pack_movements',
      text: 'Pack Movements',
      subItems: [
        { key: 'pack_movements_view', text: 'View' },
      ],
    }
    freightOrderOptionsItems.splice(1, 0, obj);
  } else {
    freightOrderOptionsItems.push({key:'recalculate_distance', text: 'Re-Calculate Movements Distance'});
  }

  freightOrderOptionsItems.push({key: 'show_hierarchy', text: 'Show Hierarchy'});

  return freightOrderOptionsItems;
};

export const getGrainOrderActionMenuOptions = (order, subItems, clickedOption) => {
  const {
    isAmendRequestPending, canGrantCustomerPermission, editButtonLabel, canCustomerCreateMovements, canViewPickup, canViewDelivery
  } = order;

  let callOnGrainOrderOptionsItems = CALL_ON_GRAIN_ORDER_TABLE_OPTIONS_ITEMS();
  const canCustomerCreateMovementsLabel = canCustomerCreateMovements ? 'Disallow Customer to Create Movements' : 'Allow Customer to Create Movements';
  if(!canViewDelivery || !canViewPickup)
    spliceItem(callOnGrainOrderOptionsItems, {key: 'vendor_dec'})
  if (get(order, 'canMarkMovementsDelivered'))
    callOnGrainOrderOptionsItems.push({key:'mark_movements_delivered', text: 'Mark Movements Delivered'});

  if (isAmendRequestPending)
    callOnGrainOrderOptionsItems.push({key: 'review_amendment', text: 'Review Amendment'});

  if (canGrantCustomerPermission)
    callOnGrainOrderOptionsItems.push(
      {
        key: 'can_or_cannot_customer_create_freight_movements',
        text: canCustomerCreateMovementsLabel
      }
    );

  if (editButtonLabel) {
    const amendIndex = getIndexOfSpliceItem(callOnGrainOrderOptionsItems, {key: 'amend'});
    callOnGrainOrderOptionsItems[amendIndex].text = editButtonLabel;
  }

  if(isSystemCompany())
    callOnGrainOrderOptionsItems.push({key: 'regenerate_pdf', text: 'Regenerate PDF'});

  if(isStaff())
    callOnGrainOrderOptionsItems.push({ key: 'assign_to', text: 'Assign To'})

  const option = find(callOnGrainOrderOptionsItems, {key: get(clickedOption, 'key')});
  if(option && isArray(subItems) && !isEmpty(subItems))
    option.subItems = subItems;

  const PDFOption = getIndexOfSpliceItem(callOnGrainOrderOptionsItems, {key: 'download_creator_pdf'});
  if (PDFOption)
    callOnGrainOrderOptionsItems[PDFOption].data.id = get(order, 'id');

  callOnGrainOrderOptionsItems.push({key: 'show_hierarchy', text: 'Show Hierarchy'});
  callOnGrainOrderOptionsItems.push({key:'recalculate_distance', text: 'Re-Calculate Movements Distance'});

  return callOnGrainOrderOptionsItems;
};

export const getFreightMovementActionMenuOptions = (movement, subItems, clickedOption, isPackMovement) => {
  const {
    isAmendRequestPending, addOrEditInloadLabel, addOrEditOutloadLabel, editButtonLabel, status,
    displayStatus, canViewPickup, canViewDelivery
  } = movement;
  const addEditInloadSpliceItem = {key:'add_edit_inload'};
  const addEditOutloadSpliceItem = {key: 'add_edit_outload'};
  const markCompletedOption = {
    key: 'mark_complete',
    text: 'Mark Complete',
    sideDrawer: true,
    hasFunc: true,
  };

  let freightContractOptionsItems = cloneDeep((MOVEMENTS_TABLE_OPTIONS_ITEMS()));
  const canMarkToDelivered = includes(['completed', 'Completed', 'manual_contract_complete_balanced', 'Manually Completed'], (status || displayStatus));

  if (isAmendRequestPending)
    freightContractOptionsItems.push({key: 'review_amendment', text: 'Review Amendment'});

  const inloadIndex = getIndexOfSpliceItem(freightContractOptionsItems, addEditInloadSpliceItem);
  freightContractOptionsItems[inloadIndex].text = addOrEditInloadLabel;
  if (!canViewDelivery)
    freightContractOptionsItems.splice(inloadIndex, 1);

  const outloadIndex = getIndexOfSpliceItem(freightContractOptionsItems, addEditOutloadSpliceItem);
  freightContractOptionsItems[outloadIndex].text = addOrEditOutloadLabel;
  if (!canViewPickup)
    freightContractOptionsItems.splice(outloadIndex, 1);

  if(!canViewDelivery || !canViewPickup)
    spliceItem(freightContractOptionsItems, {key: 'vendor_dec'})

  if (editButtonLabel) {
    const amendIndex = getIndexOfSpliceItem(freightContractOptionsItems, {key: 'amend'});
    freightContractOptionsItems[amendIndex].text = editButtonLabel;
  }

  if (canMarkToDelivered)
    freightContractOptionsItems.push({key: 'mark_as_delivered', text: 'Mark As Delivered'});
  else
    freightContractOptionsItems.push(markCompletedOption);

  if(isSystemCompany()) {
    freightContractOptionsItems.push({key: 'regenerate_pdf', text: 'Regenerate PDF'});
  }

  const option = find(freightContractOptionsItems, {key: get(clickedOption, 'key')});
  if(option && isArray(subItems) && !isEmpty(subItems))
    option.subItems = subItems;

  if (isStaff()) {
    if((movement.displayStatus || movement.status)?.toLowerCase() === 'void' && !isPackMovement)
      freightContractOptionsItems.push({key: 'revive', text: 'Revive Movement'})

    if(movement?.pausedDocketEntry){
      freightContractOptionsItems.push({key: 'include_in_docket_entry', text: `Include in ${getCountryLabel('docket')} Entry`});
      freightContractOptionsItems.push({key: 'edit_docket_clarification', text: `Edit ${getCountryLabel('docket')} Clarification`});
    }
    else
      freightContractOptionsItems.push({key:'ignore_docket_data', text: `Seek ${getCountryLabel('docket')} Clarification`});
  }

  if (movement?.typeId === PACK_ORDER_TYPE_ID) {
    spliceItem(freightContractOptionsItems, {key: 'amend'});
    spliceItem(freightContractOptionsItems, {key: 'duplicate'});
    spliceItem(freightContractOptionsItems, {key: 'void_and_duplicate'});
  }

  if(get(movement, 'containerNumber'))
    spliceItem(freightContractOptionsItems, {key: 'duplicate'});

  if (isPackMovement){
    freightContractOptionsItems.push({key:'create_container_movement', text: 'Create Container Movement'});
  } else {
    freightContractOptionsItems.push({key:'recalculate_distance', text: 'Re-Calculate Distance'});
  }

  return freightContractOptionsItems;
};

export const regenerateOrderPDF = order => {
  const orderId = get(order, 'id');
  if(orderId)
    return regeneratePDF(APIService.freights().orders(orderId).appendToUrl('regenerate-pdfs/'));
};

export const regenerateMovementPDF = movement => {
  const movementId = get(movement, 'id');
  if(movementId)
    return regeneratePDF(APIService.freights().contracts(movementId).appendToUrl('regenerate-pdfs/'));
};

export const parseDateTime = (type, dateTime, isActualDateTime = false) => {
  if(type === 'date') {
    if(isActualDateTime)
      return moment(dateTime).format(getCountryFormats().date);
    return moment(dateTime).format('YYYY-MM-DD');
  } else {
    if(isActualDateTime)
      return moment(dateTime).format(getCountryFormats().time);
    return moment(dateTime).format('HH:mm:ss');
  }
};

export const isCustomerGradeOrTonnageMissing = (entity, callback) => {
  const fields = ['grade', 'customer', 'tonnage'];
  APIService.profiles().appendToUrl(`transactions/freightcontract/${entity.id}/`).get().then(data => {
    const missingField = fields.find(field => isEmpty(data, field) || !get(data, field));
    if (missingField) {
      const errorMessage = VENDOR_DEC_NOT_ALLOWED_ERROR_MESSAGE_TEMPLATE({ missingField });
      alertifyjs.alert(
          'Permission Denied',
          `<div className=""><p>${errorMessage}</p></div>`,
          () => {},
      );
    }
    if(callback)
      callback(!!missingField);
    else
      return !!missingField;
  });
};
