import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getCompanyDetails, updateCompany } from '../../actions/companies/index';
import { addCompany } from '../../actions/companies';
import { fetchNameFromABN, isLoading, receiveABNResponse } from '../../actions/main';
import AutoComplete from '../google-places/AutoComplete';
import {
  range, includes, isNumber, cloneDeep, pick, map, compact, toLower, has,
  get, set, every, find, filter, isEqual, isEmpty, isObject, forEach
} from 'lodash';
import CommonButton from '../common/CommonButton';
import CommonTextField from '../common/CommonTextField';
import {setHeaderText, setBreadcrumbs, forceStopLoader} from '../../actions/main';
import CommonSelect from '../common/select/CommonSelect';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import CommonMultiSelect from '../common/autocomplete/CommonMultiSelect';
import APIService from '../../services/APIService';
import { BROKER, DEFAULT_LAT, DEFAULT_LNG, BHC_TYPE_ID, LOGISTICS_LITE_PLAN, PREMIUM_PLAN, WAREHOUSE_INLOAD_FEES_CHARGED_AT, TRANSACTION_TYPES, TRANSACTION_TYPE_MAPPER, XERO_INVOICE_STATUSES, CONTRACT_CREATION_RESTRICTIONS, VARIETY_MANDATORY_LOAD_OPTIONS, VARIETY_MANDATORY_USER_OPTIONS, CONTRACT_ALLOCATION_TYPES, EMPTY_VALUE} from '../../common/constants';
import SideDrawer from '../common/SideDrawer';
import {
  canCreateOrUpdate, isSystemCompany, getBrokerageDisplayValue, weekdays, getWeek,
  isCompanyEditable, isCurrentUserBroker, isCurrentUserCompanyAdmin, currentUserCompany,
  hasDuplicates, getCurrentCountry, toPhoneFormat, isVisibleInCountry, isSuperuser,
  getCountryLabel,
  isObserver,
  isCompanyAdminOrObserver,
} from '../../common/utils';
import {getPaymentTerms} from '../../actions/companies/contracts';
import FileUpload from '../common/FileUpload';
import {BROKERAGE_FEE_TYPES, BROKERAGE_FREQUENCIES, CHARGED_AT_PERIODS} from '../contracts/fixtures';
import { positiveDecimalFilter } from '../../common/input-filters';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CommonDatePicker from "../common/CommonDatePicker";
import moment from 'moment';
import Checkbox from "@mui/material/Checkbox/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import RightTick from '../common/icons/RightTick';
import { ChipLabel } from '../common/ChipLabel';
import startCase from 'lodash/startCase';
import TransactionConfig from './TransactionConfig';
import keys from 'lodash/keys';
import alertify from 'alertifyjs';
import { GROWER } from '../../common/constants';
import ABNField from '../common/ABNField';
import PhoneField from '../common/PhoneField';

const initialDataFields = {
  abn: '',
  businessName: '',
  typeId: '',
  entityName: '',
  gstNumber: '',
  address: {address: '',latitude: '',longitude: ''},
  mobile: '',
  website: '',
  startOfWeek: '',
  endOfWeek: '',
  paymentTermId: '',
  transactionParticipation: false,
  mobileParticipation: false,
  canRepresent: false,
  warehouseChargedAt: false,
  restrictSlotCancellation: false,
  showCostsInFm: false,
  saleContractCreationRestriction: '',
  purchaseContractCreationRestriction: '',
  mobileOrdersWithinDeliveryRange: false,
  additionalMassLimitCodes: false,
  acquisitionFileUpload: false,
  overdraftTransferAllowed: true,
  warehouseInvoiceGroupGrades: false,
  hoursBeforeCancellationStops: 0,
  mobileFlow: undefined,
  planType: undefined,
  rowsPerPage: 30,
  passwordExpiryPeriod: '',
  canUploadContractCsv: false,
  bulkInvoicingFrom: '',
  contractInvoicingFrom: '',
  freightInvoicingFrom: '',
  warehouseInloadChargedFrom: '',
  paymentDueDays: 15,
  logo: {
    base64: '',
    name: '',
  },
  brokerages: [
    {
      feeType: null,
      rate: null,
      frequency: null,
      totalFee: null,
      chargedAt: null,
      subscriptionStartDate: null,
    },
  ],
  extras: {alias: [], grower_number: '',},
  enableCustomCsv: false,
  multiSlotBooking: false,
  enableTransferToApprovedBuyers: false,
  defaultLevyEpr: true,
  automaticallyAddInload: false,
  automaticallyAddOutload: false,
  showCashPricesToAll: true,
  xeroEnabled: false,
  isVarietyMandatory: false,
  isHalalDeclarationMandatory: false,
  fillDocket: true,
  shrinkageOnInloadForAll: false,
  loadTypeForVarietyMandatory: [],
  userTypeForVarietyMandatory: [],
  haveField: false,
  isIndependentSiteBuyer: false,
  isSellingToIndependentSiteBuyer: false,
  onlyCreatorCanAmendContract: false,
  onlyCreatorCanAmendOrder: false,
  warehouseInvoiceCommoditySeparated: false,
  enableMyStocksOnMobile: false,
  contractAllocations: [],
  bankApcaNumber: undefined,
  transactionCode: undefined,
  contractNumberMandatoryInTransfers: false,
  isVarietyMandatoryInCommodityDec: false,
  foreignCurrencyAllowed: false,
  warehouseInvoiceFrequency: '',
  warehouseInvoiceStartOfWeek: '',
  assignTruckToDrivers: false,
  includeLevyAdjustments: true,
  autoMarkCompletedFromDelivered: true,
  automaticContainerMovement: false,
  pickupDetailsWarning: false,
  deliveryDetailsWarning: false,
  showAdditionalSpecs: false,
  autoSubscribeNgr: false,
}

class CompanyForm extends Component {
  constructor(props) {
    super(props);
    this.setBrokerage = true;
    this.requiredFields = ['abn', 'businessName', 'address', 'mobile', 'paymentTermId'];
    this.brokerageFields = {
      feeType: null,
      rate: null,
      frequency: null,
      totalFee: null,
      chargedAt: null,
      subscriptionStartDate: null,
    };
    this.state = {
      country: getCurrentCountry(),
      existingLogoUrl: null,
      openSideDrawer: false,
      prevPayload: undefined,
      weekdays: weekdays(),
      noAction: false,
      noActionOnNonBrokerageFields: false,
      companyTypes: [],
      allCompanyTypes: [],
      transactionConfig: [],
      alias0: '',
      alias1: '',
      alias2: '',
      alias3: '',
      alias4: '',
      data: cloneDeep(initialDataFields),
      dataDup: {},
      showWarehouseChargedAfterDate: false,
      errors: {
        abn: (isObject(this.props.serverErrors) && has(this.props.serverErrors, 'abn')) ?  this.props.serverErrors.abn[0] : '',
        address: '',
        mobile: '',
        website: '',
        gstNumber: '',
        businessName: '',
        typeId: '',
        startOfWeek: '',
        endOfWeek: '',
        paymentTermId: '',
        warehouseInloadChargedFrom: '',
        loadTypeForVarietyMandatory: '',
        userTypeForVarietyMandatory: '',
        logo: '',
        feeType: '',
        rate: '',
        frequency: '',
        totalFee: '',
        chargedAt: '',
        subscriptionStartDate: '',
        hoursBeforeCancellationStops: '',
        bankApcaNumber: '',
      },
    };
    this.formRef = React.createRef();
    this.submit = this.submit.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.setAllFieldsValuesBySelectedCompany = this.setAllFieldsValuesBySelectedCompany.bind(this);
    this.onStartOfWeekChange = this.onStartOfWeekChange.bind(this);
    this.onMobileFlowChange = this.onMobileFlowChange.bind(this);
    this.onEndOfWeekChange = this.onEndOfWeekChange.bind(this);
    this.onOpenSideDrawer = this.onOpenSideDrawer.bind(this);
    this.onCloseSideDrawer = this.onCloseSideDrawer.bind(this);
    this.handleSelectFieldChange = this.handleSelectFieldChange.bind(this);
    this.handleLogoUpload = this.handleLogoUpload.bind(this);
    this.handleLogoRemove = this.handleLogoRemove.bind(this);
    this.handleBrokerageFeeTypeChange = this.handleBrokerageFeeTypeChange.bind(this);
    this.handleBrokerageFrequencyChange = this.handleBrokerageFrequencyChange.bind(this);
    this.handleBrokerageChargedAtChange = this.handleBrokerageChargedAtChange.bind(this);
    this.handleSubscriptionStartDateChange = this.handleSubscriptionStartDateChange.bind(this);
    this.handleWarehouseChargedAtChange = this.handleWarehouseChargedAtChange.bind(this);
    this.handleRestrictSlotCancellationChange = this.handleRestrictSlotCancellationChange.bind(this);
    this.handleContractCreationRestrictionChange = this.handleContractCreationRestrictionChange.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.handleHoursBeforeCancellationStopsChange = this.handleHoursBeforeCancellationStopsChange.bind(this);
    this.handleContractAllocationChange = this.handleContractAllocationChange.bind(this);
    this.onAliasChange = this.onAliasChange.bind(this);
    this.handleBankApcaNumberChange = this.handleBankApcaNumberChange.bind(this);
    this.addTransactionConfig = this.addTransactionConfig.bind(this);
    this.deleteTransaction = this.deleteTransaction.bind(this);
    this.handleTransactionConfigChanged = this.handleTransactionConfigChanged.bind(this);
    this.handleTransactionBlur = this.handleTransactionBlur.bind(this);
    this.areDuplicateTransactionsExists = this.areDuplicateTransactionsExists.bind(this);
    this.abn = this.state.country?.abn?.label || 'ABN'
    this.errorMessages = {
      abn: `Please enter a valid ${this.abn}`,
      address: 'Please enter a valid Address',
      businessName: 'Please enter a valid Business Name',
      typeId: 'Please select a primary business',
      mobile: 'Please enter a valid Phone/Mobile number',
      website: 'Please enter a valid Website',
      paymentTermId: 'Please select a payment term'
    };

  }

  componentDidMount(){
    this.props.getPaymentTerms();
    this.props.forceStopLoader();
    if(!this.props.selectedCompany && this.props.companyId && !this.state.isFetchingCompanyDetails){
      this.setState({isFetchingCompanyDetails: true}, () => this.props.getCompany(this.props.companyId));
    }
    if(isEmpty(this.state.companyTypes) && this.props.token) {
      APIService.companies().types().get(this.props.token).then(types => {
        const companyTypes = filter(types, (type) => { return type.id !== BROKER && type.id !== BHC_TYPE_ID; });
        this.setState({
          allCompanyTypes: types,
          companyTypes: companyTypes,
        });
      });
    }
  }

  componentDidUpdate(prevProps) {
    if(!this.props.selectedCompany && this.props.companyId && !this.state.isFetchingCompanyDetails){
      this.props.isLoading()
      this.setState(
        {isFetchingCompanyDetails: true},
        () => this.props.getCompany(this.props.companyId)
      );
    }
    if(this.props.updatedCompanyDetails && !isEqual(this.props.updatedCompanyDetails, prevProps.updatedCompanyDetails)) {
      let updatedCompanyDetails = pick(this.props.updatedCompanyDetails, [
        'abn', 'businessName', 'typeId', 'entityName', 'mobile', 'website',
        'startOfWeek', 'endOfWeek', 'brokerages']);
      updatedCompanyDetails.address = {
        name: this.props.updatedCompanyDetails.address.name,
        address: this.props.updatedCompanyDetails.address.address,
        latitude: this.props.updatedCompanyDetails.address.latitude,
        longitude: this.props.updatedCompanyDetails.address.longitude,
      };
      if (!isEqual(updatedCompanyDetails, this.state.data)) {
        this.setAllFieldsValuesBySelectedCompany();
      }
    }

    if(!isEqual(prevProps.serverErrors, this.props.serverErrors)) {
      if(has(this.props.serverErrors, 'transactionCode')) {
        this.setState({
          data: {
            ...this.state.data,
            transactionCode: get(this.state, 'prevPayload.transactionCode', '')
          }
        })
      }
    }
    if(!isEqual(prevProps.abn, this.props.abn)) {
      const newState = { ...this.state };
      newState.data.entityName = get(this.props.abn, 'entityName')
      this.setState(newState);
    }
    if(!isEqual(prevProps.selectedCompany, this.props.selectedCompany)) {
      const newState = { ...this.state };
      newState.country = getCurrentCountry(this.props.selectedCompany?.countryCode)
      newState.data.abn = get(this.props.selectedCompany, 'abn');
      this.setState(newState);
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    const newState = { ...this.state };
    newState.data.businessName = (props.abn.businessName) ? props.abn.businessName : '';
    newState.data.entityName = (props.abn.entityName) ? props.abn.entityName : '';
    forEach(props.serverErrors, (value, key) => {
      newState.errors[key] = value;
    });
    this.setState(newState);

    if (props.selectedCompany) {
      this.setAllFieldsValuesBySelectedCompany();
      if(this.props.headerText !== props.selectedCompany.name) {
        this.props.setHeaderText(props.selectedCompany.name);
      }
      const route = this.props.isFromCompanyDirectory == true ? '/companies': '/companies/groups'
      const breadcrumbs = [
        {text: 'Companies', route: route},
        {text: props.selectedCompany.name}
      ];
      if(!isEqual(this.props.breadcrumbs, breadcrumbs)) {
        this.props.setBreadcrumbs(breadcrumbs);
      }
    }
  }

  handleWarehouseChargedAtChange(value) {
      this.setState({data: {...this.state.data, warehouseChargedAt: value}, showWarehouseChargedAfterDate: value === 'outload_or_transfer_until_date'});
  }

  handleWarehouseInvoiceGroupGradesChange = event => {
    let value = event.target.checked
    if (value && this.props.selectedCompany.warehouseFeesWithGradesComboExists)
      alertify.error("Cannot enable this setting. Warehouse fees with grades combo exists!", 5)
    else
      this.setState({data: {...this.state.data, warehouseInvoiceGroupGrades: event.target.checked}});
  }
  handleCheckboxClick = event => this.setState({data: {...this.state.data, [event.target.id]: event.target.checked}});

  handleRestrictSlotCancellationChange(event) {
    let error = '';
    if (event.target.checked && !this.state.data.hoursBeforeCancellationStops)
      error = 'This field can not be blank';
    else
      error = '';
    this.setState({
      data: { ...this.state.data, restrictSlotCancellation: event.target.checked },
      errors: { ...this.state.errors, hoursBeforeCancellationStops: error }
    });
  }

  handleContractCreationRestrictionChange = (value, id) => this.setState({data: { ...this.state.data, [id]: value }});

  handleSelectFieldChange(value, id) {
    const newState = {...this.state};
    set(newState.data, id, value);
    this.setState(newState);
  }

  handleInvoicingDateChange = (value, id) => {
    if(value)
      value += '-01'
    this.handleSelectFieldChange(value, id)
  }

  handleBrokerageFeeTypeChange(value, id) {
    const newState = {...this.state};
    set(newState.data, id, value);
    this.setState(newState, () => this.cleanupBrokerages());
  }

  handleBrokerageFrequencyChange(value, id) {
    const newState = {...this.state};
    set(newState.data, id, value);

    if (!value && this.state.data.brokerages[0].feeType === 'Flat Fee'){
      newState.errors.frequency = 'This field can not be blank';
    } else if (value) {
      newState.errors.frequency = '';
    }

    this.setState(newState);
  }

  handleBrokerageChargedAtChange(value, id) {
    const newState = {...this.state};
    set(newState.data, id, value);

    if (!value && this.state.data.brokerages[0].feeType === 'Flat Fee'){
      newState.errors.chargedAt = 'This field can not be blank';
    } else if (value) {
      newState.errors.chargedAt = '';
    }

    this.setState(newState);
  }

  handleSubscriptionStartDateChange(value, id) {
    const newState = {...this.state};
    set(newState.data, id, value);

    if (!value && this.state.data.brokerages[0].feeType === 'Flat Fee'){
      newState.errors.subscriptionStartDate = 'This field can not be blank';
    } else if (value) {
      newState.errors.subscriptionStartDate = '';
    }

    this.setState(newState);
  }

  cleanupBrokerages() {
    const newState = {...this.state};
    const feeType = this.state.data.brokerages[0].feeType;
    if(includes(['Fee Per MT', '% of Sale'], feeType)) {
      newState.data.brokerages[0].totalFee = undefined;
      newState.errors.totalFee = '';
      newState.data.brokerages[0].frequency = undefined;
      newState.errors.frequency = '';
      newState.data.brokerages[0].chargedAt = undefined;
      newState.errors.chargedAt = '';
      newState.data.brokerages[0].subscriptionStartDate = undefined;
      newState.errors.subscriptionStartDate = '';
    } else if(includes(['Flat Fee'], this.state.data.brokerages[0].feeType), feeType) {
      newState.data.brokerages[0].rate = undefined;
      newState.errors.rate = '';
      newState.data.brokerages[0].subscriptionStartDate = moment().format('YYYY-MM-DD');
    } else {
      newState.data.brokerages[0].totalFee = undefined;
      newState.errors.totalFee = '';
      newState.data.brokerages[0].frequency = undefined;
      newState.errors.frequency = '';
      newState.data.brokerages[0].rate = undefined;
      newState.errors.rate = '';
      newState.data.brokerages[0].chargedAt = undefined;
      newState.errors.chargedAt = '';
      newState.data.brokerages[0].subscriptionStartDate = undefined;
      newState.errors.subscriptionStartDate = '';
    }
    this.setState(newState);
  }

  setBrokeragesErrors = () => {
    const newState = {...this.state};
    if (includes(['Fee Per MT', '% of Sale'], newState.data.brokerages[0].feeType)){
      if (!newState.data.brokerages[0].rate) {
        newState.errors.rate = 'This field can not be blank';
      }
    } else if (newState.data.brokerages[0].feeType === 'Flat Fee'){
      if (!newState.data.brokerages[0].totalFee) {
        newState.errors.totalFee = 'This field can not be blank';
      }
      if (!newState.data.brokerages[0].frequency) {
        newState.errors.frequency = 'This field can not be blank';
      }
      if (!newState.data.brokerages[0].chargedAt) {
        newState.errors.chargedAt = 'This field can not be blank';
      }
    }
    this.setState(newState);
  };

  submit(event) {
    event.preventDefault();
    const newState = { ...this.state };
    set(newState.data, 'extras.alias', compact([this.state.alias0, this.state.alias1, this.state.alias2, this.state.alias3, this.state.alias4]));
    forEach(this.requiredFields, (attr) => {
      if (attr === 'abn' && isSuperuser())
        newState.errors[attr] = '';
      else if(includes([null, undefined, ''], newState.data[attr])) {
        newState.errors[attr] = this.errorMessages[attr];
      }
    });
    newState.errors.gstNumber = '';
    if (newState.data.isVarietyMandatory) {
      newState.errors.loadTypeForVarietyMandatory = isEmpty(newState.data.loadTypeForVarietyMandatory) ? 'This field can not be blank' : '';
      newState.errors.userTypeForVarietyMandatory = isEmpty(newState.data.userTypeForVarietyMandatory)? 'This field can not be blank' : '';
    }
    else {
      newState.data.loadTypeForVarietyMandatory = [];
      newState.data.userTypeForVarietyMandatory = [];
      newState.errors.loadTypeForVarietyMandatory = '';
      newState.errors.userTypeForVarietyMandatory = '';
    }
    if (newState.data.warehouseChargedAt === 'outload_or_transfer_until_date')
      newState.errors.warehouseInloadChargedFrom = isEmpty(newState.data.warehouseInloadChargedFrom) ? 'This field can not be blank' : '';
    this.setBrokeragesErrors();
    this.setState(newState);
    if(every(this.state.errors, (v) => {return v === '';}))
    {
      let data = this.state.data;
      if(!this.shouldShowBrokerages() || this.isBrokerageSame()) {
        delete data.brokerages;
      } else if(this.isBrokerageNotFilled()) {
        data.brokerages = [];
      } else {
        data.brokerages[0].brokerCompanyId = this.props.user.companyId;
        data.brokerages[0].totalFee = data.brokerages[0].totalFee ? parseFloat(data.brokerages[0].totalFee) : null;
        data.brokerages[0].rate = data.brokerages[0].rate ? parseFloat(data.brokerages[0].rate) : null;
      }
      if(this.state.existingLogoUrl && !data.logo.base64) {
        delete data.logo;
      }

      if(this.state.data.transactionCode) {
        let transactionReferenceNumberConfigs = [];
        if(this.state.transactionConfig.length) {
          const areTransactionsValid = this.state.transactionConfig.every((transaction) => transaction.isValid);
          if(!areTransactionsValid)
            return;
          if(this.areDuplicateTransactionsExists()) {
            alertify.error("Duplicate Transaction Configs are not allowed");
            return
          }

          this.state.transactionConfig.forEach((transactionConfig) => {
            const transaction = Object.assign({}, transactionConfig);
            delete transaction.isValid;
            if(transaction.transactionType || transaction.startFrom || transaction.length){
              transactionReferenceNumberConfigs.push(transaction);
            }
          });
        }
        data.transactionReferenceNumberConfigs = transactionReferenceNumberConfigs;
      }

      if(this.props.selectedCompany){
        if(isEmpty(data.address.latitude) || !isNumber(data.address.latitude)){
          data.address.latitude = DEFAULT_LAT;
          data.address.longitude = DEFAULT_LNG;
        }
        if(!this.state.data.bulkInvoicingFrom)
          data.bulkInvoicingFrom = null
        if(!this.state.data.contractInvoicingFrom)
          data.contractInvoicingFrom = null
        if(!this.state.data.freightInvoicingFrom)
          data.freightInvoicingFrom = null
        if(!this.state.data.paymentDueDays)
          data.paymentDueDays = 15
        this.setState({prevPayload: data});
        this.props.updateCompany(this.props.selectedCompany.id, data, this.onCloseSideDrawer);
      } else {
        if(get(data, 'address.address') && !get(data, 'address.latitude') && !get(data, 'address.longitude')){
          data.address.latitude = DEFAULT_LAT;
          data.address.longitude = DEFAULT_LNG;
        }
        this.setState({prevPayload: data});
        this.props.addCompany(data);
      }
    }
  }

  isBrokerageSame() {
    if(this.shouldShowBrokerages()) {
      const oldBrokerages = pick(
        this.props.selectedCompany.brokeragesForUser[0],
        ['feeType', 'rate', 'totalFee', 'chargedAt', 'subscriptionStartDate', 'frequency']
      );
      const updatedBrokerages = pick(
        this.state.data.brokerages[0],
        ['feeType', 'rate', 'totalFee', 'chargedAt', 'subscriptionStartDate', 'frequency']
      );
      return isEqual(oldBrokerages, updatedBrokerages);
    }
  }

  isBrokerageNotFilled() {
    return isEmpty(this.state.data.brokerages[0].feeType) && (isEmpty(this.state.data.brokerages[0].rate) || isEmpty(this.state.data.brokerages[0].totalFee));
  }

  onBlurAbn(event) {
    this.validate(event);
    const newState = { ...this.state };
    if (event.target.value && !isSuperuser()) {
      newState.data.entityName = '';
      newState.data.businessName = '';
    }
    this.setState(newState);
    let isEditCompany = !isEmpty(this.props.selectedCompany)
    if(!this.state.errors[event.target.id] && this.state.data[event.target.id] && !event.target.value.startsWith('00')) {
      this.props.fetchNameFromABN(event.target.value, isEditCompany);
    }
  }

  updateDomAndState(event, predicate=false) {
    const newState = { ...this.state };

    if(predicate) {
      newState.errors[event.target.id] = this.errorMessages[event.target.id];
    } else {
      newState.errors[event.target.id] = '';
    }
    this.setState(newState)
  }

  onChange(event) {
    const { data } = this.state;
    set(data, event.target.id, event.target.value);
    this.setState({ data });
  }

  onBrokerageTextFieldsChange(event){
    const newState = {...this.state};
    const field = event.target.id.split('.')[1];
    if (!event.target.value){
      newState.errors[field] = 'This field can not be blank';
    } else {
      newState.errors[field] = '';
    }
    set(newState.data, event.target.id, event.target.value);
    this.setState(newState);
  }


  onSelectChange(typeId) {
    if (isNumber(typeId) && this.state.data.typeId != typeId) {
      const newState = {...this.state};
      newState.data.typeId = typeId;
      this.setState(newState);
    }
  }

  handleHoursBeforeCancellationStopsChange(value) {
    if (value && this.state.data.hoursBeforeCancellationStops !== value) {
      let error = '';
      if (!value)
        error = 'This field can not be blank';
      else
        error = '';
      this.setState({
        data: { ...this.state.data, hoursBeforeCancellationStops: value },
        errors: { ...this.state.errors, hoursBeforeCancellationStops: error }
      });
    }
  }

  handleContractAllocationChange(id, selectedItems) {
    let value = selectedItems.map(obj => obj.id)
    if (this.state.data.contractAllocations !== value)
      this.setState({data: { ...this.state.data, contractAllocations: value }});
  }

  onMobileFlowChange(value) {
    if(value && this.state.data.mobileFlow !== value) {
      this.setState({data: {...this.state.data, mobileFlow: value}});
    }
  }

  handleTransactionBlur() {
    const newState = { ...this.state };
    set(newState.errors, 'transactionCode', "");
    this.setState(newState);
  }

  areDuplicateTransactionsExists() {
    let allTransactions = map(this.state.transactionConfig, transaction => get(transaction, 'transactionType'));
    return hasDuplicates(allTransactions);
  }

  onPlanTypeChange = value => {
    if(value && this.state.data.planType !== value) {
      const newState = { ...this.state };
      if(isEqual(value, LOGISTICS_LITE_PLAN)) {
        newState.data.canRepresent = false;
        newState.data.mobileParticipation = false;
        newState.data.transactionParticipation = false;
      } else {
        newState.data.canRepresent = get(this.state.dataDup, 'canRepresent');
        newState.data.mobileParticipation = get(this.state.dataDup, 'mobileParticipation');;
        newState.data.transactionParticipation = get(this.state.dataDup, 'transactionParticipation');;
      }
      newState.data.planType = value;
      this.setState(newState);
    }
  };

  rowsPerPageChange = value => {
    if(value && this.state.data.rowPerPage !== value) {
      this.setState({data: {...this.state.data, rowsPerPage: value}});
    }
  }

  warehouseInvoiceFrequencyChange = value => {
    if(this.state.data.warehouseInvoiceFrequency !== value) {
      this.setState({data: {...this.state.data, warehouseInvoiceFrequency: value}});
    }
  }

  warehouseInvoiceStartOfWeekChange = value=> {
    if (isNumber(value) && this.state.data.warehouseInvoiceStartOfWeek != value) {
      const newState = {...this.state};
      newState.data.warehouseInvoiceStartOfWeek = value;
      this.setState(newState);
    }
  }

  onStartOfWeekChange(value) {
    if (isNumber(value) && this.state.data.startOfWeek != value) {
      const newState = {...this.state};
      newState.data.startOfWeek = value;
      if(value == this.state.data.endOfWeek) {
        newState.errors.endOfWeek = 'Cannot be same as Start of Week';
      }
      else {
        newState.errors.endOfWeek = '';
      }
      this.setState(newState);
    }
  }

  onEndOfWeekChange(value) {
    if (isNumber(value) && this.state.data.endOfWeek != value) {
      const newState = {...this.state};
      newState.data.endOfWeek = value;
      if(this.state.data.startOfWeek == value) {
        newState.errors.endOfWeek = 'Cannot be same as Start of Week';
      }
      else {
        newState.errors.endOfWeek = '';
      }
      this.setState(newState);
    }
  }

  onBlur(event) {
    this.validate(event);
  }

  onAddressChange(data) {
    const newState = { ...this.state };
    newState.data.address = {};
    newState.data.address.name = data.name;
    newState.data.address.address = data.address;
    newState.data.address.latitude = data.lat;
    newState.data.address.longitude = data.lng;

    this.setState(newState);
    this.validateAddress();
  }

  validateAddress() {
    const newState = { ...this.state };
    if(newState.data.address.name === ''){
      newState.errors.address = this.errorMessages.address;
    } else {
      newState.errors.address = '';
    }
    this.setState(newState);
  }

  validate(event) {
    const { country } = this.state
    if(["website"].includes(event.target.id)) {
      this.updateDomAndState(
        event,
        (event.target.value != "" && !event.target.value.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/)) // eslint-disable-line
      );
    }
    else if(["mobile"].includes(event.target.id)) {
      this.updateDomAndState(
        event,
        !event.target.value.match(country.config.phoneMobileRegex)
      );
    }
    else if(["address", "businessName"].includes(event.target.id)) {
      this.updateDomAndState(
        event,
        event.target.value == ''
      );
    }
    else if (["abn"].includes(event.target.id) && !isSuperuser()) {
      this.updateDomAndState(
        event,
        event.target.value.length != 11
      );
    }
    const newState = { ...this.state };
    newState.data[event.target.id] = event.target.value;
    this.setState(newState);
  }

  setAllFieldsValuesBySelectedCompany() {
    const { selectedCompany } = this.props;
    const newState = { ...this.state };
    if(selectedCompany) {
      const data = {
        abn: this.state.data.abn ? this.state.data.abn : selectedCompany.abn,
        entityName: get(this.props.abn, 'entityName') ? get(this.props.abn, 'entityName') : selectedCompany.entityName,
        businessName: selectedCompany.businessName,
        mobile: selectedCompany.mobile,
        address: {
          name: selectedCompany.address.name,
          address: selectedCompany.address.address,
          latitude: selectedCompany.address.latitude,
          longitude: selectedCompany.address.longitude
        },
        typeId: selectedCompany.typeId,
        website: selectedCompany.website || '',
        gstNumber: selectedCompany.gstNumber || '',
        startOfWeek: selectedCompany.startOfWeek,
        endOfWeek: selectedCompany.endOfWeek,
        paymentTermId: selectedCompany.paymentTermId,
        logo: {
          base64: null,
          name: null,
        },
        brokerages: this.setBrokerage ? [this.brokerageFields] : this.state.data.brokerages,
        transactionParticipation: selectedCompany.transactionParticipation,
        mobileParticipation: selectedCompany.mobileParticipation,
        canRepresent: selectedCompany.canRepresent,
        mobileFlow: selectedCompany.mobileFlow,
        warehouseChargedAt: selectedCompany.warehouseChargedAt,
        warehouseInloadChargedFrom: get(selectedCompany, 'warehouseInloadChargedFrom'),
        xeroInvoiceStatus: selectedCompany.xeroInvoiceStatus,
        restrictSlotCancellation: selectedCompany.restrictSlotCancellation,
        showCostsInFm: selectedCompany.showCostsInFm,
        purchaseContractCreationRestriction: selectedCompany?.purchaseContractCreationRestriction,
        saleContractCreationRestriction: selectedCompany?.saleContractCreationRestriction,
        mobileOrdersWithinDeliveryRange: selectedCompany.mobileOrdersWithinDeliveryRange,
        additionalMassLimitCodes: selectedCompany.additionalMassLimitCodes,
        acquisitionFileUpload: selectedCompany.acquisitionFileUpload,
        contractNumberMandatoryInTransfers: selectedCompany.contractNumberMandatoryInTransfers,
        isVarietyMandatoryInCommodityDec: selectedCompany.isVarietyMandatoryInCommodityDec,
        isHalalDeclarationMandatory: selectedCompany.isHalalDeclarationMandatory,
        foreignCurrencyAllowed: selectedCompany.foreignCurrencyAllowed,
        warehouseInvoiceFrequency: selectedCompany.warehouseInvoiceFrequency,
        warehouseInvoiceStartOfWeek: selectedCompany.warehouseInvoiceStartOfWeek,
        assignTruckToDrivers: selectedCompany.assignTruckToDrivers,
        includeLevyAdjustments: selectedCompany.includeLevyAdjustments,
        autoMarkCompletedFromDelivered: selectedCompany.autoMarkCompletedFromDelivered,
        automaticContainerMovement: selectedCompany.automaticContainerMovement,
        pickupDetailsWarning: selectedCompany.pickupDetailsWarning,
        deliveryDetailsWarning: selectedCompany.deliveryDetailsWarning,
        showAdditionalSpecs: selectedCompany.showAdditionalSpecs,
        autoSubscribeNgr: selectedCompany.autoSubscribeNgr,
        fillDocket: selectedCompany.fillDocket,
        shrinkageOnInloadForAll: selectedCompany?.shrinkageOnInloadForAll,
        planType: get(selectedCompany, 'platformfeatures.planType'),
        hoursBeforeCancellationStops: get(selectedCompany, 'hoursBeforeCancellationStops'),
        passwordExpiryPeriod: get(selectedCompany, 'passwordExpiryPeriod'),
        overdraftTransferAllowed: selectedCompany.overdraftTransferAllowed,
        warehouseInvoiceGroupGrades: selectedCompany.warehouseInvoiceGroupGrades,
        canUploadContractCsv: selectedCompany.canUploadContractCsv,
        enableCustomCsv: selectedCompany.enableCustomCsv,
        multiSlotBooking: selectedCompany.multiSlotBooking,
        enableTransferToApprovedBuyers: selectedCompany.enableTransferToApprovedBuyers,
        defaultLevyEpr: selectedCompany.defaultLevyEpr,
        automaticallyAddInload: selectedCompany.automaticallyAddInload,
        automaticallyAddOutload: selectedCompany.automaticallyAddOutload,
        showCashPricesToAll: selectedCompany.showCashPricesToAll,
        xeroEnabled: selectedCompany.xeroEnabled,
        isVarietyMandatory: selectedCompany.isVarietyMandatory,
        loadTypeForVarietyMandatory: selectedCompany.loadTypeForVarietyMandatory,
        userTypeForVarietyMandatory: selectedCompany.userTypeForVarietyMandatory,
        haveField: selectedCompany.haveField,
        isSellingToIndependentSiteBuyer: selectedCompany.isSellingToIndependentSiteBuyer,
        isIndependentSiteBuyer: selectedCompany.isIndependentSiteBuyer,
        onlyCreatorCanAmendContract: selectedCompany.onlyCreatorCanAmendContract,
        onlyCreatorCanAmendOrder: selectedCompany.onlyCreatorCanAmendOrder,
        warehouseInvoiceCommoditySeparated: selectedCompany.warehouseInvoiceCommoditySeparated,
        enableMyStocksOnMobile: selectedCompany.enableMyStocksOnMobile,
        contractAllocations: selectedCompany.contractAllocations,
        extras: {
          alias: [],
          grower_number: get(selectedCompany, 'extras.growerNumber')
        },
        rowsPerPage: get(selectedCompany, 'rowsPerPage'),
        bulkInvoicingFrom: get(selectedCompany, 'bulkInvoicingFrom'),
        contractInvoicingFrom: get(selectedCompany, 'contractInvoicingFrom'),
        freightInvoicingFrom: get(selectedCompany, 'freightInvoicingFrom'),
        paymentDueDays: get(selectedCompany, 'paymentDueDays', 15),
        bankApcaNumber: get(selectedCompany, 'bankApcaNumber'),
        transactionCode: get(selectedCompany, 'transactionCode')
      };

      let transactionConfigs = get(selectedCompany, 'transactionReferenceNumberConfigs')
      let allTransactions = keys(transactionConfigs)
      let parsedTransactionConfigs = [];
      if(allTransactions.length) {
        map(allTransactions, transaction => {
          let transactionType = get(TRANSACTION_TYPE_MAPPER, transaction);
          parsedTransactionConfigs.push({
            transactionType: transactionType,
            prefix: get(transactionConfigs, `${transaction}.prefix`, ''),
            startFrom: get(transactionConfigs, `${transaction}.startFrom`, ''),
            length: get(transactionConfigs, `${transaction}.length`, ''),
            isValid: true,
          })
        })
      }
      if (get(selectedCompany, 'warehouseChargedAt') === 'outload_or_transfer_until_date')
        newState.showWarehouseChargedAfterDate = true;
      newState.transactionConfig = parsedTransactionConfigs;

      if(this.shouldShowBrokerages() && this.setBrokerage) {
        this.setBrokerage = false;
        if(this.props.selectedCompany.brokeragesForUser[0]) {
          const brokerage = pick(
            this.props.selectedCompany.brokeragesForUser[0],
            ['feeType', 'rate', 'totalFee', 'frequency', 'chargedAt', 'subscriptionStartDate']
          );
          data.brokerages = [cloneDeep(brokerage)];
        }
      }
      newState.data = data;
      newState.dataDup = cloneDeep(data);
      newState.existingLogoUrl = this.props.selectedCompany.logoUrl;
      map([0, 1, 2, 3, 4], order => {
        set(newState, `alias${order}`, get(selectedCompany, `extras.alias[${order}]`));
      });

      if(isSystemCompany()){
        newState.noAction = false;
        newState.noActionOnNonBrokerageFields = false;
      } else {
        if(this.props.user.company.typeId == 2 || (this.props.selectedCompany.id === this.props.user.companyId && canCreateOrUpdate(this.props.user, 'company', false))) {
          newState.noAction = false;
        } else {
          newState.noAction = this.props.selectedCompany.typeId === 4 || this.props.selectedCompany.isRegistered;
        }
        newState.noActionOnNonBrokerageFields = (((this.props.selectedCompany.id !== this.props.currentUserCompanyId) && (this.props.selectedCompany.isRegistered || this.props.selectedCompany.typeId === BHC_TYPE_ID)) || ((this.props.selectedCompany.id === this.props.user.companyId) && !canCreateOrUpdate(this.props.user, 'company', false)));
      }
    }
    this.setState(newState);
  }

  onOpenSideDrawer() {
    this.setAllFieldsValuesBySelectedCompany();
    this.setState({
      openSideDrawer: true,
    });
  }

  onCloseSideDrawer() {
    this.setState({
      data: cloneDeep(initialDataFields),
      openSideDrawer: false,
    }, () => this.props.setABNResponse({}));
  }

  handleLogoUpload(fileState){
    const newState = {...this.state};
    newState.data.logo = fileState.file;
    this.setState(newState);
  }

  handleLogoRemove() {
    const newState = {...this.state};
    newState.data.logo.base64 = null;
    newState.data.logo.name = null;
    newState.existingLogoUrl = null;
    this.setState(newState);
  }

  shouldShowBrokerages() {
    return this.props.user && this.props.user.company.typeId === 2 && this.props.selectedCompany && this.props.user.companyId !== this.props.selectedCompany.id;
  }

  shouldDisableParticipation() {
    const { selectedCompany } = this.props;
    if(selectedCompany)
      return !selectedCompany.isRegistered;

    return false;
  }

  onAliasChange(event) {
    const newState = {...this.state};
    set(newState, event.target.id, toLower(event.target.value));
    this.setState(newState);
  }

  handleBankApcaNumberChange(event) {
    const newState = {...this.state};
    const value = event.target.value;
    if (value.length != 6) {
      newState.errors.bankApcaNumber = 'This field requires 6 digits';
    }
    else {
      newState.errors.bankApcaNumber = '';
      newState.data.bankApcaNumber = value;
    }
    this.setState(newState);
  }

  handleTransactionConfigChanged(index, transactionConfig) {
    const newState = { ...this.state };
    newState.transactionConfig[index] = transactionConfig;
    this.setState(newState);
  }

  addTransactionConfig() {
   let config = {
      transactionType: '',
      prefix: '',
      startFrom: '',
      length: '',
      isValid: false,
    }
    const newState = { ...this.state };
    newState.transactionConfig = [
      ...this.state.transactionConfig,
      config
    ]
    this.setState(newState);
  }

  deleteTransaction(itemIndex) {
    const newState = { ...this.state };
    let transactionConfig = [...newState.transactionConfig];
    transactionConfig.splice(itemIndex, 1);
    newState.transactionConfig = transactionConfig;
    this.setState(newState);
  }

  showAPCANumberField() {
    return isVisibleInCountry('bank_apca_number') && (isSystemCompany() || this.props.selectedCompany?.id === currentUserCompany()?.id && isCompanyAdminOrObserver());
  }

  onMultiSelectChange = (id, selectedItems) => {
    const newState = {...this.state};
    if(newState.data.isVarietyMandatory && isEmpty(selectedItems)) {
      newState.errors[id] = 'This field cannot be blank';
      newState.data[id] = []
    } else {
      newState.data[id]= map(selectedItems, 'id');
      newState.errors[id] = '';
    }
    this.setState(newState);
  };

  handleXeroInvoiceStatusChange = value => this.setState({data: {...this.state.data, xeroInvoiceStatus: value}});

  render() {
    const selectedCompany = this.props.selectedCompany;
    const country = getCurrentCountry(selectedCompany?.countryCode)
    const companyType = find(this.state.allCompanyTypes,{id: this.state.data.typeId });
    const paymentTermName = (!isEmpty(this.props.paymentTerms) && selectedCompany && selectedCompany.paymentTermId) ? find(this.props.paymentTerms, {id: selectedCompany.paymentTermId})?.name : EMPTY_VALUE;
    const isSystem = isSystemCompany()
    const showWarehouseChargedAfterDate = this.state.showWarehouseChargedAfterDate;
    const isEditable = isCompanyEditable(selectedCompany)
    const showAPCANumberField = this.showAPCANumberField()
    const isGSTVisible = this.state.country?.config?.invoicing?.gst
    return (
      <div ref={this.formRef}>
        {
          this.state.openSideDrawer &&
          <SideDrawer
            isOpen={this.state.openSideDrawer}
            title="Edit Company"
            size="small"
            onClose={this.onCloseSideDrawer}
          >
            <div className="cardForm cardForm--drawer" style={{marginTop: '15px'}}>
              <div className="cardForm-content row">
              <div className="col-sm-12 form-wrap-70" style={{padding: '0px 0px 15px 0px'}}>
                  <FileUpload
                    id="logo"
                    floatingLabelText="Attach File"
                    fullWidth
                    textFieldstyle={{float: 'left', color: 'black'}}
                    buttonStyle={{border: '1px solid'}}
                    onChange={this.handleLogoUpload}
                    buttonText="Upload Logo"
                    allowedExtensions="image/*"
                    previewStyle={{marginTop: '10px', width: '100%', marginLeft: '0px'}}
                    onRemove={this.handleLogoRemove}
                    fileCount={(get(this.state, 'data.logo.base64') || this.state.existingLogoUrl) ? 2 : 0}
                    showImagePreviewFromURL={this.state.existingLogoUrl}
                    disabled={!isEditable}
                  />
                </div>
                <div className="col-sm-12 form-wrap-70">
                  <ABNField
                    value={this.state.data.abn}
                    helperText={(has(this.props.serverErrors, 'abn')) ? this.props.serverErrors.abn : (this.state.errors.abn || this.props.abnError)}
                    onBlur={this.onBlurAbn.bind(this)}
                    onChange={this.onChange.bind(this)}
                    disabled={!isSystemCompany() && !isEmpty(this.props.selectedCompany)}
                    countryCode={this.props.selectedCompany?.countryCode}
                  />
                </div>
                <div className="col-sm-12 form-wrap-70">
                  <CommonTextField
                    id="entityName"
                    disabled={!isSuperuser()}
                    value={this.state.data.entityName}
                    label="Entity Name"
                    onChange={this.onChange.bind(this)}
                  />
                </div>
                <div className="col-sm-12 form-wrap-70">
                  <CommonTextField
                    id="businessName"
                    label="Business Name"
                    placeholder="Please enter"
                    onChange={this.onChange.bind(this)}
                    value={this.state.data.businessName}
                    helperText= {this.state.errors.businessName}
                    onBlur={this.validate.bind(this)}
                    maxLength="100"
                    disabled={!isEditable}
                  />
                </div>
                <div className="col-sm-12 form-wrap-70">
                  <AutoComplete
                    id="address"
                    placeholder="Please select an address from the suggestions"
                    floatingLabelText="Address"
                    fullWidth
                    errorText= {(has(this.props.serverErrors, 'address')) ?  this.props.serverErrors.address : this.state.errors.address}
                    onChange={this.onAddressChange.bind(this)}
                    errorStyle={{textAlign: "left"}}
                    value={this.state.data.address.address}
                    name={this.state.data.address.name}
                    onBlur={this.validateAddress.bind(this)}
                    disabled={!isEditable}
                    countryCode={this.props.selectedCompany?.countryCode}
                  />
                </div>
                <div className="col-sm-12 form-wrap-70">
                  <PhoneField
                    id="mobile"
                    label="Phone/Mobile"
                    value={this.state.data.mobile}
                    helperText={(has(this.props.serverErrors, 'mobile')) ?  this.props.serverErrors.mobile : this.state.errors.mobile}
                    onBlur={this.validate.bind(this)}
                    onChange={this.onChange.bind(this)}
                    disabled={!isEditable}
                    countryCode={this.props.selectedCompany?.countryCode}
                  />
                </div>
                <div className="col-sm-12 form-wrap-70">
                  <CommonTextField
                    id="website"
                    label="Website (Optional)"
                    placeholder="Please enter"
                    value={this.state.data.website}
                    onBlur={this.validate.bind(this)}
                    onChange={this.onChange.bind(this)}
                    disabled={!isEditable}
                    helperText={(has(this.props.serverErrors, 'website')) ?  this.props.serverErrors.website : this.state.errors.website}
                  />
                </div>
                { showAPCANumberField &&
                  <div className="col-sm-12 form-wrap-70">
                    <CommonTextField
                      id="bankApcaNumber"
                      label="Direct Entry User ID/APCA Number"
                      placeholder="Please enter"
                      value={this.state.data.bankApcaNumber}
                      type='number'
                      onChange={this.handleBankApcaNumberChange}
                      helperText={this.state.errors.bankApcaNumber}
                      onInput = {(e) =>{
                        e.target.value = e.target.value.toString().slice(0,6);
                      }}
                    />
                  </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <CommonTextField
                        id="passwordExpiryPeriod"
                        label="Password Expiry"
                        placeholder="Please enter"
                        onChange={this.onChange.bind(this)}
                        value={this.state.data.passwordExpiryPeriod}
                        maxLength="3"
                        onKeyDown={event => positiveDecimalFilter(event, 2, 2160)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='start' style={{ color: 'rgb(162,162,162)'}}>
                              Days
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                }
                {
                  isSystem &&
                  <React.Fragment>
                    <div className="col-sm-12 form-wrap-70">
                      <CommonTextField
                        id="transactionCode"
                        label='Transaction Code'
                        placeholder='Transaction Code'
                        onChange={this.onChange.bind(this)}
                        onBlur={this.handleTransactionBlur}
                        value={this.state.data.transactionCode}
                        maxLength="4"
                        helperText={(has(this.state.errors, 'transactionCode')) ?  get(this.state.errors, 'transactionCode[0]') : ''}
                      />
                    </div>
                    {
                      map(this.state.transactionConfig, (transaction, index) => {
                        return (
                          <TransactionConfig
                            key={index}
                            {...transaction}
                            index={index}
                            transactionItems={TRANSACTION_TYPES}
                            deleteTransaction={this.deleteTransaction}
                            valueChanged={this.handleTransactionConfigChanged}
                          />
                        )
                      })
                    }
                    <div className="col-sm-12 form-wrap-70 padding-reset">
                      <CommonButton
                        label='Add Transaction Config'
                        color='secondary'
                        onClick={this.addTransactionConfig}
                        variant="outlined"
                        disabled={!this.state.data.transactionCode}
                      />
                    </div>
                  </React.Fragment>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <CommonTextField
                        type='number'
                        id="paymentDueDays"
                        label='Payment Receival Days'
                        onChange={this.onChange.bind(this)}
                        value={get(this.state, 'data.paymentDueDays')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end' style={{ color: 'rgb(162,162,162)'}}>
                              days
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                }
                <div className="col-sm-12 form-wrap-70">
                  {isSystemCompany() && companyType ?
                  (
                    <CommonSelect
                      id="primaryBusiness"
                      floatingLabelText="Primary Business"
                      value={this.state.data.typeId}
                      selectedItemId={this.state.data.typeId}
                      errorText= {get(this.state.errors, 'typeId', '')}
                      selectConfig={{text: 'displayName', value: 'id'}}
                      onChange={this.onSelectChange}
                      items={this.state.allCompanyTypes}
                    />
                  ) :
                  (
                    companyType ?
                      <CommonTextField
                        id="primaryBusiness"
                        label="Primary Business"
                        value={companyType.displayName}
                        disabled
                      /> : ''
                  )}
                </div>
                <div className="col-sm-12 form-wrap-70">
                  <CommonSelect
                    id="startOfWeek"
                    floatingLabelText="Start of week"
                    selectedItemId={this.state.data.startOfWeek}
                    value={this.state.data.startOfWeek}
                    errorText= {this.state.errors.startOfWeek}
                    selectConfig={{text: 'displayName', value: 'id'}}
                    onChange={this.onStartOfWeekChange}
                    items={this.state.weekdays}
                    disabled={!isEditable}
                  />
                </div>
                <div className="col-sm-12 form-wrap-70">
                  <CommonSelect
                    id="endOfWeek"
                    floatingLabelText="End of week"
                    selectedItemId={this.state.data.endOfWeek}
                    value={this.state.data.endOfWeek}
                    errorText= {this.state.errors.endOfWeek}
                    selectConfig={{text: 'displayName', value: 'id'}}
                    onChange={this.onEndOfWeekChange}
                    items={this.state.weekdays}
                    disabled={!isEditable}
                  />
                </div>
                <div className="col-sm-12 form-wrap-70">
                  <CommonAutoSelect
                    id="paymentTermId"
                    onChange={this.handleSelectFieldChange}
                    label="Payment Term"
                    dataSourceConfig={{text: 'name', value: 'id'}}
                    value={this.state.data.paymentTermId}
                    errorText={this.state.errors.paymentTermId}
                    items={this.props.paymentTerms}
                    disabled={!isEditable}
                  />
                </div>
                {isGSTVisible && <div className="col-sm-12 form-wrap-70">
                  <CommonTextField
                    id="gstNumber"
                    value={this.state.data.gstNumber}
                    label={getCountryLabel('gst')}
                    onChange={this.onChange.bind(this)}
                    helperText={this.state.errors.gstNumber}
                    disabled={selectedCompany?.isRegistered && !isSuperuser() && !isCurrentUserCompanyAdmin() && !isObserver()}
                  />
                </div>}
                {
                  this.shouldShowBrokerages() ?
                    <div className="col-sm-12 form-wrap-70">
                      <div>
                        <CommonSelect
                          id="brokerages[0].feeType"
                          floatingLabelText="Brokerage Fee Type"
                          selectConfig={{text: 'name', value: 'id'}}
                          items={BROKERAGE_FEE_TYPES}
                          value={this.state.data.brokerages[0]?.feeType || null}
                          onChange={this.handleBrokerageFeeTypeChange}
                          style={{width: '100%'}}
                          includeEmptyOption
                        />
                      </div>
                      {
                        this.state.data.brokerages[0]?.feeType === 'Fee Per MT'?
                          <div>
                            <TextField
                              id="brokerages[0].rate"
                              style={{width: '100%', marginTop: "5px", marginBottom: "15px"}}
                              label="Rate"
                              placeholder="Rate"
                              onKeyDown={(event)=>positiveDecimalFilter(event, 2, 9999)}
                              value={this.state.data.brokerages[0].rate}
                              onChange={this.onBrokerageTextFieldsChange.bind(this)}
                              helperText={this.state.errors.rate}
                              error={!!this.state.errors.rate}
                              variant="standard" />
                          </div>
                        : null
                      }
                      {
                        this.state.data.brokerages[0]?.feeType === '% of Sale' ?
                          <div>
                            <TextField
                              id="brokerages[0].rate"
                              style={{width: '100%'}}
                              label="Rate"
                              placeholder="Rate"
                              onKeyDown={(event)=>positiveDecimalFilter(event, 2, 100)}
                              value={this.state.data.brokerages[0].rate}
                              onChange={this.onBrokerageTextFieldsChange.bind(this)}
                              helperText={this.state.errors.rate}
                              error={!!this.state.errors.rate}
                              variant="standard" />
                          </div>
                        : null
                      }
                      {
                        this.state.data.brokerages[0]?.feeType === 'Flat Fee' ?
                          <div style={{display: "flex", flexDirection: "column", rowGap: "15px"}}>
                            <CommonSelect
                              id="brokerages[0].frequency"
                              floatingLabelText="Frequency"
                              selectConfig={{text: 'name', value: 'id'}}
                              items={BROKERAGE_FREQUENCIES}
                              value={this.state.data.brokerages[0].frequency}
                              errorText={this.state.errors.frequency}
                              onChange={this.handleBrokerageFrequencyChange}
                              style={{width: '100%'}}
                            />
                            <TextField
                              id="brokerages[0].totalFee"
                              style={{width: '100%'}}
                              label="Fee"
                              placeholder="Fee"
                              onKeyDown={(event)=>positiveDecimalFilter(event, 2,99999.99)}
                              value={this.state.data.brokerages[0].totalFee}
                              onChange={this.onBrokerageTextFieldsChange.bind(this)}
                              InputProps={{
                                startAdornment: <InputAdornment position="start" style={{color: 'rgb(162,162,162)'}}>$</InputAdornment>
                              }}
                              helperText={this.state.errors.totalFee}
                              error={!!this.state.errors.totalFee}
                              variant="standard" />
                            <CommonSelect
                              id="brokerages[0].chargedAt"
                              floatingLabelText="Charged At"
                              selectConfig={{text: 'name', value: 'id'}}
                              items={CHARGED_AT_PERIODS}
                              value={this.state.data.brokerages[0].chargedAt}
                              errorText={this.state.errors.chargedAt}
                              onChange={this.handleBrokerageChargedAtChange}
                              style={{width: '100%'}}
                            />
                            <CommonDatePicker
                              id="brokerages[0].subscriptionStartDate"
                              floatingLabelText="Periodic Brokerage Start Date"
                              errorText={this.state.errors.subscriptionStartDate}
                              value={this.state.data.brokerages[0].subscriptionStartDate}
                              onChange={this.handleSubscriptionStartDateChange}
                            />
                          </div>
                        : null
                      }
                    </div>
                  : null
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70" style={{marginTop: "15px"}}>
                      <CommonSelect
                        id="mobileFlow"
                        floatingLabelText="Mobile Flow"
                        selectedItemId={this.state.data.mobileFlow}
                        value={this.state.data.mobileFlow}
                        onChange={this.onMobileFlowChange}
                        items={[{id: 'storage', name: 'Storage'}, {id: 'logistics', name: 'Logistics'}]}
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <CommonSelect
                        id="planType"
                        floatingLabelText="Platform Access"
                        selectedItemId={this.state.data.planType}
                        value={this.state.data.planType}
                        onChange={this.onPlanTypeChange}
                        items={[{id: PREMIUM_PLAN, name: 'Full'}, {id: LOGISTICS_LITE_PLAN, name: 'Lite'}]}
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <CommonSelect
                        id="warehouseChargedAt"
                        floatingLabelText="Inload Charged At"
                        selectedItemId={this.state.data.warehouseChargedAt}
                        value={this.state.data.warehouseChargedAt}
                        onChange={this.handleWarehouseChargedAtChange}
                        items={WAREHOUSE_INLOAD_FEES_CHARGED_AT}
                      />
                    </div>
                }
                {
                  isSystem && showWarehouseChargedAfterDate &&
                    <div className="col-sm-12 form-wrap-70">
                      <CommonDatePicker
                        type='month'
                        id="warehouseInloadChargedFrom"
                        floatingLabelText="Inload Charged From"
                        errorText={this.state.errors.warehouseInloadChargedFrom}
                        value={this.state.data.warehouseInloadChargedFrom ? this.state.data.warehouseInloadChargedFrom.replace('-01', '') : this.state.data.warehouseInloadChargedFrom}
                        onChange={this.handleInvoicingDateChange}
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <CommonSelect
                        id="xeroInvoiceStatus"
                        floatingLabelText="Publish invoices to Xero as"
                        selectedItemId={this.state.data.xeroInvoiceStatus}
                        value={this.state.data.xeroInvoiceStatus}
                        onChange={this.handleXeroInvoiceStatusChange}
                        items={XERO_INVOICE_STATUSES}
                      />
                    </div>
                }
                {isSystem &&
                  <div className="col-sm-12 form-wrap-70">
                    <CommonMultiSelect
                      id="contractAllocations"
                      label="Contract Allocations"
                      selectedItems={this.state.data.contractAllocations}
                      onChange={this.handleContractAllocationChange}
                      items={CONTRACT_ALLOCATION_TYPES}
                      displayField='name'
                      variant='standard'
                      style={{marginTop: "-25px"}}
                    />
                  </div>
                }
                {
                  isSystem &&
                  <div className="col-sm-12 form-wrap-70" style={{marginTop: "20px"}}>
                    <CommonSelect
                      id="purchaseContractCreationRestriction"
                      floatingLabelText="Purchase Contract Creation Restriction"
                      selectedItemId={this.state.data.purchaseContractCreationRestriction}
                      value={this.state.data.purchaseContractCreationRestriction}
                      onChange={this.handleContractCreationRestrictionChange}
                      items={CONTRACT_CREATION_RESTRICTIONS}
                    />
                  </div>
                }
                {
                  isSystem &&
                  <div className="col-sm-12 form-wrap-70">
                    <CommonSelect
                      id="saleContractCreationRestriction"
                      floatingLabelText="Sale Contract Creation Restriction"
                      selectedItemId={this.state.data.saleContractCreationRestriction}
                      value={this.state.data.saleContractCreationRestriction}
                      onChange={this.handleContractCreationRestrictionChange}
                      items={CONTRACT_CREATION_RESTRICTIONS}
                    />
                  </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <CommonSelect
                        id="rowsPerPage"
                        floatingLabelText="Rows per page"
                        selectedItemId={this.state.data.rowsPerPage}
                        value={this.state.data.rowsPerPage}
                        onChange={this.rowsPerPageChange}
                        items={[ {id: 30, name: 30}, {id: 50, name: 50}, {id: 100, name: 100}]}
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <CommonSelect
                        id="warehouseInvoiceFrequency"
                        floatingLabelText="Warehouse Invoice Frequency"
                        selectedItemId={this.state.data.warehouseInvoiceFrequency}
                        value={this.state.data.warehouseInvoiceFrequency}
                        onChange={this.warehouseInvoiceFrequencyChange}
                        items={[{id: 'weekly', name: 'Weekly'}, {id: 'monthly', name: 'Monthly'}]}
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <CommonSelect
                        id="warehouseInvoiceStartOfWeek"
                        floatingLabelText="Warehouse Invoice Start Of Week"
                        selectedItemId={this.state.data.warehouseInvoiceStartOfWeek}
                        value={this.state.data.warehouseInvoiceStartOfWeek}
                        selectConfig={{text: 'displayName', value: 'id'}}
                        onChange={this.warehouseInvoiceStartOfWeekChange}
                        items={this.state.weekdays}
                        disabled={!isEditable}
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='transactionParticipation'
                            color="primary"
                            checked={this.state.data.transactionParticipation}
                            onChange={this.handleCheckboxClick}
                            disabled={this.shouldDisableParticipation() || isEqual(this.state.data.planType, LOGISTICS_LITE_PLAN) }
                          />
                        }
                        label="Subscriber"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='mobileParticipation'
                            color="primary"
                            checked={this.state.data.mobileParticipation}
                            onChange={this.handleCheckboxClick}
                            disabled={this.shouldDisableParticipation()}
                          />
                        }
                        label="Mobile Subscriber"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='canRepresent'
                            color="primary"
                            checked={this.state.data.canRepresent}
                            onChange={this.handleCheckboxClick}
                            disabled={this.shouldDisableParticipation() || isEqual(this.state.data.planType, LOGISTICS_LITE_PLAN)}
                          />
                        }
                        label="Represent Other Companies"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={this.state.data.restrictSlotCancellation}
                            onChange={this.handleRestrictSlotCancellationChange}
                            disabled={this.shouldDisableParticipation()}
                          />
                        }
                        label="Restrictions on Bookie while canceling slots"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='isHalalDeclarationMandatory'
                            color="primary"
                            checked={this.state.data.isHalalDeclarationMandatory}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Make Halal Declaration Mandatory in Truck Dec"
                      />
                    </div>
                }
                {
                  this.state.data.restrictSlotCancellation && isSystem &&
                  <div 
                    className="col-sm-12 form-wrap-70"  
                    style={{marginTop: "-10px", marginBottom: "10px"}}
                  >
                    <CommonSelect
                      id="hoursBeforeCancellationStops"
                      floatingLabelText="Hours Before Cancellation Stops"
                      selectedItemId={this.state.data.hoursBeforeCancellationStops}
                      errorText={this.state.errors.hoursBeforeCancellationStops}
                      value={this.state.data.hoursBeforeCancellationStops}
                      onChange={this.handleHoursBeforeCancellationStopsChange}
                      items={range(0, 49).map(val => { return {id: val, name: val};})}
                    />
                  </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='showCostsInFm'
                            color="primary"
                            checked={this.state.data.showCostsInFm}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Show Costs in FM"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='overdraftTransferAllowed'
                            color="primary"
                            checked={this.state.data.overdraftTransferAllowed}
                            onChange={this.handleCheckboxClick}
                            disabled={this.shouldDisableParticipation()}
                          />
                        }
                        label="Overdraft Transfer Allowed"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={this.state.data.warehouseInvoiceGroupGrades}
                            onChange={this.handleWarehouseInvoiceGroupGradesChange}
                            disabled={this.shouldDisableParticipation()}
                          />
                        }
                        label="Club Grades in Warehouse Invoices"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='canUploadContractCsv'
                            color="primary"
                            checked={this.state.data.canUploadContractCsv}
                            onChange={this.handleCheckboxClick}
                            disabled={this.shouldDisableParticipation()}
                          />
                        }
                        label="Contract CSV upload allowed"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='enableCustomCsv'
                            color="primary"
                            checked={this.state.data.enableCustomCsv}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Enable Custom CSV"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='multiSlotBooking'
                            color="primary"
                            checked={this.state.data.multiSlotBooking}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Multi Slot Booking"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='enableTransferToApprovedBuyers'
                            color="primary"
                            checked={this.state.data.enableTransferToApprovedBuyers}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Transfer to only Approved Buyers"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='warehouseInvoiceCommoditySeparated'
                            color="primary"
                            checked={this.state.data.warehouseInvoiceCommoditySeparated}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Warehouse Invoice Commodity Separated"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='enableMyStocksOnMobile'
                            color="primary"
                            checked={this.state.data.enableMyStocksOnMobile}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Enable My Stocks on Mobile"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='defaultLevyEpr'
                            color="primary"
                            checked={this.state.data.defaultLevyEpr}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Default Levy EPR"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='automaticallyAddInload'
                            color="primary"
                            checked={this.state.data.automaticallyAddInload}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Automatically Add Inload"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='automaticallyAddOutload'
                            color="primary"
                            checked={this.state.data.automaticallyAddOutload}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Automatically Add Outload"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='showCashPricesToAll'
                            color="primary"
                            checked={this.state.data.showCashPricesToAll}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Show Cash Prices To All"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='xeroEnabled'
                            color="primary"
                            checked={this.state.data.xeroEnabled}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Xero Invoices"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='isVarietyMandatory'
                            color="primary"
                            checked={this.state.data.isVarietyMandatory}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Mandate Variety For"
                      />
                    </div>
                }
                 {
                  isSystem && this.state.data.isVarietyMandatory &&
                  <div className="col-sm-12 form-wrap-70">
                    <CommonMultiSelect
                      id="loadTypeForVarietyMandatory"
                      items={VARIETY_MANDATORY_LOAD_OPTIONS}
                      selectedItems={this.state.data.loadTypeForVarietyMandatory}
                      displayField='name'
                      onChange={this.onMultiSelectChange}
                      label='Load Type'
                      variant='standard'
                      error={this.state.errors.loadTypeForVarietyMandatory}
                      helperText={this.state.errors.loadTypeForVarietyMandatory}
                      style={{marginTop: "-10px"}}
                    />
                  </div>
                }
                {
                  isSystem && this.state.data.isVarietyMandatory &&
                  <div className="col-sm-12 form-wrap-70" style={{marginBottom: "15px"}}>
                    <CommonMultiSelect
                      id="userTypeForVarietyMandatory"
                      items={VARIETY_MANDATORY_USER_OPTIONS}
                      selectedItems={this.state.data.userTypeForVarietyMandatory}
                      displayField='name'
                      onChange={this.onMultiSelectChange}
                      label='User Type'
                      variant='standard'
                      error={this.state.errors.userTypeForVarietyMandatory}
                      helperText={this.state.errors.userTypeForVarietyMandatory}
                    />
                  </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='haveField'
                            color="primary"
                            checked={this.state.data.haveField}
                            onChange={this.handleCheckboxClick}
                            disabled={this.state.data.typeId === GROWER}
                          />
                        }
                        label="Has Fields"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='isIndependentSiteBuyer'
                            color="primary"
                            checked={this.state.data.isIndependentSiteBuyer}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Independent Site Buyer"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='isSellingToIndependentSiteBuyer'
                            color="primary"
                            checked={this.state.data.isSellingToIndependentSiteBuyer}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Seller for Independent Site Buyers"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='onlyCreatorCanAmendContract'
                            color="primary"
                            checked={this.state.data.onlyCreatorCanAmendContract}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Only Creator can Amend Contract"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='onlyCreatorCanAmendOrder'
                            color="primary"
                            checked={this.state.data.onlyCreatorCanAmendOrder}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Only Creator can Amend Order (FO/GO)"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='mobileOrdersWithinDeliveryRange'
                            color="primary"
                            checked={this.state.data.mobileOrdersWithinDeliveryRange}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Mobile orders within Delivery range"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='additionalMassLimitCodes'
                            color="primary"
                            checked={this.state.data.additionalMassLimitCodes}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Additional Mass Limit Codes"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='acquisitionFileUpload'
                            color="primary"
                            checked={this.state.data.acquisitionFileUpload}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Acquisition File Upload"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='contractNumberMandatoryInTransfers'
                            color="primary"
                            checked={this.state.data.contractNumberMandatoryInTransfers}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Contract Number Mandatory in Transfers"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='isVarietyMandatoryInCommodityDec'
                            color="primary"
                            checked={this.state.data.isVarietyMandatoryInCommodityDec}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Variety Mandatory in Commodity dec."
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='foreignCurrencyAllowed'
                            color="primary"
                            checked={this.state.data.foreignCurrencyAllowed}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Foreign Currency Allowed"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='fillDocket'
                            color="primary"
                            checked={this.state.data.fillDocket}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Fill Docket Entries"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='shrinkageOnInloadForAll'
                            color="primary"
                            checked={this.state.data.shrinkageOnInloadForAll}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Shrinkage on Inload for all"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='assignTruckToDrivers'
                            color="primary"
                            checked={this.state.data.assignTruckToDrivers}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Assign Truck To Drivers"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='includeLevyAdjustments'
                            color="primary"
                            checked={this.state.data.includeLevyAdjustments}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Include Levy Adjustments"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='autoMarkCompletedFromDelivered'
                            color="primary"
                            checked={this.state.data.autoMarkCompletedFromDelivered}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Auto Mark Movement Complete"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='automaticContainerMovement'
                            color="primary"
                            checked={this.state.data.automaticContainerMovement}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Automatic Container Movement"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='pickupDetailsWarning'
                            color="primary"
                            checked={this.state.data.pickupDetailsWarning}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Pickup Details Warning"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='deliveryDetailsWarning'
                            color="primary"
                            checked={this.state.data.deliveryDetailsWarning}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Delivery Details Warning"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='showAdditionalSpecs'
                            color="primary"
                            checked={this.state.data.showAdditionalSpecs}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Show Additional Specs"
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='autoSubscribeNgr'
                            color="primary"
                            checked={this.state.data.autoSubscribeNgr}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        label="Auto Subscribe NGRs from NGR LookUp Account"
                      />
                    </div>
                }
                 {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <CommonDatePicker
                        type='month'
                        id="bulkInvoicingFrom"
                        floatingLabelText="Bulk Invoicing From"
                        errorText={this.state.errors.bulkInvoicingFrom}
                        value={this.state.data.bulkInvoicingFrom ? this.state.data.bulkInvoicingFrom.replace('-01', '') : this.state.data.bulkInvoicingFrom}
                        onChange={this.handleInvoicingDateChange}
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <CommonDatePicker
                        type='month'
                        id="contractInvoicingFrom"
                        floatingLabelText="Contract Invoicing From"
                        errorText={this.state.errors.contractInvoicingFrom}
                        value={this.state.data.contractInvoicingFrom ? this.state.data.contractInvoicingFrom.replace('-01', '') : this.state.data.contractInvoicingFrom}
                        onChange={this.handleInvoicingDateChange}
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div className="col-sm-12 form-wrap-70">
                      <CommonDatePicker
                        type='month'
                        id="freightInvoicingFrom"
                        floatingLabelText="Freight Invoicing From"
                        errorText={this.state.errors.freightInvoicingFrom}
                        value={this.state.data.freightInvoicingFrom ? this.state.data.freightInvoicingFrom.replace('-01', '') : this.state.data.freightInvoicingFrom}
                        onChange={this.handleInvoicingDateChange}
                      />
                    </div>
                }
                {
                  isSystem &&
                    <div>
                      <div className="col-sm-12" style={{fontWeight: "500"}}> Extras </div>
                      <div className="col-sm-12 form-wrap-70">
                        {map([0, 1, 2, 3, 4], order => {
                          return (
                            <CommonTextField
                              key={order}
                              id={`alias${order}`}
                              label={`Alias ${order+1}`}
                              placeholder={`Alias ${order}`}
                              onChange={this.onAliasChange}
                              value={get(this.state, `alias${order}`)}
                            />
                          );
                        })}
                      </div>
                      <div className="col-sm-12 form-wrap-70">
                        <CommonTextField
                          id="extras.growerNumber"
                          label='Grower Number'
                          placeholder='Grower Number'
                          onChange={this.onChange.bind(this)}
                          value={get(this.state, 'data.extras.grower_number')}
                        />
                      </div>
                    </div>
                }
                {Boolean(isEditable || isCurrentUserBroker()) &&
                <div className="col-sm-12 cardForm-action top15 padding-reset">
                  <CommonButton
                    label='Cancel'
                    color='grey'
                    onClick={this.onCloseSideDrawer}
                    variant="outlined"
                  />
                  <CommonButton
                    label='Submit'
                    color='primary'
                    onClick={this.submit}
                    variant="contained"
                  />
                </div>
                }
              </div>
            </div>
        </SideDrawer>
        }
        <div className="card">
          <div className="card-header">
            <h3 className="header-title">
              Company Details
              {
                isSystem &&
                  <React.Fragment>
                    {
                      get(selectedCompany, 'transactionParticipation') &&
                        <ChipLabel label='Transaction Participator' style={{marginLeft: '5px'}} color='default' />
                    }
                    {
                      get(selectedCompany, 'mobileParticipation') &&
                        <ChipLabel label='Mobile Participator' style={{marginLeft: '5px'}} color='default' />
                    }
                    {
                      get(selectedCompany, 'canRepresent') &&
                        <ChipLabel label='Can Represent' style={{marginLeft: '5px'}} color='default' />
                    }
                    {
                      get(selectedCompany, 'mobileFlow') &&
                        <ChipLabel label={`Mobile ${startCase(get(selectedCompany, 'mobileFlow'))} Flow`} style={{marginLeft: '5px'}} color='default' />
                    }
                    {
                      get(selectedCompany, 'warehouseChargedAt') &&
                        <ChipLabel label='Charged At Outload' style={{marginLeft: '5px'}} color='default' />
                    }
                    {
                      get(selectedCompany, 'restrictSlotCancellation') &&
                        <ChipLabel label='Restrictions on Bookie' style={{marginLeft: '5px'}} color='default' />
                    }
                    {
                      get(this.props, 'selectedCompany.platformfeatures.planType') &&
                        <ChipLabel label={get(this.props, 'selectedCompany.platformfeatures.planType') === LOGISTICS_LITE_PLAN ? "Lite Plan" : "Full Plan"} style={{marginLeft: '5px'}} color='default' />
                    }
                    {
                      this.props.selectedCompany?.id && !get(this.props, 'selectedCompany.fillDocket') &&
                        <ChipLabel label="Ignore Docket Entries" style={{marginLeft: '5px'}} color='error' />
                    }
                  </React.Fragment>
              }
              <React.Fragment>
                {
                  get(selectedCompany, 'isValidXeroConnection') &&
                    <ChipLabel label='Xero Connected' style={{marginLeft: '5px'}} color='primary' />
                }
                {
                  !get(selectedCompany, 'isValidXeroConnection') && get(selectedCompany, 'isXeroAccountLinked') &&
                    <ChipLabel label='Xero Connection Expired' style={{marginLeft: '5px'}} color='error' />
                }
              </React.Fragment>
            </h3>
            { Boolean(isCompanyEditable(selectedCompany) || isCurrentUserBroker()) &&
              <div className="header-content">
                <div className="header-item">
                  <CommonButton
                    label='Edit'
                    color='primary'
                    onClick={this.onOpenSideDrawer}
                    variant="contained"
                  />
                </div>
              </div>
            }
            {selectedCompany &&
             <div className="card-content">
               <div className="col-xs-6">
                 <div className="label-content">
                   {country?.config?.abn?.label}
                   <span>{
                     selectedCompany.abn || EMPTY_VALUE
                   } {
                     selectedCompany.isRegistered && <RightTick width={20} height={15} style={{verticalAlign: 'middle', marginBottom: '3px'}} />
                   }
                   </span>
                 </div>
                 {
                   selectedCompany.abn && selectedCompany.abn !== '-' &&
                     <div className="label-content">
                       {country?.config?.abn?.label} Status
                       <span>
                         {selectedCompany.abnStatus ? selectedCompany.abnStatus.toUpperCase() : "-"}
                         {selectedCompany.abnStatusLastUpdatedAt && ` (Last Synced: ${moment(selectedCompany.abnStatusLastUpdatedAt).fromNow()})`}
                       </span>
                     </div>
                 }
                 <div className="label-content">
                   Entity Name
                   <span>{selectedCompany.entityName || EMPTY_VALUE}</span>
                 </div>
                 <div className="label-content">
                   Business Name
                   <span>{selectedCompany.businessName || EMPTY_VALUE}</span>
                 </div>
                 <div className="label-content">
                   Primary Business
                   <span>{(selectedCompany.type && selectedCompany.type.displayName) || EMPTY_VALUE}</span>
                 </div>
                 <div className="label-content">
                   Address
                   <span>{(selectedCompany.address && selectedCompany.address.address) || (selectedCompany.address && selectedCompany.address.name) || EMPTY_VALUE}</span>
                 </div>
                 <div className="label-content">
                   Payment Term
                   <span>{paymentTermName}</span>
                 </div>
                 {isGSTVisible && <div className="label-content">
                  {getCountryLabel('gst')}
                   <span>{selectedCompany.gstNumber || EMPTY_VALUE}</span>
                 </div>}
                 {
                   selectedCompany.brokeragesForUser && selectedCompany.brokeragesForUser[0] ?
                     <div className="label-content">
                       Default Brokerage
                       <span>{getBrokerageDisplayValue(selectedCompany.brokeragesForUser[0])}</span>
                     </div>
                   :
                   null
                 }
               </div>
               <div className="col-xs-6">
                 <div className="label-content">
                   Phone/Mobile
                   <span>{selectedCompany.mobile ? toPhoneFormat(selectedCompany.mobile) : EMPTY_VALUE}</span>
                 </div>
                 <div className="label-content">
                   Website
                   <span>{selectedCompany.website || EMPTY_VALUE}</span>
                 </div>
                 <div className="label-content">
                   Start of week
                   <span>{getWeek(selectedCompany.startOfWeek, this.state.weekdays)}</span>
                 </div>
                 <div className="label-content">
                   End of week
                   <span>{getWeek(selectedCompany.endOfWeek, this.state.weekdays)}</span>
                 </div>
                 {isSystem && selectedCompany.hoursBeforeCancellationStops > 0 &&
                  <div className="label-content">
                    Hours Before Cancellation Stops
                    <span>{selectedCompany.hoursBeforeCancellationStops}</span>
                  </div>}
                 {
                   selectedCompany.logoUrl ?
                     <div className="label-content">
                       Logo
                       <span><img style={{width: '75px'}} src={selectedCompany.logoUrl}/></span>
                     </div>
                   :
                   null
                 }
               </div>
             </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const currentUserCompanyId = state.main.user.user.companyId;
  const abn = state.main.abn || {businessName: '', entityName: ''};
  const company = state.companies.company || {};
  const selectedCompany = state.companies.companies.selectedCompany;
  const updatedCompanyDetails = state.companies.companies.updatedCompanyDetails;
  var abnError = '';
  var serverErrors = state.companies.companies.errors || [];
  if(state.main.abn && state.main.abn.errors) {
    abnError = state.main.abn.errors.abn[0];
    abn.businessName = abn.entityName = '';
  }
  abn.businessName = (abn.businessName !== null) ? abn.businessName : '';
  abn.entityName = (abn.entityName !== null) ? abn.entityName : '';
  return {
    abn,
    abnError,
    company,
    serverErrors,
    selectedCompany,
    currentUserCompanyId,
    headerText: state.main.headerText,
    breadcrumbs: state.main.breadcrumbs,
    token: state.main.user.token,
    user: state.main.user.user,
    updatedCompanyDetails,
    paymentTerms: state.companies.contracts.paymentTerms,
    isFromCompanyDirectory: state.companies.companies.isFromCompanyDirectory,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchNameFromABN: (value, isEditCompany) => dispatch(fetchNameFromABN(value, isEditCompany)),
  addCompany:(data) => dispatch(addCompany(data)),
  getCompany:(companyId) => dispatch(getCompanyDetails(companyId)),
  updateCompany: (companyId, data, callback) => dispatch(updateCompany(companyId, data, callback)),
  setHeaderText: (text) => dispatch(setHeaderText(text)),
  setBreadcrumbs: (breadcrumbs) => dispatch(setBreadcrumbs(breadcrumbs)),
  forceStopLoader: () => dispatch(forceStopLoader()),
  getPaymentTerms: () => dispatch(getPaymentTerms()),
  setABNResponse: (data) => dispatch(receiveABNResponse(data)),
  isLoading: () => dispatch(isLoading())
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyForm);
