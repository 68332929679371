import React, { Component } from 'react';
import { connect } from 'react-redux';

import '@babel/polyfill';
import moment from 'moment';
import alertifyjs from 'alertifyjs';
import IconButton from '@mui/material/IconButton';
import ArrowForward from '@mui/icons-material/ArrowForward';
import TextField from '@mui/material/TextField/TextField';
import InputAdornment from '@mui/material/InputAdornment/InputAdornment';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import DialogActions from '@mui/material/DialogActions/DialogActions';
import Dialog from '@mui/material/Dialog/Dialog';
import withStyles from '@mui/styles/withStyles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CommonButton from '../common/CommonButton';
import {
  REQUEST_ORDER_TYPE,
  PRIMARY_COLOR_GREEN,
  MT_UNIT,
  REQUEST_ORDER_TYPE_IDS,
  PICKUP_REQUEST_ORDER_TYPE_ID,
  GROWER,
  SYSTEM_COMPANY_IDS,
  TRANSPORT_MODE_TRUCK,
  TOLERANCE_NIL,
  TOLERANCE_5_OR_20_PERCENT
} from '../../common/constants';
import { required, selected } from '../../common/validators';
import { create, getOrderFixtures } from '../../actions/companies/orders';
import { getCompanyCompaniesMinimal } from '../../actions/api/companies';
import { isLoading, loaded, setBreadcrumbs, setHeaderText } from '../../actions/main/index';
import {
  clickAddGeneralConditionButton,
  clickAddSpecialConditionButton,
  getGeneralConditions,
  getSpecialConditions,
  receiveGeneralConditions,
  receiveSpecialConditions,
} from '../../actions/companies/conditions';
import {
  isEmpty, isNumber, isEqual, map, filter, every, replace, find, forEach, mapValues,
  includes, set, get, has, uniqBy, omit, isArray, pick,
  isObject, cloneDeep, keys, values, compact, uniq, without, merge,
  isNull,
  partialRight
} from 'lodash';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import CommodityAutoComplete from '../common/autocomplete/CommodityAutoComplete';
import VarietyAutoComplete from '../common/autocomplete/VarietyAutoComplete';
import GradeAutoComplete from '../common/autocomplete/GradeAutoComplete';
import CommonSelect from '../common/select/CommonSelect';
import CommonDatePicker from '../common/CommonDatePicker';
import SeasonSelect from '../common/select/SeasonSelect';
import TransportModeSelect from '../common/select/TransportModeSelect';
import SiteAsyncAutocomplete from '../common/autocomplete/SiteAsyncAutocomplete';
import CommonTextField from '../common/CommonTextField';
import {
  generateIdentifier,
  getAutoSelectFocusField,
  getLabelForEmployeeForm,
  getDateTimeFromUTC,
  isStaff,
  getCountryFormats,
  getCountryLabel,
  getCountrySystemCompanyId,
  isCurrentUserBelongsToCompany,
  generateUpdatedIdentifier,
  currentUserCompany,
  currentUser
} from '../../common/utils';
import { getCommoditiesWithVarietiesAndGrades } from '../../actions/api/commodities';
import APIService from '../../services/APIService';
import RequestOrderPreview from './order-preview/RequestOrderPreview';
import SideDrawer from '../common/SideDrawer';
import CreateEmployee from '../../containers/CreateEmployee';
import CreateGeneralCondition from '../../containers/CreateGeneralCondition';
import CreateSpecialCondition from '../../containers/CreateSpecialCondition';
import { clickAddEmployeeButton } from '../../actions/company-settings/employees';
import CreateCompanyNgr from '../../containers/CreateCompanyNgr';
import { clickAddCompanyNgrButton } from '../../actions/companies/company-ngrs';
import { clickAddNgrButton } from '../../actions/companies/ngrs';
import {AddCircleIconWithTooltip} from "../common/icons/AddCircleIconWithTooltip";
import {canCreateEmployee} from "../../actions/api/employees";
import {clickAddFarmEmployeeButton} from "../../actions/companies/farm-employees";
import {raiseOrderAmendRequest} from "../../actions/companies/freights";
import {forceStopLoader} from "../../actions/main";
import {canCreateNgr} from "../../actions/api/ngrs";
import Notes from "../common/Notes";
import CustomEmailDialog from '../common/CustomEmailDialog';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CreateCompanySite from '../../containers/CreateCompanySite';
import QuickOrder from '../common/TemplateSearch';
import { Tooltip } from '@mui/material';
import first from 'lodash/first';
import toString from 'lodash/toString';
import pickBy from 'lodash/pickBy';
import NumberField from '../common/NumberField';

const DEFAULT_SIDE_DRAWERS_STATE = {
  customerSideDrawerIsOpen: false,
  ngrSideDrawerIsOpen: false,
  freightProviderSideDrawerIsOpen: false,
  generalConditionSideDrawerIsOpen: false,
  specialConditionSideDrawerIsOpen: false,
  consignorSideDrawer: false,
  consigneeSideDrawer: false
};
const autocompleteFilters = createFilterOptions();
const styles = () => ({
  inputRoot: {
    color: 'black !important',
  },
  consignorPopper: {
    width: '400px',
    marginTop: '1325px',
    marginLeft: localStorage.toggleNav === "true" ? '115px' : '270px',
  },
  consigneePopper: {
    width: '400px',
    marginTop: '1325px',
    marginLeft: localStorage.toggleNav === "true" ? '805px' : '960px',
  },
});

class RequestOrderForm extends Component {
  constructor(props) {
    super(props);
    this.isEditForm = Boolean(this.props.match.params.order_id);
    const hashQueryParams = new URLSearchParams(window.location.hash.split('?')[1]);
    this.isDuplicatingOrder = Boolean(hashQueryParams.get('copyFrom'))
    this.copyFrom = hashQueryParams.get('copyFrom')
    this.removeLoader = false;
    this.pickupOrderIdentifier = generateIdentifier('pickup_order');
    this.deliveryOrderIdentifier = generateIdentifier('delivery_order');
    const queryParams = new URLSearchParams(this.props.location.search);
    this.selectedOrderId = queryParams.get('orderId');
    this.applyingBaseEntity = false;
    this.applyingTemplate = false;
    this.selectedOrder = null;
    this.fetchingOrder = false;
    this.disableForm = true;
    this.tonnageLabel = getCountryLabel('tonnage')
    this.state = {
      countryFormats: getCountryFormats(),
      pickupSettings: {},
      deliverySettings: {},
      partyValidate: false,
      fetchingConditions: true,
      emailPopupParties: [],
      isDefaultGeneralConditionApplied: false,
      isDefaultSpecialConditionApplied: false,
      base64Logo: null,
      setFieldErrors: false,
      queryParamExists: false,
      selectedConsignee: undefined,
      selectedConsignor: undefined,
      selectedCustomer: undefined,
      fetchConsignorSites: false,
      fetchConsigneeSites: false,
      consignors: [],
      consignees: [],
      consignorSites: [],
      consigneeSites: [],
      customerContacts: [],
      customerNgrs: [],
      generalConditionsAll: [],
      specialConditionsAll: [],
      selectedGeneralConditionText: undefined,
      selectedSpecialConditionText: undefined,
      generalConditionsSelector: undefined,
      specialConditionsSelector: undefined,
      selectedConsignorSite: undefined,
      selectedConsigneeSite: undefined,
      consignorAddress: undefined,
      consigneeAddress: undefined,
      ngrSideDrawerIsOpen: false,
      ngrEntity: undefined,
      templateDialog: false,
      customerTonnageAvailable: undefined,
      openWarningDialog: false,
      throughputInloadOrder: false,
      throughputInloadCheckbox: false,
      throughputOutloadOrder: false,
      throughputOutloadCheckbox: false,
      templateName: {
        value: null,
        validators: [required()],
        errors: []
      },
      errors: {
        party: false
      },
      tolerances: [],
      fields: {
        externalReferenceNumber: {
          value: undefined,
          errors: [],
          validators: []
        },
        note: {
          description: '',
          attachments: [],
          companyId: this.props.currentUser.companyId,
          errors : []
        },
        status: {
          value: 'planned',
        },
        generalConditions: {
          value: '',
          validators: [],
          errors: [],
        },
        specialConditions: {
          value: '',
          validators: [],
          errors: [],
        },
        identifier: {
          value: this.pickupOrderIdentifier,
          validators: [required()],
          errors: [],
        },
        commodityId: {
          value: undefined,
          validators: [required()],
          errors: [],
        },
        varietyId: {
          value: undefined,
          validators: [],
          errors: [],
        },
        typeId: {
          value: undefined,
          validators: [required()],
          errors: [],
        },
        season: {
          value: undefined,
          validators: [required()],
          errors: [],
        },
        transportMode: {
          value: TRANSPORT_MODE_TRUCK,
          validators: [],
          errors: [],
        },
        quantity: {
          value: undefined,
          validators: [],
          errors: [],
        },
        plannedTonnage: {
          value: undefined,
          validators: [required()],
          errors: [],
        },
        plannedGradeId: {
          value: undefined,
          validators: [required(), selected()],
          errors: [],
        },
        containerNumber: {
          value: undefined,
          validators: [],
          errors: [],
        },
        freightPickup: {
          date: {
            value: undefined,
            validators: [required()],
            errors: [],
          },
          timeStart: {
            value: '12:00:00',
          },
          orderNumber: {
            value: undefined,
            validators: [],
            errors: [],
          },
          orderId: {
            value: undefined,
            validators: [],
            errors: [],
          },
          consignor: {
            handlerId: {
              value: undefined,
              validators: [],
              errors: [],
            },
            site: {
              locationType: {
                value: undefined,
                validators: [],
                errors: [],
              },
              locationId: {
                value: undefined,
                validators: [selected()],
                errors: [],
              },
            },
          },
          selectedContainer: undefined,
        },
        freightDelivery: {
          date: {
            value: undefined,
            validators: [required()],
            errors: [],
          },
          timeStart: {
            value: '12:00:00',
          },
          orderNumber: {
            value: undefined,
            validators: [],
            errors: [],
          },
          orderId: {
            value: undefined,
            validators: [],
            errors: [],
          },
          consignee: {
            handlerId: {
              value: undefined,
              validators: [],
              errors: [],
            },
            site: {
              locationType: {
                value: undefined,
                validators: [],
                errors: [],
              },
              locationId: {
                value: undefined,
                validators: [selected()],
                errors: [],
              },
            },
          },
        },
        customer: {
          company: undefined,
          companyId: {
            value: undefined,
            validators: [required(), selected()],
            errors: []
          },
          contactId: {
            value: undefined,
            validators: [required(), selected()],
            errors: []
          },
          ngrId: {
            value: undefined,
            validators: [required(), selected()],
            errors: []
          },
          address: undefined,
          representedById: { value: undefined }
        },
        toleranceId: {
          value: undefined,
          validators: [],
          errors: []
        }
      },
      isConsignorEditable: true,
      isConsigneeEditable: true,
      canClearSiteOptions: false,

      // Edit FO Fields
      isFetchingSelectedOrder: false,
      isPopulated: false,
      customerNgr: undefined,
      customerItems: [],
      typeItems: [],
      disableSite:  false,
      disableStorage:  false
    };

    // Edit FO Vars
    this.editOrderId = get(this.props, 'match.params.order_id');
    this.editOrder = null;
    this.isEditForm = !!get(this.props, 'match.params.order_id');
    this.fieldsDup = cloneDeep(this.state.fields);

    if(!this.isEditForm){
      Promise.all([
        this.props.dispatch(getGeneralConditions('freight')),
        this.props.dispatch(getSpecialConditions('freight')),
      ]);
    }

    if(this.isEditForm)
      this.props.dispatch(isLoading('nonExistentComponent'));
    this.props.dispatch(getOrderFixtures());

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleSelectFieldChange = this.handleSelectFieldChange.bind(this);
    this.handleCommodityChange = this.handleCommodityChange.bind(this);
    this.handleVarietyChange = this.handleVarietyChange.bind(this);
    this.handleGradeChange = this.handleGradeChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleConsignorChange = this.handleConsignorChange.bind(this);
    this.handleConsigneeChange = this.handleConsigneeChange.bind(this);
    this.handleConditionSelector = this.handleConditionSelector.bind(this);
    this.appendGeneralConditions = this.appendGeneralConditions.bind(this);
    this.appendSpecialConditions = this.appendSpecialConditions.bind(this);
    this.handleOrderTypeChange = this.handleOrderTypeChange.bind(this);
    this.handleTonnageFieldChange = this.handleTonnageFieldChange.bind(this);
    this.handleQuantityFieldChange = this.handleQuantityFieldChange.bind(this);
    this.handleCustomerContactChange = this.handleCustomerContactChange.bind(this);
    this.handleIdentifierChange = this.handleIdentifierChange.bind(this);
    this.closeCustomerSideDrawer = this.closeCustomerSideDrawer.bind(this);
    this.openCustomerSideDrawer = this.openCustomerSideDrawer.bind(this);
    this.getCustomerContacts = this.getCustomerContacts.bind(this);
    this.openGeneralConditionSideDrawer = this.openGeneralConditionSideDrawer.bind(this);
    this.closeSpecialConditionSideDrawer = this.closeSpecialConditionSideDrawer.bind(this);
    this.closeGeneralConditionSideDrawer = this.closeGeneralConditionSideDrawer.bind(this);
    this.openSpecialConditionSideDrawer = this.openSpecialConditionSideDrawer.bind(this);
    this.focusOnFirstErrorField = this.focusOnFirstErrorField.bind(this);
    this.openNgrSideDrawer = this.openNgrSideDrawer.bind(this);
    this.closeNgrSideDrawer = this.closeNgrSideDrawer.bind(this);
    this.addCustomerContactButton = this.addCustomerContactButton.bind(this);
    this.addGeneralConditionButton = this.addGeneralConditionButton.bind(this);
    this.addSpecialConditionButton = this.addSpecialConditionButton.bind(this);
    this.setDefaultGeneralCondition = this.setDefaultGeneralCondition.bind(this);
    this.setDefaultSpecialCondition = this.setDefaultSpecialCondition.bind(this);
    this.customerSideDrawer = this.customerSideDrawer.bind(this);
    this.handleCancelButtonClick = this.handleCancelButtonClick.bind(this);
    this.handleTemplateNameChange = this.handleTemplateNameChange.bind(this);
    this.handleTemplateNameBlur = this.handleTemplateNameBlur.bind(this);
    this.handleTemplateDialogClose = this.handleTemplateDialogClose.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
    this.getTemplatesService = this.getTemplatesService.bind(this);
    this.handleQuickOrderChange = this.handleQuickOrderChange.bind(this);
    this.populateTemplateFields = this.populateTemplateFields.bind(this);
    this.saveTemplate = this.saveTemplate.bind(this);
    this.handleSiteChange = this.handleSiteChange.bind(this);
    this.handleThroughputOrderChange = this.handleThroughputOrderChange.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.getMergedNote = this.getMergedNote.bind(this);
    this.unsetCanClearSiteOption = this.unsetCanClearSiteOption.bind(this);
    this.updateDisableSiteAndStorageSettings = this.updateDisableSiteAndStorageSettings.bind(this);
    this.fieldsOrder = [
      "typeId", "identifier", "externalReferenceNumber",
      "customer.companyId",
      "customer.contactId", "customer.ngrId", "commodityId",
      "varietyId", "plannedGradeId", "season", "quantity", "plannedTonnage", "freightPickup.date",
      "freightPickup.consignor.handlerId",
      "freightDelivery.date", "freightDelivery.consignee.handlerId",
      "generalConditionsSelector",
      "generalConditionViewer", "generalConditions", "specialConditionsSelector",
      "specialConditionViewer", "specialConditions", "note"
    ];

    this.formRef = React.createRef();

    this.fieldRef = {};
    this.fieldsOrder.forEach(e => (this.fieldRef[e] = React.createRef()));
    this.disableForm = this.isEditForm ? false : true;

  }

  componentDidMount() {
    this.props.dispatch(getCompanyCompaniesMinimal(this.props.userCompanyId, { include_parent_company: true }));
    this.getBase64Logo();
    this.props.dispatch(setHeaderText("Request Orders"));
    let breadcrumbs = [{ text: 'Request Orders', route: '/orders/requests' }];

    if (this.isEditForm) {
      breadcrumbs = breadcrumbs.concat([
        { text: get(this.editOrder, 'displayName'), route: `/orders/requests/${this.editOrderId}/order` },
        { text: 'Edit' },
      ]);
    } else {
      breadcrumbs = breadcrumbs.concat([{ text: 'New' }]);
    }
    this.props.dispatch(setBreadcrumbs(breadcrumbs));
    if (!this.isEditForm) {
      if (isEmpty(this.props.commodities)) {
        this.props.dispatch(getCommoditiesWithVarietiesAndGrades());
      }
      setTimeout(() => {
        this.setState({ setFieldErrors: true });
      }, 2000);
    }
  }


  getBase64Logo() {
    const logoUrl = get(this.props, 'currentUser.myCompanyLogoUrl') || get(this.editOrder, 'createdBy.myCompanyLogoUrl');
    if (logoUrl && !this.state.base64Logo) {
      APIService.base64()
                .post({ url: logoUrl })
                .then(data => {
                  this.setState({ base64Logo: data.base64 });
                });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.isEditForm &&
      !this.state.isFetchingSelectedOrder
    ) {
      this.setState({ isFetchingSelectedOrder: true, isPopulated: false }, () =>
        this.setOrder(this.editOrderId)
      );
    }
    else if(this.isDuplicatingOrder && !this.state.isFetchingSelectedOrder){
      this.props.dispatch(isLoading());
      this.setState({ isFetchingSelectedOrder: true, isPopulated: false }, () =>
        this.setOrder(this.copyFrom)
      );
    }
    else {
      if (!isEqual(this.props.serverErrors, prevProps.serverErrors)) {
        const newState = { ...this.state };
        forEach(this.props.serverErrors, (value, key) => {
          if(key.toLowerCase() === 'identifier')
            value[0] = value[0].replace('Freight order', 'Order')
          if(key.toLowerCase() === 'freightorder') {
            alertifyjs.error(first(value));
            return
          }
          newState.fields[key].errors = value;
        });
        this.setState(newState);
      }

      if (!isEmpty(this.props.specialConditionsAll) && !this.state.isDefaultSpecialConditionApplied && !this.isEditForm) {
        const newState = { ...this.state };
        const specialConditions = map(filter(this.props.specialConditionsAll, { default: true }), 'details').join('\n');
        if (!isEmpty(specialConditions) && !includes(newState.fields.specialConditions.value, specialConditions))
          newState.fields.specialConditions.value = specialConditions + '\n' + newState.fields.specialConditions.value;
        newState.isDefaultSpecialConditionApplied = true;
        this.setState(newState);
      }

      if (!isEmpty(this.props.generalConditionsAll) && !this.state.isDefaultGeneralConditionApplied && !this.isEditForm) {
        const newState = { ...this.state };
        const generalConditions = map(filter(this.props.generalConditionsAll, { default: true }), 'details').join('\n');
        if (!isEmpty(generalConditions) && !includes(newState.fields.generalConditions.value, generalConditions))
          newState.fields.generalConditions.value = generalConditions + '\n' + newState.fields.generalConditions.value;
        newState.isDefaultGeneralConditionApplied = true;
        this.setState(newState);
      }
    }
    this.setTolerance();
  };

  setTolerance() {
    if (isEmpty(this.state.tolerances) && !isEmpty(this.props.tolerances)) {
      const newState = {...this.state};
      newState.tolerances = this.props.tolerances;
      if (!this.editOrder)
        newState.fields.toleranceId.value = newState.fields.typeId.value === PICKUP_REQUEST_ORDER_TYPE_ID ? TOLERANCE_NIL : TOLERANCE_5_OR_20_PERCENT;
      this.setState(newState);
    }
  }

  componentWillUnmount() {
    this.props.dispatch(receiveGeneralConditions([]));
    this.props.dispatch(receiveSpecialConditions([]));
  }

  setOrder = async (orderId) => {
    await APIService.freights()
                    .orders(orderId)
                    .get(this.props.userToken)
                    .then(async response => {
                      this.editOrder = response;
                      if(
                        !this.isDuplicatingOrder &&
                        !isEqual(get(this.editOrder, 'status'), 'template') &&
                        !this.applyingTemplate
                      ) {
                        let breadcrumbs = [
                          { text: 'Request Orders', route: '/orders/requests' },
                          { text: get(this.editOrder, 'displayName'), route: `/freights/orders/${this.editOrderId}/order` },
                          { text: 'Edit' },
                        ];
                        this.props.dispatch(setBreadcrumbs(breadcrumbs));
                        let companyId;
                        if(this.editOrder.typeId === 4) {
                          this.fetchPickupSettings(this.editOrder.freightPickup.consignor.handlerId);
                          companyId = get(this.editOrder.freightPickup.consignor, 'handler.companyId');
                        }
                        else if(this.editOrder.typeId === 5) {
                          this.fetchDeliverySettings(this.editOrder.freightDelivery.consignee.handlerId);
                          companyId = get(this.editOrder.freightDelivery.consignee, 'handler.companyId');
                        }
                        if (!includes(SYSTEM_COMPANY_IDS, companyId) && find(this.props.allDirectoryCompanies, {id: companyId})?.typeId == GROWER) {
                          const companyFarms = APIService.companies(companyId).farms().appendToUrl('?is_active=true').get()
                          const contractHandlers = APIService.contracts().handlers().get(null, null, {company_ids: currentUserCompany().id})
                          await Promise.all([
                            companyFarms,
                            contractHandlers
                          ]).then(responses => {
                            const response1 = responses[0]
                            const response2 = responses[1]?.handlers || []
                            const newState = { ...this.state };
                            let handlers1 = map(response1, item => ({ ...item, name: item?.displayName }));
                            let handlers2 = map(response2, item => ({ ...item, name: item?.name }));
                            if (this.editOrder.typeId === 4)
                              newState.consignors = [...handlers1, ...handlers2]
                            else if (this.editOrder.typeId === 5)
                              newState.consignees =  [...handlers1, ...handlers2]
                            this.setState(newState);
                          })
                        } else {
                          const currentUserCompanyId = currentUserCompany().id
                          const contractHandlers = APIService.contracts().handlers().get(null, null, {company_ids: currentUserCompanyId})
                          const companySites =  APIService.companies(companyId).appendToUrl('company_sites/?is_active=true').get()
                          await Promise.all([
                            companySites,
                            contractHandlers
                          ]).then(responses => {
                            const response1 = responses[0]
                            const response2 = responses[1]?.handlers || []
                            const newState = { ...this.state };
                            let handlers1 = map(response1, item => {
                              let companyName = includes(SYSTEM_COMPANY_IDS, companyId) ? 'Unknown Company' : find(this.props.allDirectoryCompanies, {id:companyId})?.name;
                              return { ...item, name: `${companyName} (${item.name})`}
                            })
                            let handlers2 = map(response2, item => ({ ...item, name: item?.name }));
                            if (this.editOrder.typeId === 4)
                              newState.consignors = [...handlers1, ...handlers2]
                            else if (this.editOrder.typeId === 5)
                              newState.consignees = [...handlers1, ...handlers2]
                            this.setState(newState);
                          })
                        }
                        this.populateRequiredFields();
                        this.props.dispatch(forceStopLoader());
                      } else {
                        if(this.editOrder.typeId === 4)
                          this.fetchPickupSettings(get(this.editOrder, 'freightPickup.consignor.handlerId'));
                        else if(this.editOrder.typeId === 5)
                          this.fetchDeliverySettings(get(this.editOrder, 'freightDelivery.consignee.handlerId'));

                        this.populateTemplateFields()
                      }
                      Promise.all([
                        this.props.dispatch(getGeneralConditions('freight')),
                        this.props.dispatch(getSpecialConditions('freight')),
                      ]);
                    });
  };

  isValueChanged = field => {
    return get(this.state.fields, `${field}.value`) != get(this.editOrder, field);
  };

  populateRequiredFields = async () => {
    const customerCompany = get(this.editOrder, 'customer.company');
    const getCustomerContacts = this.getContacts(customerCompany, get(this.editOrder, 'customer.representedById'));
    if(customerCompany)
      this.getCustomerNgrs(customerCompany.id);

    await Promise.all([getCustomerContacts]).then(responses => {
      this.setState(
        {
          customerContacts: responses[0],
        },
        () => {
          const newState = { ...this.state };
          if(get(this.editOrder, 'outloadThroughputFee'))
            set(newState, 'throughputOutloadOrder', true);
          if(get(this.editOrder, 'inloadThroughputFee'))
            set(newState, 'throughputInloadOrder', true);

          set(newState, 'fields.note.description', get(this.editOrder, 'note.description'));
          forEach(['typeItems'], key => set(newState, key, [get(this.editOrder, replace(key, 'Items', ''))]));

          const selectedCustomer = find(this.props.allDirectoryCompanies, { id: get(this.editOrder, 'customer.handlerId') });
          if (selectedCustomer) {
            set(newState, 'customerItems', [selectedCustomer]);
          }

          forEach([
            'typeId', 'customer.companyId',
            'commodityId', 'varietyId', 'plannedGradeId', 'season', 'identifier', 'plannedTonnage',
            'generalConditions', 'specialConditions', 'transportMode', 'toleranceId'
          ], key => set(newState.fields, `${key}.value`, get(this.editOrder, key)));

          let pickupLocalDateTime = getDateTimeFromUTC(this.editOrder.freightPickup.dateTime);
          let deliveryLocalDateTime = getDateTimeFromUTC(this.editOrder.freightDelivery.dateTime);
          set(newState.fields, 'freightPickup.date.value', pickupLocalDateTime.date);
          set(newState.fields, 'freightDelivery.date.value', deliveryLocalDateTime.date);
          set(newState.fields, 'customer.company', get(this.editOrder, 'customer.company'));
          set(newState.fields, 'customer.contactId.value', get(this.editOrder, 'customer.contactId'));
          set(newState.fields, 'customer.ngrId.value', get(this.editOrder, 'customer.ngrId'));
          set(newState, 'customerContact', get(this.editOrder, 'customer.contact'));
          set(newState, 'customerNgr', get(this.editOrder, 'customer.ngr'));

          const orderConsignor = get(this.editOrder, 'freightPickup.consignor.handler');
          const orderConsignee = get(this.editOrder, 'freightDelivery.consignee.handler');

          let handlerCompanyId = null
          if (!isEmpty(orderConsignor)) {
            newState.selectedConsignor = {id: orderConsignor.id, name: orderConsignor.displayName, companyId: orderConsignor.companyId, stocksManagement: orderConsignor.stocksManagement};
            newState.consignors = uniqBy([...newState.consignors, orderConsignor], 'id');
            newState.fields.freightPickup.consignor.handlerId.value = get(orderConsignor, 'id');
            handlerCompanyId = orderConsignor.companyId
          }

          if (!isEmpty(orderConsignee)) {
            newState.selectedConsignee = {id: orderConsignee.id, name: orderConsignee.displayName, companyId: orderConsignee.companyId};
            newState.consignees = uniqBy([...newState.consignees, orderConsignee], 'id');
            newState.fields.freightDelivery.consignee.handlerId.value = get(orderConsignee, 'id');
            handlerCompanyId = orderConsignee.companyId
          }

          const isSiteCompanyCurrentUser = isEqual(handlerCompanyId, get(this.props, 'currentUser.companyId'))
          const disableEssentials = includes(['delivered', 'in_progress', 'completed'], get(this.editOrder, 'status'));
          const disableSite =  disableEssentials|| (this.isEditForm && !(this.UserSiteCustomerOrIsStaff() && this.canAmendSite()))
          const disableStorage =  disableEssentials|| (this.isEditForm && !(this.UserSiteCustomerOrIsStaff() && this.canAmendStorage(isSiteCompanyCurrentUser)))

          newState.disableSite = disableSite
          newState.disableStorage = disableStorage

          this.setState(newState,() => {
            this.setHandlerSites();
          });
        },
      );
    });
  };

  populateTemplateFields = async () => {
    const newState = { ...this.state };
    set(newState, 'fields.note.description', get(this.editOrder, 'note.description'));
    forEach(['typeItems'], key => set(newState, key, [get(this.editOrder, replace(key, 'Items', ''))]));

    const selectedCustomer = find(this.props.allDirectoryCompanies, { id: get(this.editOrder, 'customer.handlerId') });
    if (selectedCustomer) {
      set(newState, 'customerItems', [selectedCustomer]);
    }

    forEach([
      'typeId', 'customer.companyId',
      'commodityId', 'varietyId', 'plannedGradeId', 'season', 'identifier', 'plannedTonnage',
      'generalConditions', 'specialConditions', 'customer.contactId', 'transportMode'
    ], key => {
      set(newState.fields, `${key}.value`, get(this.editOrder, key))
      set(newState.fields, `${key}.errors`, []);
    });

    let pickupLocalDateTime = getDateTimeFromUTC(this.editOrder.freightPickup.dateTime);
    let deliveryLocalDateTime = getDateTimeFromUTC(this.editOrder.freightDelivery.dateTime);
    const orderConsignor = get(this.editOrder, 'freightPickup.consignor.handler');
    const orderConsignee = get(this.editOrder, 'freightDelivery.consignee.handler');

    if (!isEmpty(orderConsignor)) {
      newState.selectedConsignor = {id: orderConsignor.id, name: orderConsignor.displayName, companyId: orderConsignor.companyId};
      newState.consignors = uniqBy([...newState.consignors, orderConsignor], 'id');
      const consignorHandler = get(orderConsignor, 'id');
      newState.fields.freightPickup.consignor.handlerId.value = get(orderConsignor, 'id');
      this.getSites(consignorHandler, 'farm', 'consignor');
    }

    if (!isEmpty(orderConsignee)) {
      newState.selectedConsignee = {id: orderConsignee.id, name: orderConsignee.displayName, companyId: orderConsignee.companyId};
      newState.consignees = uniqBy([...newState.consignees, orderConsignee], 'id');
      const consigneeHandler = get(orderConsignee, 'id');
      newState.fields.freightDelivery.consignee.handlerId.value = consigneeHandler
      this.getSites(consigneeHandler, 'farm', 'consignee');
    }

    if(!this.applyingTemplate){
      const isOrderStatusVoid = (get(this.editOrder, 'orderStatusDisplayName') === 'Void')
      if(this.isDuplicatingOrder && !isOrderStatusVoid)
        set(newState.fields, 'identifier.value', generateUpdatedIdentifier(this.editOrder.identifier[0]))
      else if(isOrderStatusVoid)
        set(newState.fields, 'identifier.value', this.editOrder.identifier)
    }
    else
      set(newState.fields, 'identifier.value', isEqual(this.editOrder.typeId, PICKUP_REQUEST_ORDER_TYPE_ID ) ? this.pickupOrderIdentifier : this.deliveryOrderIdentifier);

    if(get(this.editOrder, 'typeId') === PICKUP_REQUEST_ORDER_TYPE_ID)
      set(newState.fields, 'freightPickup.consignor.handlerId.validators', [required()])
    else
      set(newState.fields, 'freightDelivery.consignee.handlerId.validators', [required()])

    set(newState.fields, 'freightPickup.date.value', pickupLocalDateTime.date);
    set(newState.fields, 'freightDelivery.date.value', deliveryLocalDateTime.date);
    set(newState.fields, 'customer.company', get(this.editOrder, 'customer.company'));
    set(newState, 'customerNgr', get(this.editOrder, 'customer.ngr'));
    set(newState.fields, 'customer.ngrId.value', get(this.editOrder, 'customer.ngrId'));
    set(newState, 'customerContact', get(this.editOrder, 'customer.contact'));
    set(newState.fields, 'note', this.getMergedNote());

    if(this.isDuplicatingOrder)
      newState.canClearSiteOptions = true

    this.setState(newState, () => {
      this.getCustomerDetails()
    });
    this.props.dispatch(forceStopLoader());
  }

  getMergedNote() {
    const defaultNote = cloneDeep(this.fieldsDup.note);
    const editOrderNote = cloneDeep(get(this.editOrder, 'note', {}));

    const mergedNote = {
      description: get(editOrderNote,'description') || defaultNote.description,
      attachments: defaultNote.attachments,
      companyId: this.props.currentUser.companyId,
      errors: defaultNote.errors
    };

    return mergedNote;
  }

  getCustomerDetails = async () => {
    const customerCompany = get(this.editOrder, 'customer.company');
    const getCustomerContacts = this.getContacts(customerCompany, get(this.editOrder, 'customer.representedById'));
    if(customerCompany) {
      this.getCustomerNgrs(customerCompany.id);
      await Promise.all([getCustomerContacts]).then(responses => {
        this.setState({customerContacts: responses[0]}, () => this.applyingTemplate = false);
      });
    }
  }

  populateDependentFields = (consignor, consignee) => {
    const newState = { ...this.state };

    let dependentFields = ['customer.contactId', 'customer.ngrId'];
    forEach(dependentFields, key => set(newState.fields, `${key}.value`, get(this.editOrder, key)));
    if (!isEmpty(consignor)) {
      const consignor = get(this.editOrder, 'freightPickup.consignor');
      const site = get(consignor, 'sites[0]');
      const isCustomerPickupSite = get(this.editOrder, 'customer.company.id') === get(consignor, 'handler.companyId');
      set(newState.fields, 'freightPickup.consignor.handlerId.value', get(consignor, 'handlerId'));
      set(newState.fields, 'freightPickup.consignor.site.address', get(site, 'location.address'));
      newState.consignorAddress = { value: get(site, 'location.address') };
      set(newState.fields, 'freightPickup.consignor.site.locationType.value', get(site, 'locationContentType'));
      set(newState.fields, 'freightPickup.consignor.site.locationId.value', get(site, 'locationId'));
      if (this.siteCompanyCurrentUser() && !isCustomerPickupSite)
        set(newState, 'throughputOutloadCheckbox', true);
    }

    if (!isEmpty(consignee)) {
      const consignee = get(this.editOrder, 'freightDelivery.consignee');
      const site = get(consignee, 'sites[0]');
      const isCustomerDeliverySite = get(this.editOrder, 'customer.company.id') === get(consignee, 'handler.companyId');
      set(newState.fields, 'freightDelivery.consignee.handlerId.value', get(consignee, 'handlerId'));
      set(newState.fields, 'freightDelivery.consignee.site.address', get(site, 'location.address'));
      set(newState.fields, 'freightDelivery.consignee.site.locationType.value', get(site, 'locationContentType'));
      set(newState.fields, 'freightDelivery.consignee.site.locationId.value', get(site, 'locationId'));
      newState.consigneeAddress = { value: get(site, 'location.address') };
      if (this.siteCompanyCurrentUser() && !isCustomerDeliverySite)
        set(newState, 'throughputInloadCheckbox', true);
      newState.selectedConsigneeSite = find(this.state.consigneeSites, {
        id: get(site, 'locationId'),
        entity: get(site, 'locationContentType'),
      });
    }
    newState.isPopulated = true;
    this.setState(newState, () => this.props.dispatch(forceStopLoader()));
  };

  setHandlerSites = async () => {
    const selectedConsignor = this.state.selectedConsignor || get(this.editOrder, 'freightPickup.consignor.handler');
    if (!isEmpty(selectedConsignor)) {
      await Promise.all([this.getSites(selectedConsignor.id, 'farm', 'consignor')]);
    }

    const selectedConsignee = this.state.selectedConsignee || get(this.editOrder, 'freightDelivery.consignee.handler');
    if (!isEmpty(selectedConsignee)) {
      await Promise.all([this.getSites(selectedConsignee.id, 'farm', 'consignee')]);
    }

    this.populateDependentFields(selectedConsignor, selectedConsignee);
  };

  isNoteChanged = () => {
    return (get(this.state.fields, `note.description`) != get(this.editOrder, 'note.description') ||
            !isEqual(get(this.state.fields, `note.attachments`), get(this.editOrder, 'note.attachments')));
  };

  isDateTimeChanges = field =>{
    let measuredDate = moment(get(this.editOrder, `${field}.dateTime`)).format("YYYY-MM-DD");
    return get(this.state.fields, `${field}.date.value`) != measuredDate;
  };

  getEditData = () => {
    let data = {};
    forEach([
      'plannedTonnage',
      'generalConditions', 'specialConditions', 'customer.contactId', 'customer.ngrId', 'quantity',
      'varietyId', 'plannedGradeId', 'season', 'toleranceId', 'identifier'
    ], field => {
      if (this.isValueChanged(field)){
        set(data, field, get(this.state.fields, `${field}.value`));
      }
    });

    forEach(['freightPickup', 'freightDelivery'], field =>{
      if(this.isDateTimeChanges(field)){
        set(data, `${field}.dateTime`, `${get(this.state, `fields.${field}.date.value`)} 00:00:00Z`);;
      }
    });

    forEach(data, (value, key) => {
      if (includes(['plannedTonnage'], key) && isEmpty(value)) {
        set(data, key, null);
      }
    });

    forEach(data, (value, key) => {
      if (includes(['freightPickup', 'freightDelivery'], key) && !isEmpty(value)) {
        forEach(value, (v, k) => {
          if (includes(['date'], k) && !isEmpty(v)) {
            set(data, `${key}.dateTime`, `${get(data, `${key}.${k}`)} 00:00:00Z`);
          }
        });
      }
    });

    if (this.isHandlerChanged('freightPickup', 'consignor')) {
      set(data, 'freightPickup.consignor.handlerId', get(this.state.selectedConsignor, 'id'));
      if (isEqual(get(this.state.selectedConsignorSite, 'farmId'), get(this.state.selectedConsignor, 'id'))) {
        set(data, 'freightPickup.consignor.sites[0].locationId', get(this.state.selectedConsignorSite, 'id'));
        set(data, 'freightPickup.consignor.sites[0].locationType', get(this.state.selectedConsignorSite, 'entity'));
      }
    }

    if (this.isHandlerChanged('freightDelivery', 'consignee')) {
      set(data, 'freightDelivery.consignee.handlerId', get(this.state.selectedConsignee, 'id'));
      if (isEqual(get(this.state.selectedConsigneeSite, 'farmId'), get(this.state.selectedConsignee, 'id'))) {
        set(data, 'freightDelivery.consignee.sites[0].locationId', get(this.state.selectedConsigneeSite, 'id'));
        set(data, 'freightDelivery.consignee.sites[0].locationType', get(this.state.selectedConsigneeSite, 'entity'));
      }
    }
    if (this.isNoteChanged()) {
      let note = get(this.state.fields, 'note');
      note = omit(note, ['errors']);
      data.note = note;
    }
    const isPickupOrder= this.state.fields.typeId.value === REQUEST_ORDER_TYPE.PICKUP_ORDER
    const inloadThroughputFee = {
      'isActive': this.state.throughputInloadOrder,
      'type': 'inload'
    }
    const outloadThroughputFee = {
      'isActive': this.state.throughputOutloadOrder,
      'type': 'outload'
    }
    const throughputFees = isPickupOrder ? [outloadThroughputFee] : [inloadThroughputFee];
    set(data, 'throughputFees', throughputFees);
    return data;
  };

  isHandlerChanged = (checkpoint, handler) => {
    return (
      this.isValueChanged(`${checkpoint}.${handler}.handlerId`) ||
      get(this.state.fields, `${checkpoint}.${handler}.site.locationType.value`) !==
      get(this.editOrder, `${checkpoint}.${handler}.sites[0].locationContentType`) ||
      get(this.state.fields, `${checkpoint}.${handler}.site.locationId.value`) !==
      get(this.editOrder, `${checkpoint}.${handler}.sites[0].locationId`)
    )
  };

  getSubmitData = () => {
    let data = mapValues(this.state.fields, 'value');
    data.identifier = data.identifier ? data.identifier.toUpperCase() : '';
    data.freightPickup = mapValues(this.state.fields.freightPickup, 'value');
    data.freightDelivery = mapValues(this.state.fields.freightDelivery, 'value');

    set(data, 'freightDelivery.dateTime', `${data.freightDelivery.date} 00:00:00Z`);
    set(data, 'freightPickup.dateTime', `${data.freightPickup.date} 00:00:00Z`);
    if (this.state.fields.freightPickup.consignor.handlerId.value) {
      data.freightPickup.consignor = {
        handlerId: this.state.fields.freightPickup.consignor.handlerId.value,
        sites: [],
      };
      if (this.state.fields.freightPickup.consignor.site.locationId.value && this.state.fields.freightPickup.consignor.site.locationType.value) {
        data.freightPickup.consignor.sites = [
          {
            locationId: this.state.fields.freightPickup.consignor.site.locationId.value,
            locationType: this.state.fields.freightPickup.consignor.site.locationType.value,
          },
        ];
      }
    }
    if(this.state.selectedConsignor && !isEqual(get(this.state.selectedConsignor, 'id'), this.state.fields.freightPickup.consignor.handlerId.value)) {
      data.freightPickup.consignor = {
        handlerId: get(this.state.selectedConsignor, 'id'),
        sites: [],
      };
    }

    if (this.state.fields.freightDelivery.consignee.handlerId.value) {
      data.freightDelivery.consignee = {
        handlerId: this.state.fields.freightDelivery.consignee.handlerId.value,
        sites: [],
      };
      if (this.state.fields.freightDelivery.consignee.site.locationId.value && this.state.fields.freightDelivery.consignee.site.locationType.value) {
        data.freightDelivery.consignee.sites = [
          {
            locationId: this.state.fields.freightDelivery.consignee.site.locationId.value,
            locationType: this.state.fields.freightDelivery.consignee.site.locationType.value,
          },
        ];
      }
    }

    if(this.state.selectedConsignee && !isEqual(get(this.state.selectedConsignee, 'id'), this.state.fields.freightDelivery.consignee.handlerId.value)) {
      data.freightDelivery.consignee = {
        handlerId: get(this.state.selectedConsignee, 'id'),
        sites: [],
      };
    }

    data.customer = mapValues(this.state.fields.customer, 'value');
    for (var key in data) {
      if (has(data, key)) {
        if (/^\d+$/.test(key)) {
          delete data[key];
        }
      }
    }

    if (!isNumber(get(data, 'freightPickup.consignor.handlerId')))
      delete data.freightPickup.consignor;

    if (!isNumber(get(data, 'freightDelivery.consignee.handlerId')))
      delete data.freightDelivery.consignee;

    let note = this.state.fields.note;
    note = omit(note,['errors']);
    data.note = note;
    const isPickupOrder= this.state.fields.typeId.value === REQUEST_ORDER_TYPE.PICKUP_ORDER
    const inloadThroughputFee = {
      'isActive': this.state.throughputInloadOrder,
      'type': 'inload'
    }
    const outloadThroughputFee = {
      'isActive': this.state.throughputOutloadOrder,
      'type': 'outload'
    }
    const throughputFees = isPickupOrder ? [outloadThroughputFee] : [inloadThroughputFee];
    data.throughputFees = throughputFees;
    return data;
  };

  handleSubmit(event) {
    event.preventDefault();
    this.setAllFieldErrors();
    this.focusOnFirstErrorField();

    setTimeout(() => {
      if (
        this.valid(this.state.fields.freightPickup.consignor) &&
        this.valid(this.state.fields.freightDelivery.consignee) &&
        this.valid(this.state.fields.customer) &&
        this.valid(this.state.fields.freightPickup) &&
        this.valid(this.state.fields.freightDelivery) &&
        this.valid(this.state.fields)
      ) {
        const data = this.getSubmitData();
        if (this.state.preview) {
          if (this.isEditForm) {
            const editData = this.getEditData();
            if(this.isPickupRequestOrder() && editData.freightDelivery)
              delete editData.freightDelivery.consignee;
            if(this.isDeliveryRequestOrder() && editData.freightPickup)
              delete editData.freightPickup.consignor;
            if (isEmpty(editData) || isEqual(keys(editData), 'communication')) {
              alertifyjs.alert(`${this.getOrderEntity()} Order`, 'Please update the order before re-submitting.');
            }
            else if(Object.entries(editData).filter(val =>  val[1] != null).length == 1 && has(editData, "note")){
              this.props.dispatch(isLoading('genericTableWithData'));
              this.props.dispatch(raiseOrderAmendRequest(this.editOrderId, editData));
            }
            else {
              this.openEmailDialog(editData);
            }
          } else {
            this.openEmailDialog(data);
          }
        } else {
          this.handleOpenPreview();
        }
      }
    }, 200);
  }

  valid(fields) {
    return every(fields, field => {
      if (has(field, 'errors')) return field.errors.length === 0;
      else return true;
    });
  }

  async handleOrderTypeChange(value) {
    this.props.dispatch(isLoading(null, 100000000));
    if (value !== this.state.fields.typeId.value) {
      const newState = { ...this.state };
      newState.fields.typeId.value = value;
      this.resetFields(newState);
      this.setPlannedGradeValidators(newState);
      if(this.isPickupRequestOrder(newState)) {
        newState.fields.identifier.value = this.pickupOrderIdentifier;
        newState.fields.freightPickup.consignor.handlerId.validators = [required()]
        newState.fields.freightDelivery.consignee.handlerId.validators = []
        newState.fields.freightDelivery.consignee.handlerId.errors = []
      }
      else {
        newState.fields.identifier.value = this.deliveryOrderIdentifier;
        newState.fields.freightPickup.consignor.handlerId.validators = []
        newState.fields.freightPickup.consignor.handlerId.errors = []
        newState.fields.freightDelivery.consignee.handlerId.validators = [required()]
      }
      newState.customerTonnageAvailable = undefined
      newState.fields.plannedTonnage.errors = []
      newState.fields.toleranceId.value = newState.fields.typeId.value === PICKUP_REQUEST_ORDER_TYPE_ID ? TOLERANCE_NIL : TOLERANCE_5_OR_20_PERCENT;
      this.setState(newState, () => this.setFieldErrors('typeId', value));
    }
    this.props.dispatch(loaded());
  }

  setPlannedGradeValidators(newState) {
    newState.fields.plannedGradeId.validators = [required()];
  }

  handleFarmSideDrawer = (key, bool = false) => {
    this.setState({
      [key]: bool
    });
  };

  handleFarmSubmit = (key, data) =>{
    this.handleFarmSideDrawer(key, false);
    this.setState({inputTextFarm: ""});
    data = pick(data, ['id', 'name', 'ld']);
    if (key === 'consignorSideDrawer'){
      this.handleConsignorChange(null, data);
    }
    else if (key === 'consigneeSideDrawer'){
      this.handleConsigneeChange(null, data);
    }
  };

  handleQuantityFieldChange(event) {
    let value = event.target.value;
    if (value) value = parseFloat(value);

    this.setState({
      fields: {
        ...this.state.fields,
        quantity: { ...this.state.fields.quantity, value: value, errors: value ? [] : this.state.fields.quantity.errors },
      },
    });
  }

  handleTonnageFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
    this.setFieldErrors(event.target.id, event.target.value);
    this.setTonnageWarning(event.target.value, this.state.customerTonnageAvailable);
  }

  appendGeneralConditions() {
    const newState = { ...this.state };
    if (isEmpty(newState.fields.generalConditions.value))
      newState.fields.generalConditions.value = newState.selectedGeneralConditionText;
    else if (!includes(newState.fields.generalConditions.value, newState.selectedGeneralConditionText))
      newState.fields.generalConditions.value = newState.fields.generalConditions.value + '\n\n' + newState.selectedGeneralConditionText;
    this.setState(newState);
  }

  appendSpecialConditions() {
    const newState = { ...this.state };
    if (isEmpty(newState.fields.specialConditions.value))
      newState.fields.specialConditions.value = newState.selectedSpecialConditionText;
    else if (!includes(newState.fields.specialConditions.value, newState.selectedSpecialConditionText))
      newState.fields.specialConditions.value = newState.fields.specialConditions.value + '\n\n' + newState.selectedSpecialConditionText;
    this.setState(newState);
  }

  appendCustomCondition(e, field) {
    const newState = { ...this.state };
    newState.fields[field].value = e.target.value;
    this.setState(newState);
  }

  handleConditionSelector(value, id, chosenItem) {
    var key = null;
    if (id === 'generalConditionsSelector') {
      key = 'selectedGeneralConditionText';
    } else if (id === 'specialConditionsSelector') {
      key = 'selectedSpecialConditionText';
    }
    const newState = { ...this.state };
    if (chosenItem) {
      newState[key] = chosenItem.details;
      newState[id] = value;
    } else {
      newState[key] = undefined;
      newState[id] = undefined;
    }
    this.setState(newState);
  }

  handleConsignorInputChange = (event, value) => {
    this.setState({consignorInputText: value});
  };

  handleConsigneeInputChange = (event, value) => {
    this.setState({consigneeInputText: value});
  };

  async handleSiteChange (value, id, item) {
    var collectionKey = null;
    var addressKey = null;
    if (id.search('consignor') > -1) {
      collectionKey = 'consignorSites';
      addressKey = 'consignorAddress';
    } else if (id.search('consignee') > -1) {
      collectionKey = 'consigneeSites';
      addressKey = 'consigneeAddress';
    }
    if (item) {
      set(this.state, addressKey + '.value', item.address);
      if (collectionKey === 'consignorSites')
        set(this.state, 'selectedConsignorSite', item);
      else if (collectionKey === 'consigneeSites')
        set(this.state, 'selectedConsigneeSite', item);

      this.setFieldValue(id, item.id)
      this.setFieldValue(id.replace('locationId', 'locationType'), item.entity);

      if (includes(id, 'consignor')) {
        const newState = { ...this.state };
        if (get(item, 'type') === 'container') {
          newState.fields.freightPickup.selectedContainer = item;
        }
        else {
          newState.fields.containerNumber.value = undefined;
          newState.fields.freightPickup.selectedContainer = undefined;
        }
        this.setState(newState);
      }
    } else {
      this.setFieldValue(id, null)
      this.setFieldValue(id.replace('locationId', 'locationType'), null);
      const newState = { ...this.state };
      newState.fields.containerNumber.value = undefined;
      newState.fields.freightPickup.selectedContainer = undefined;
      this.setState(newState);
    }
  }

  async getSites(handlerId, partyType, getSitesFor) {
    let sites = [];
    if (partyType == 'farm') {
      let storageId = getSitesFor == 'consignor' ? get(this.editOrder, 'freightPickup.consignor.sites[0].locationId') : get(this.editOrder, 'freightDelivery.consignee.sites[0].locationId');
      let queryString = '';
      if (storageId)
        queryString = this.isEditForm ? `?amend&storageId=${storageId}` : '';
      queryString = queryString ? queryString + '&with_containers=true' : '?with_containers=true';
      let companyId = this.state.fields.customer.companyId.value;
      if (companyId)
        queryString = queryString + `&company_id=${companyId}`;
      let homeStorages = await APIService.farms(handlerId)
        .appendToUrl('storages/home/minimal/').appendToUrl(queryString)
        .get(this.props.userToken);
      let farmFields = await APIService.farms(handlerId)
        .farm_fields()
        .get(this.props.userToken);

      homeStorages = map(homeStorages, storage => {
        return merge(storage, {
          id: storage.id,
          entity: 'storage',
          storageType: 'home',
        });
      });

      farmFields = map(farmFields, ff => {
        return merge(ff, { entity: 'farmfield' });
      });

      sites = farmFields.concat(homeStorages);
    } else {
      let companySites = await APIService.companies(handlerId)
        .company_sites()
        .get(this.props.userToken);
      sites = map(companySites, site => {
        return merge(site, { entity: 'companysite' });
      });
      const selectedTrack = get(this.state.selectedBaseEntity, 'trackName');
      if (selectedTrack && getSitesFor == 'consignee') {
        sites = filter(sites, site => {
          if (site.isBhc) {
            return includes(site.tracks, selectedTrack);
          } else {
            return site;
          }
        });
      }
    }
    if (getSitesFor == 'consignor') {
      this.setState({
        consignorSites: sites,
      });
    } else if (getSitesFor == 'consignee') {
      this.setState({
        consigneeSites: sites,
      });
    }
  }

  handleThroughputOrderChange = event => this.setState({[event.target.id]: event.target.checked})

  handleThroughputCheckbox(newState, item, key) {
    const isCustomerSite = get(this.editOrder, 'customer.company.id') === get(item, 'companyId');
    if ((get(item, 'companyId') === get(this.props, 'currentUser.companyId')) && !isCustomerSite) {
      set(newState, key + 'Checkbox', true);
    } else {
      set(newState, key + 'Checkbox', false);
      set(newState, key + 'Order', false);
    }
    this.setState(newState);
  }

  handleConsignorChange(event, item) {
    const value = get(item, 'id');
    if (item && item.inputValue) {
      this.handleFarmSideDrawer("consignorSideDrawer", true);
      this.setState({
        inputTextFarm: item.inputValue
      });
      return;
    }
    if (
      this.state.fields.freightPickup.consignor.handlerId.value != value
    ) {
      const newState = { ...this.state };
      this.handleThroughputCheckbox(newState, item, 'throughputOutload');
      newState.pickupSettings = {};
      newState.fields.freightPickup.consignor.handlerId.value = value;
      newState.fields.freightPickup.consignor.site.locationId.value = null;
      newState.fields.freightPickup.consignor.site.locationType.value = null;
      if (isObject(item)) {
        newState.selectedConsignor = item;
        newState.consignors = uniqBy([...newState.consignors, item], 'id');
        if (!this.applyingBaseEntity || (this.isEditForm && this.state.isPopulated)) {
          this.getSites(value, 'farm', 'consignor');
        }
      } else {
        newState.selectedConsignor = undefined;
        newState.consignorSites = [];
      }

      this.setState(newState, () => {
        this.fetchPickupSettings(value);
        this.setFieldErrors('freightPickup.consignor.handlerId', value);
        this.getAvailableTonnageForCustomer(value, this.state.fields.plannedGradeId.value, this.state.fields.season.value, this.state.fields.customer.ngrId.value);
        if(this.isEditForm && item){
          const handlerCompanyId = get(item, 'companyId')
          this.updateDisableSiteAndStorageSettings(handlerCompanyId)
        }
      });
    }
  }

  updateDisableSiteAndStorageSettings = (handlerCompanyId) => {
    const isSiteCompanyCurrentUser = isEqual(handlerCompanyId, get(this.props, 'currentUser.companyId'))
    const isUserSiteCustomerOrIsStaff =  isStaff() || isSiteCompanyCurrentUser || isEqual(get(this.editOrder, 'customer.companyId'), get(this.props, 'currentUser.companyId'))

    const disableEssentials = includes(['delivered', 'in_progress', 'completed'], get(this.editOrder, 'status'));
    const disableSite =  disableEssentials || (!(isUserSiteCustomerOrIsStaff && this.canAmendSite()))
    const disableStorage =  disableEssentials || (!(isUserSiteCustomerOrIsStaff && this.canAmendStorage(isSiteCompanyCurrentUser)))

    this.setState({disableSite: disableSite, disableStorage: disableStorage})
  }

  handleConsigneeChange(event, item) {
    const value = get(item, 'id');
    if (item && item.inputValue) {
      this.handleFarmSideDrawer("consigneeSideDrawer", true);
      this.setState({
        inputTextFarm: item.inputValue
      });
      return;
    }
    if (
      this.state.fields.freightDelivery.consignee.handlerId.value != value
    ) {
      const newState = { ...this.state };
      this.handleThroughputCheckbox(newState, item, 'throughputInload');
      newState.deliverySettings = {};
      newState.fields.freightDelivery.consignee.handlerId.value = value;
      newState.fields.freightDelivery.consignee.site.locationId.value = null;
      newState.fields.freightDelivery.consignee.site.locationType.value = null;
      if (isObject(item)) {
        newState.selectedConsignee = item;
        newState.consignees = uniqBy([...newState.consignees, item], 'id');
        this.getSites(value, 'farm', 'consignee');
      } else {
        newState.selectedConsignee = undefined;
        newState.consigneeSites = [];
      }
      this.setState(newState, () => {
        this.fetchDeliverySettings(value);
        this.setFieldErrors('freightDelivery.consignee.handlerId', value);
        if(this.isEditForm && item){
          const handlerCompanyId = get(item, 'companyId')
          this.updateDisableSiteAndStorageSettings(handlerCompanyId)
        }
      });
    }
  }

  fetchPickupSettings = farmId => this.fetchSMSettings('pickupSettings', farmId)

  fetchDeliverySettings = farmId => this.fetchSMSettings('deliverySettings', farmId)

  fetchSMSettings = (siteSettings, farmId) => {
    if(farmId) {
      APIService
        .company_sites(farmId)
        .appendToUrl('settings/')
        .get()
        .then(settings => this.setState({[siteSettings]: settings}, () => {
          this.raiseAlertIfNoPermission();
        }));
    }
  }

  getPermissionSettings() {
    const { pickupSettings, deliverySettings } = this.state;
    const settings = this.isDeliveryRequestOrder() ? deliverySettings : pickupSettings;
    return settings.checkpointOrderCreationPermission;
  }

  getHandler() {
    const isDeliveryRequestOrder = this.isDeliveryRequestOrder();
    if(this.editOrder && !isEqual(get(this.editOrder, 'status'), 'template'))
      return this.editOrder.typeId == 5 ? this.editOrder.freightDelivery.consignee.handler : this.editOrder.freightPickup.consignor.handler;

    return isDeliveryRequestOrder ? this.state.selectedConsignee : this.state.selectedConsignor;
  }

  raiseAlertIfNoPermission() {
    if(!this.hasPermission()) {
      const permission = this.getPermissionSettings();
      const employeeLabel = permission === 'site_only' ? 'site employees' : 'site employees and grain owners';
      alertifyjs.alert(
        'Permission Denied',
        `Only ${employeeLabel} can create the Pickup/Delivery orders for this site. Please contact the site employees for generating the Pickup/Delivery Order numbers.`,
        () => {
          if(this.isDeliveryRequestOrder()) {
            this.handleConsigneeChange(null, null);
            this.handleConsigneeInputChange('');
          } else {
            this.handleConsignorChange(null, null);
            this.handleConsignorInputChange('');
          }
        }
      );
    }
  }

  hasPermission() {
    const { fields } = this.state;
    const { currentUser } = this.props;
    const permission = this.getPermissionSettings();
    const handler = this.getHandler();
    if(permission === 'site_only')
      return currentUser.companyId === handler.companyId;
    else if (permission && fields.customer.companyId.value)
      return includes([fields.customer.companyId.value, handler.companyId], currentUser.companyId);
    else
      return true;
  }

  isAcceptanceRequired() {
    const handler = this.getHandler();
    const permission = this.getPermissionSettings();
    const { currentUser } = this.props;
    return handler && currentUser.companyId !== handler.companyId &&
           permission === 'grain_owner_and_site_acceptance_from_site';
  }

  isAcceptanceDisabled() {
    return (
      this.isEditingBeforeConfirmation() ||
      this.isRepresentingCustomer() ||
      get(this.editOrder, 'isCustomer') || (this.props.currentUser.companyId === get(this.getHandler(), 'companyId'))
    );
  }

  handleCommodityChange(value, id) {
    if (this.state.fields.commodityId.value != value) {
      const newState = { ...this.state };
      if (this.hasQuantityBasedCommodity(value)) 
        newState.fields.quantity.validators = [required()];
      else {
        newState.fields.quantity.validators = [];
        newState.fields.quantity.errors = [];
        newState.fields.quantity.value = undefined;
        newState.fields.quantity.errors = [];
      }
      newState.fields.commodityId.value = value;
      newState.fields.varietyId.value = null;
      this.setState(newState, () => {
        this.setFieldErrors(id, value);
      });
    }
  }

  handleVarietyChange(value) {
    if (this.state.fields.varietyId.value != value) {
      const newState = { ...this.state };
      newState.fields.varietyId.value = value || null;
      this.setState(newState);
    }
  }

  handleGradeChange(valueObject, id) {
    this.getAvailableTonnageForCustomer(this.state.fields.freightPickup.consignor.handlerId.value, valueObject.id, this.state.fields.season.value, this.state.fields.customer.ngrId.value);
    this.setFieldValue(id, valueObject.id);
    this.setFieldErrors(id, valueObject.id);
  }

  handleCustomerChange(value, id, item) {
    const isCustomerOnlyEdit = true;
    let isCurrentUserSameAsParty = get(item, 'id') === get(this.props, 'currentUser.companyId') && !this.isEditForm;;
    if (!this.isEditForm || isCustomerOnlyEdit) {
      const newState = { ...this.state };
      if(!this.isDuplicatingOrder){
        newState.canClearSiteOptions = true
        newState.selectedConsignor = undefined
        newState.fields.freightPickup.consignor.site.locationId.value = undefined
        newState.fields.freightPickup.consignor.handlerId.value = undefined
        newState.fields.freightDelivery.consignee.site.locationId.value = undefined
        newState.fields.freightDelivery.consignee.handlerId.value = undefined
      }

      if (this.state.fields.customer.companyId.value !== value) {
        newState.fields.customer.contactId.value = null;
        newState.fields.customer.ngrId.value = null;
        newState.fields.customer.company = null;
        newState.fields.customer.companyId.value = value;
        newState.fields.customer.representedById.value = get(item, 'representedById');
      }

      if (item) {
        if (isCurrentUserSameAsParty) {
          newState.fields.customer.contactId.value = this.props.currentUser.id;
        }
        newState.fields.customer.company = item;
        newState.selectedCustomer = item;
      } else {
        newState.customerContacts.length = 0;
        newState.customerNgrs.length = 0;
        newState.selectedCustomer = undefined;
      }
      this.setState(newState, async () => {
        if(this.isDuplicatingOrder)
          this.isDuplicatingOrder = false

        this.setFieldErrors(id, value);
        this.handleSideDrawer();
        if (item) {
          await this.getCustomerContacts(item);
          this.getCustomerNgrs(this.state.fields.customer.companyId.value);
        }
      });
    }
  }

  handleCustomerContactChange(value, id) {
    const newState = { ...this.state };
    newState.fields.customer.contactId.value = value;
    this.setState(newState);
    this.setFieldErrors(id, value);
  }

  handleSelectFieldChange(value, id) {
    if (id === 'season') {
      this.getAvailableTonnageForCustomer(this.state.fields.freightPickup.consignor.handlerId.value, this.state.fields.plannedGradeId.value, value, this.state.fields.customer.ngrId.value);
    }
    if (id === 'customer.ngrId') {
      this.getAvailableTonnageForCustomer(this.state.fields.freightPickup.consignor.handlerId.value, this.state.fields.plannedGradeId.value, this.state.fields.season.value, value);
    }
    this.setFieldValue(id, value);
    this.setFieldErrors(id, value); 
  }

  handleFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
  }

  setFieldValue(id, value, validateAfterSet = false, callback) {
    const newState = { ...this.state };
    set(newState.fields, id + '.value', value);
    this.setState(newState, () => {
      if (validateAfterSet) this.setFieldErrors(id, value);
      if (callback) callback();
    });
  }

  setFieldErrors(id, value) {
    const newState = { ...this.state };
    if (newState.setFieldErrors && !this.applyingTemplate) {
      this.getFieldErrors(get(newState.fields, id), value);
    }
    this.setState(newState);
  }

  setFieldCustomErrors = (id, msg) => {
    const newState = { ...this.state };
    const field = get(newState.fields, id);
    if (has(field, 'errors') && isArray(field.errors)) {
      if (msg) field.errors.push(msg);
      else field.errors = [];
    }
    this.setState(newState);
  };

  getFieldErrors(field, value) {
    if (has(field, 'value') && has(field, 'validators')) {
      field.errors = [];
      field.validators.forEach(validator => {
        if (validator.isInvalid(value || field.value)) {
          field.errors.push(validator.message);
        }
      });
    }
  }

  setAllFieldErrors() {
    const newState = { ...this.state };
    this.applyValidatorsOn(newState.fields);
    this.applyValidatorsOn(newState.fields.freightPickup);
    this.applyValidatorsOn(newState.fields.freightPickup.consignor);
    this.applyValidatorsOn(newState.fields.freightDelivery);
    this.applyValidatorsOn(newState.fields.freightDelivery.consignee);
    this.applyValidatorsOn(newState.fields.customer);
    this.setState(newState);
    window.scrollTo(0, 0);
  }

  focusOnFirstErrorField() {
    const nestedFields = ["customer.companyId", "customer.contactId", "customer.ngrId", "freightPickup.date",
                          "freightPickup.consignor.handlerId", "freightDelivery.date", "freightDelivery.consignee.handlerId"];

    const autoCompleteFields = ['externalReferenceNumber', "commodityId", "plannedGradeId",
                                "generalConditionsSelector", "specialConditionsSelector"];

    for(let i = 0; i < this.fieldsOrder.length; i++) {
      const formField = this.fieldRef[this.fieldsOrder[i]];
      const field = this.state.fields[this.fieldsOrder[i]];

      if (nestedFields.indexOf(this.fieldsOrder[i]) !== -1) {
        if ((this.fieldsOrder[i] === "customer.companyId" && this.state.fields.customer.companyId.errors.length) ||
            (this.fieldsOrder[i] === 'customer.contactId' && this.state.fields.customer.contactId.errors.length > 0) ||
            (this.fieldsOrder[i] === "customer.ngrId" && this.state.fields.customer.ngrId.errors.length)
        ) {
          getAutoSelectFocusField(this.fieldRef, this.fieldsOrder[i]);
          formField.current.scrollIntoView({ block: 'center' });
          break;
        }
        if (
          (this.fieldsOrder[i] === 'freightDelivery.consignee.handlerId' && this.state.fields.freightDelivery.consignee.handlerId.errors.length > 0 && formField.current.node) ||
          (this.fieldsOrder[i] === 'freightPickup.consignor.handlerId' && this.state.fields.freightPickup.consignor.handlerId.errors.length > 0 && formField.current.node)
        ){
          formField.current.node.previousSibling.focus();
          break;
        }
        if (
          (this.fieldsOrder[i] === 'freightPickup.date' && this.state.fields.freightPickup.date.errors.length > 0) ||
          (this.fieldsOrder[i] === 'freightDelivery.date' && this.state.fields.freightDelivery.date.errors.length > 0)
        ) {
          formField.current?.focus();
          break;
        }
      } else if (autoCompleteFields.indexOf(this.fieldsOrder[i]) !== -1) {
        if (field && field.errors.length > 0) {
          getAutoSelectFocusField(this.fieldRef, this.fieldsOrder[i]);
          formField.current.scrollIntoView({ block: 'center' });
          break;
        }
      } else if (field && field.errors.length > 0) {
        if (formField.current.node) {
          formField.current.node.previousSibling.focus();
          break;
        } else {
          formField.current.focus();
          formField.current.scrollIntoView({ block: 'center' });
          break;
        }
      }
    }
  }

  applyValidatorsOn(fields) {
    forEach(fields, field => {
      this.getFieldErrors(field);
    });
  }

  handleBlur(event) {
    this.setFieldErrors(event.target.id, event.target.value);
  }

  forceSetFieldErrors(id, errors) {
    const newState = {...this.state};
    set(newState.fields, id + '.errors', errors);
    this.setState(newState);
  }

  getExpectedPickupSiteName() {
    return get(this.state, 'selectedConsignor.name');
  }

  getExpectedDeliverySiteName() {
    return get(this.state, 'selectedConsignee.name');
  }

  pickUpDateMax() {
    return this.state.fields.freightDelivery.date.value ? new Date(this.state.fields.freightDelivery.date.value) : null;
  }

  deliveryDateMin() {
    let minimumDate = this.state.fields.freightPickup.date.value ? new Date(this.state.fields.freightPickup.date.value) : null;
    if (this.isEditForm) {
      const pickupDate = get(this.editOrder, 'freightPickup.date');
      minimumDate = pickupDate ? moment(pickupDate) : minimumDate;
    }
    return minimumDate;
  }

  async getCustomerNgrs(customerId, newlyCreatedNgr) {
    let customerNgrs = await APIService.companies(customerId).ngrs().appendToUrl('minimal/').get(this.props.userToken);
    const newState = {...this.state};
    newState.customerNgrs = customerNgrs;
    if (newlyCreatedNgr) {
      newState.fields.customer.ngrId.value = newlyCreatedNgr.id;
    }
    newState.customerNgrs = customerNgrs;
    this.setState(newState);
  }

  handleCancelButtonClick(event) {
    event.preventDefault();
    if (this.isEditForm && this.editOrderId) {
      window.history.back();
      setTimeout(() => window.location.reload(), 100);
    } else {
      window.location.hash = '/orders/freights';
    }
  }

  handleIdentifierChange(event) {
    const regex = new RegExp('^[a-zA-Z0-9]*$');
    if (regex.test(event.target.value)) {
      this.setFieldValue(event.target.id, event.target.value?.toUpperCase());
    } else {
      this.setFieldValue(event.target.id, this.state.fields.identifier.value?.toUpperCase());
    }
  }

  resetFields(newState) {
    if(newState.fields.typeId.value === REQUEST_ORDER_TYPE.PICKUP_ORDER) {
      this.setConsigneeValidator(newState, []);
      this.resetFreightDelivery(newState);
    }
    if(newState.fields.typeId.value === REQUEST_ORDER_TYPE.DELIVERY_ORDER) {
      this.setConsignorValidator(newState, []);
      this.resetFreightPickup(newState);
    }
  }

  resetFreightPickup(newState) {
    newState.fields.freightPickup.date.value = undefined;
    newState.fields.freightPickup.consignor.handlerId.value = undefined;
    newState.fields.freightPickup.date.errors = [];
    newState.fields.freightPickup.consignor.handlerId.errors = [];
  }

  resetFreightDelivery(newState) {
    newState.fields.freightDelivery.date.value = undefined;
    newState.fields.freightDelivery.consignee.handlerId.value = undefined;
    newState.fields.freightDelivery.date.errors = [];
    newState.fields.freightDelivery.consignee.handlerId.errors = [];
  }

  getCustomers() {
    return this.props.allDirectoryCompanies || [];
  }

  // eslint-disable-next-line no-unused-vars
  async getCustomerContacts(company, _='', customerContactId='', representedById) { // eslint-disable-line no-unused-vars
    const contacts = await this.getContacts(company, representedById);
    this.setState({customerContacts: contacts});
  }

  async getContacts(company, representedById) {
    const queryParams = representedById ? {representing_company_id: representedById} : {};
    return get(company, 'id') ? await APIService.contracts().companies(company.id).contacts().get(this.props.userToken, {}, queryParams) : [];
  }

  getKeyContactId(contacts, entityId) {
    const keyContacts = filter(contacts, {keyContact: true});
    let keyContactId = null;
    if (keyContacts && keyContacts.length === 1) {
      keyContactId = keyContacts[0].id;
    } else {
      const companyKeyContact = find(keyContacts, { companyId: entityId });
      if (companyKeyContact) {
        keyContactId = companyKeyContact.id;
      }
    }
    return keyContactId;
  }

  showProviderAndInvoicingSection = () => {
    return true;
  };

  showProviderAndInvoicingSectionOnNew = () => {
    return true;
  };

  showProviderSectionOnEdit = () => {
    return !!get(this.editOrder, 'providerId');
  };

  setConsignorValidator(newState, validators) {
    newState.fields.freightPickup.consignor.handlerId.validators = validators;
  }

  setConsigneeValidator(newState, validators) {
    newState.fields.freightDelivery.consignee.handlerId.validators = validators;
  }

  handleOpenPreview = () => {
    this.setState({ preview: true });
  };

  handleClosePreview = () => {
    this.setState({ preview: false });
  };

  getEstablishmentName = establishment => get(establishment, 'entity') === 'farm' ? get(establishment, 'displayName') : get(establishment, 'name');

  getPreviewFields = () => {
    const fields = this.state.fields;
    const currentUserCompany = this.isEditForm ? get(this.editOrder, 'createdBy.company') : this.props.currentUser.company;
    const customer = fields.customer;
    const submitData = this.getSubmitData();
    const consignorHandlerId = get(submitData, 'freightPickup.consignor.handlerId');
    const consigneeHandlerId = get(submitData, 'freightDelivery.consignee.handlerId');
    const consignor = this.getEstablishment(consignorHandlerId, null, this.state.consignors);
    const consignee = this.getEstablishment(consigneeHandlerId, null, this.state.consignees);
    const pickupStorage = get(find(this.state.consignorSites, {id: this.state.fields.freightPickup.consignor.site.locationId.value}), 'name');
    const deliveryStorage = get(find(this.state.consigneeSites, {id: this.state.fields.freightDelivery.consignee.site.locationId.value}), 'name');
    const pickupDetails = {
      'START DATE': moment(get(submitData, 'freightPickup.date')).format(this.state.countryFormats.dateDisplay),
      'END DATE': moment(get(submitData, 'freightDelivery.date')).format(this.state.countryFormats.dateDisplay),
      'PICKUP SITE': this.getEstablishmentName(consignor),
      'PICKUP STORAGE': pickupStorage,
      'PICKUP ADDRESS': consignor ? (typeof get(consignor, 'address') == 'object' ?  get(consignor, 'address.address') : get(consignor, 'address')) :'',
    };

    const deliveryDetails = {
      'START DATE': moment(get(submitData, 'freightPickup.date')).format(this.state.countryFormats.dateDisplay),
      'END DATE': moment(get(submitData, 'freightDelivery.date')).format(this.state.countryFormats.dateDisplay),
      'DELIVERY SITE': this.getEstablishmentName(consignee),
      'DELIVERY STORAGE': deliveryStorage,
      'DELIVERY ADDRESS': consignee ? (typeof get(consignee, 'address') == 'object' ? get(consignee, 'address.address') : get(consignee, 'address')) : '',
    };

    return {
      priceUnit: this.priceUnit(),
      identifier: this.state.fields.identifier.value,
      dateIssued: moment(new Date()).format(this.state.countryFormats.dateDisplay),
      currentUser: this.isEditForm ? get(this.editOrder, 'createdBy') : this.props.currentUser,
      currentUserCompanyName: get(currentUserCompany, 'name'),
      currentUserCompanyWebsite: get(currentUserCompany, 'website'),
      currentUserCompanyPhone: get(currentUserCompany, 'mobile'),
      currentUserCompanyAbn: get(currentUserCompany, 'abn'),
      currentUserCompanyAddress: get(currentUserCompany, 'address.address'),
      currentUserCompanyLogo: this.state.base64Logo,
      customerDetails : {
        customer: find(this.getCustomers(), {id: customer.companyId.value}),
        customerContact: find(this.state.customerContacts, {id: fields.customer.contactId.value}) || this.state.customerContact,
        customerNgr: this.getPropertyById(fields.customer.ngrId.value, 'ngrNumber',this.state.customerNgrs) || get(this.state.customerNgr, 'ngrNumber')
      },
      commodity: find(this.props.commodities, { id: fields.commodityId.value }),
      variety: find(this.props.varieties, { id: fields.varietyId.value }),
      plannedGrade: find(this.props.grades, { id: fields.plannedGradeId.value }) || get(this.editOrder, 'plannedGrade'),
      quantity: fields.quantity.value,
      plannedTonnage: fields.plannedTonnage.value,
      quantityLabel: this.quantityLabel(),
      quantityUnit: this.quantityUnit(),
      season: fields.season.value,
      specialConditions: fields.specialConditions.value,
      generalConditions: fields.generalConditions.value,
      pickupDetails,
      deliveryDetails,
      note: pick(fields.note, ['description', 'attachments']),
      tolerance: get(find(this.state.tolerances, {id: this.state.fields.toleranceId.value}), 'name')
    };
  };

  getEstablishment = (id, type, parties = []) => type ? find(parties, {id: id, entity: type}) : find(parties, {id: id});

  handleSideDrawer(type) {
    const sideDrawer = cloneDeep(DEFAULT_SIDE_DRAWERS_STATE);
    if (type) {
      sideDrawer[type] = true;
    }
    this.setState(sideDrawer);
  }

  openCustomerSideDrawer(){
    const companyId = get(this.state.fields, 'customer.companyId.value');
    if(companyId){
      const { dispatch } = this.props;
      dispatch(canCreateEmployee(companyId, 'company', () => {
        this.props.handleAddEmployeeButtonClick();
        this.handleSideDrawer('customerSideDrawerIsOpen');
      }));
    }
  }

  isGradeDisable = () => {
    return false;
  };

  closeCustomerSideDrawer(){
    this.setState({customerSideDrawerIsOpen: false});
  }

  openGeneralConditionSideDrawer() {
    const { dispatch } = this.props;
    dispatch(clickAddGeneralConditionButton());
    this.handleSideDrawer('generalConditionSideDrawerIsOpen');
  }

  closeGeneralConditionSideDrawer() {
    this.setState({ generalConditionSideDrawerIsOpen: false });
  }

  openSpecialConditionSideDrawer() {
    const { dispatch } = this.props;
    dispatch(clickAddSpecialConditionButton());
    this.handleSideDrawer('specialConditionSideDrawerIsOpen');
  }

  closeSpecialConditionSideDrawer() {
    this.setState({ specialConditionSideDrawerIsOpen: false });
  }

  openNgrSideDrawer(ngrEntity){
    const companyId = get(this.state.fields, 'customer.companyId.value');
    if(companyId){
      const { dispatch } = this.props;
      const selectedEntity = find(this.props.allDirectoryCompanies, {id: companyId});;
      dispatch(canCreateNgr(companyId, 'company', selectedEntity, () => {
        this.props.handleAddCompanyNgrButtonClick();
        this.props.handleAddNgrButtonClick();
        this.setState({ngrEntity: ngrEntity}, () => this.handleSideDrawer('ngrSideDrawerIsOpen'));
      }));
    }
  }

  closeNgrSideDrawer() {
    this.setState({ ngrSideDrawerIsOpen: false });
  }

  getPropertyById(id, field, list = []) {
    if(!isEmpty(list)) {
      const element = list.find(val => val.id === id);
      return element && get(element, field);
    }
  }

  addCustomerContactButton() {
    return !this.selectedOrderId && (this.state.fields.customer && this.state.fields.customer.companyId.value) &&
           <AddCircleIconWithTooltip
             id="addCustomerContact"
             title="Add Customer Contact"
             onClick={() => this.openCustomerSideDrawer()}
           />;
  }

  addGeneralConditionButton() {
    return <AddCircleIconWithTooltip id='addGeneralCondition' title='Add General Condition' onClick={() => this.openGeneralConditionSideDrawer()} />;
  }

  addSpecialConditionButton() {
    return <AddCircleIconWithTooltip id='addSpecialCondition' title='Add Special Condition' onClick={() => this.openSpecialConditionSideDrawer()} />;
  }

  addCustomerNgrButton() {
    return this.state.fields.customer.companyId.value &&
           <AddCircleIconWithTooltip
             id="addCustomerNgr"
             title="Add Customer NGR"
             onClick={() => this.openNgrSideDrawer(this.state.fields.customer)}
           />;
  }

  setDefaultGeneralCondition(condition) {
    this.handleSideDrawer();
    if (condition) {
      this.handleConditionSelector(condition.id, 'generalConditionsSelector', condition);
    }
  }

  setDefaultSpecialCondition(condition) {
    this.handleSideDrawer();
    if (condition) {
      this.handleConditionSelector(condition.id, 'specialConditionsSelector', condition);
    }
  }

  customerSideDrawer() {
    const companyId = get(this.state.fields, 'customer.companyId.value');
    const customer = get(this.state.fields.customer, 'company');
    return this.props.isEmployeeSideDrawerOpened &&
           companyId &&
           <SideDrawer
             isOpen={this.state.customerSideDrawerIsOpen}
             title={getLabelForEmployeeForm(customer)}
             onClose={this.closeCustomerSideDrawer}
             app="officeEmployee"
             canCreate={true}
           >
             <CreateEmployee
               closeDrawer={this.closeCustomerSideDrawer}
               canAccessAny={true}
               companyId={companyId}
               selectedCompany={customer}
               establishmentDetails={this.state.fields.customer}
               getContacts={this.getCustomerContacts}
             />
           </SideDrawer>;
  }

  getEmailPopupParties = () => {
    return ['customer', 'consignor', 'consignee'];
  };

  getSelectedParties = () => {
    return ['customer'];
  };

  getPartyEmails = () => {
    let parties = {
      customer: get(find(this.state.customerContacts, {id: this.state.fields.customer.contactId.value}), 'email'),
      consignor: '',
      consignee: '',
    };

    return parties;
  };

  getEmailPopupPartiesCompanyIds() {
    const parties = this.getEmailPopupParties();
    const ids = {};
    forEach(parties, party => {
      if(party === 'consignor')
        ids.consignor = get(this.state.selectedConsignor, 'companyId');
      if(party === 'consignee')
        ids.consignee = get(this.state.selectedConsignee, 'companyId');
      if(party === 'customer' || get(party, 'label'))
        ids.customer = this.state.fields.customer.companyId.value;
    });

    return ids;
  }

  async getPartyContacts() {
    if(this.gotOncePartyContacts)
      return;

    this.gotOncePartyContacts = true;
    const parties = this.getEmailPopupPartiesCompanyIds();
    const partiesWithoutContacts = without(keys(parties), 'customer');
    const contacts = {};
    forEach(parties, (id, party) => {
      contacts[party] = [];
      if(party === 'customer')
        contacts[party] = this.state.customerContacts;
    });
    if(!isEmpty(partiesWithoutContacts)) {
      const companyIds = uniq(compact(values(pick(parties, partiesWithoutContacts))));
      if(isEmpty(companyIds))
        return contacts;
      const companyQueryString = map(companyIds, id => `company_ids=${id}`).join('&');
      const employees = await APIService.profiles().appendToUrl(`employees-signature/?${companyQueryString}`).get(this.props.userToken);
      forEach(partiesWithoutContacts, party => {
        contacts[party] = filter(employees, {companyId: parties[party]});
      });
    }

    return contacts;
  }

  getEmailSubject = () => {
    const companyName = get(this.props.currentUser,'company.name',"");
    const amendingText = this.isEditForm ? "[Amendment] " : "";
    const identifier = get(this.state.fields, 'identifier.value', '').toUpperCase();
    const entity = this.getOrderEntity();
    return `${amendingText}${companyName} ${entity} Order #${identifier}`;
  };

  getFooterNote = () => {
    let bindText = '';
    this.sendButtonText = null;
    const entity = this.getOrderEntity();
    return this.isEditForm ?
           `Amendments and updated ${entity} Order PDF will be automatically sent as part of the email${bindText}` :
           `${entity} Order PDF will be automatically sent as part of the email${bindText}`;
  };

  openEmailDialog = (data) => {
    let emailPopupParties = this.getEmailPopupParties();
    this.setState({ showEmailDialog: true, emailPopupParties: emailPopupParties });
    this.payloadData = data;
  };

  closeEmailDialog = (communicationData, justClose) => {
    if(justClose) {
      this.gotOncePartyContacts = false;
      this.setState({showEmailDialog: false});
    }
    else if(this.state.showEmailDialog) {
      let data = this.payloadData;
      data = omit(data, ['freightPickup.date', 'freightDelivery.date', 'freightPickup.timeStart', 'freightDelivery.timeStart']);
      if(communicationData) {
        data['communication'] = communicationData;
      }
      if(this.isPickupRequestOrder() && get(data, 'freightDelivery.consignee'))
        delete data.freightDelivery.consignee;
      if(this.isDeliveryRequestOrder() && get(data, 'freightPickup.consignor'))
        delete data.freightPickup.consignor;
      this.setState({showEmailDialog: false}, () => {
        const {dispatch} = this.props;
        if (this.isEditForm) {
          dispatch(isLoading('genericTableWithData'));
          dispatch(raiseOrderAmendRequest(this.editOrderId, data));
        } else {
          dispatch(isLoading('genericTableWithData'));
          dispatch(create(data, false, true));
        }
      });
    }
    this.handleClosePreview();
  };

  getSelectedCommodity = commodityId => {
    const id = commodityId || this.state.fields.commodityId.value
    return id ? find(this.props.commodities, {id: id}) : null
  }

  hasQuantityBasedCommodity = commodityId => Boolean(this.getSelectedCommodity(commodityId || this.state.fields.commodityId.value)?.isQuantityBased)

  quantityLabel() {
    return get(this.getSelectedCommodity(), 'quantityLabel')
  }

  quantityUnit() {
    return get(this.getSelectedCommodity(), 'unit')
  }

  priceUnit(commodityId) {
    return get(this.getSelectedCommodity(commodityId), 'priceUnit', MT_UNIT)
  }

  noteHandler = val => {
    this.setState({fields: {...this.state.fields, note: val}});
  };

  isEditingBeforeConfirmation() {
    return includes(['draft', 'planned'], get(this.editOrder, 'status'));
  }

  isPickupRequestOrder(state) {
    const _state = state || this.state;
    return _state.fields.typeId.value === REQUEST_ORDER_TYPE.PICKUP_ORDER;
  }

  isDeliveryRequestOrder() {
    return this.state.fields.typeId.value === REQUEST_ORDER_TYPE.DELIVERY_ORDER;
  }

  getOrderEntity() {
    if(this.isPickupRequestOrder())
      return 'Pickup Request';
    if(this.isDeliveryRequestOrder())
      return 'Delivery Request';

    return 'Request';
  }

  handleQuickOrderChange(item) {
    if(item) {
      const newState = {...this.state};
      alertifyjs
        .confirm(
          'Warning',
          'Already filled values in the Order will be overridden. Do you wish to continue?',
          () => {
            this.applyingTemplate = true;
            this.editOrderId = item.id;
            this.resetFields(newState);
            this.setState(newState, () => {
              this.setOrder(item.id);
              this.props.dispatch(isLoading());
            });
          },
          () => {},
        )
        .set('labels', { ok: 'Yes', cancel: 'No' });
    }
  }

  getTemplatesService(queryString, callback) {
    queryString.type_id = []
    REQUEST_ORDER_TYPE_IDS.forEach(id => { queryString.type_id.push(id); });
    APIService.freights().appendToUrl('templates/').get(null, null, queryString).then(templates => {
      callback(templates);
    });
  }

  handleSaveButtonClick() {
    if(!this.state.fields.typeId.value) {
      alertifyjs.alert(`${this.getOrderEntity()} Order`, 'Please select order type to create template.');
      return;
    }
    else {
      const newState = { ...this.state };
      newState.templateDialog = true;
      newState.templateName.value = null;
      newState.templateName.errors = [];
      this.setState(newState);
    }
  }

  handleTemplateDialogClose() {
    this.setState({
      ...this.state,
      templateDialog: false,
    });
  }

  saveTemplate() {
    const newState = { ...this.state };
    const templateNameValidator = newState.templateName.validators[0];
    if (templateNameValidator.isInvalid(newState.templateName.value)) {
      newState.templateName.errors.push(templateNameValidator.message);
      this.setState(newState);
    } else {
      let data = this.getSubmitData();
      const { dispatch } = this.props;
      data['status'] = 'template';
      data['templateName'] = newState.templateName.value;
      if(includes(get(data.freightDelivery, 'dateTime'), 'undefined') || includes(get(data.freightDelivery, 'dateTime'), 'Invalid')) {
        delete data.freightDelivery.dateTime;
      }
      if(includes(get(data.freightPickup, 'dateTime'), 'undefined') || includes(get(data.freightPickup, 'dateTime'), 'Invalid')) {
        delete data.freightPickup.dateTime;
      }
      if(isEmpty(toString(get(data, 'plannedTonnage'))))
        delete data.plannedTonnage;

      data['note'] = pick(get(this.state.fields, 'note'), ['description', 'attachments', 'companyId']);
      data = omit(data, ['identifier', 'freightPickup.date', 'freightDelivery.date', 'freightPickup.timeStart', 'freightDelivery.timeStart']);
      data = pickBy(data, (value) => {
        return !(value === undefined || value === "" || value === null);
      })
      newState.templateDialog = false;
      this.setState(newState);
      dispatch(isLoading('genericTableWithData'));
      dispatch(create(data, false, false, true));
    }
  }

  handleTemplateNameChange(event) {
    const newState = { ...this.state };
    newState.templateName.value = event.target.value;
    const targetId = event.target.id;
    this.setState(newState, () => this.setTemplateNameFieldErrors(targetId, this.state.templateName.value));
  }

  handleTemplateNameBlur(event) {
    this.setTemplateNameFieldErrors(event.target.id, event.target.value);
  }

  setTemplateNameFieldErrors(id, value) {
    const newState = { ...this.state };
    this.getFieldErrors(get(newState, id), value);
    this.setState(newState);
  }

  isRepresentingCustomer() {
    return includes(
      compact([
        get(this.editOrder, 'customer.representedById')
      ]),
      get(this.props, 'currentUser.companyId')
    );
  }

  onFieldChange = event => {
    const newState = { ...this.state };
    var value = event.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    set(newState.fields, `${event.target.id}.value`, value);
    this.setState(newState);
  };

  async getAvailableTonnageForCustomer(siteId, plannedGradeId, season, ngrId) {
    let companyTypeId = get(this.state.fields.customer.company, 'typeId');
    const isCurrentUserCustomer = isCurrentUserBelongsToCompany(this.state.fields.customer.companyId.value)
    const isCurrentUserBelongsToPickupSite = isCurrentUserBelongsToCompany(get(this.state, 'selectedConsignor.companyId'))
    const isStocksManagementOnForConsignor = get(this.state.selectedConsignor, 'stocksManagement')
    if (isStocksManagementOnForConsignor && (isCurrentUserBelongsToPickupSite || isCurrentUserCustomer) && this.state.fields.commodityId.value && plannedGradeId && season && ngrId && siteId && this.state.fields.typeId.value === REQUEST_ORDER_TYPE.PICKUP_ORDER) {
      let queryString = `?commodityId=${this.state.fields.commodityId.value}&gradeId=${plannedGradeId}&siteId=${siteId}&season=${season}`
      if (companyTypeId === GROWER) {
        queryString += `&type=outload&customerTypeId=${companyTypeId}`;
      }
      let response = await APIService.ngrs(ngrId).appendToUrl('available-stock/')
                                    .appendToUrl(queryString)
                                    .get();
      let tonnage = response.totalTonnage.toFixed(2);
      this.setState({customerTonnageAvailable: tonnage});
      this.setTonnageWarning(this.state.fields.plannedTonnage.value, tonnage);
    }
    else {
      const newState = {...this.state};
      newState.customerTonnageAvailable = null;
      this.setTonnageWarning(this.state.fields.plannedTonnage.value, null);
      this.setState(newState);
    }
  }

  setTonnageWarning(tonnage, availableTonnage) {
    if (this.state.fields.typeId.value === REQUEST_ORDER_TYPE.PICKUP_ORDER) {
      const newState = {...this.state};
      let isTonnageExceeding = parseFloat(availableTonnage) < parseFloat(tonnage)
      if (this.editOrder)
        isTonnageExceeding = parseFloat(availableTonnage) < (parseFloat(tonnage) - parseFloat(get(this.editOrder, 'progressTonnage', 0)))
      if (tonnage && !isNull(availableTonnage) && isTonnageExceeding) {
        newState.fields.plannedTonnage.errors[0] = `Warning: exceeds stored ${this.tonnageLabel.toLowerCase()}. Available ${this.tonnageLabel} = ${availableTonnage} ${this.priceUnit()}`;
      }
      else {
        newState.fields.plannedTonnage.errors = [];
      }
      this.setState(newState);
    }
  }

  handleSubmitClick = event => {
    event.preventDefault();
    if (this.state.customerTonnageAvailable){
      let isTonnageExceeding = parseFloat(this.state.customerTonnageAvailable) < parseFloat(this.state.fields.plannedTonnage.value);
      if (this.editOrder)
        isTonnageExceeding = parseFloat(this.state.customerTonnageAvailable) < (parseFloat(this.state.fields.plannedTonnage.value) - parseFloat(get(this.editOrder, 'progressTonnage', 0)))
      if (this.state.fields.typeId.value === REQUEST_ORDER_TYPE.PICKUP_ORDER && isTonnageExceeding)
        this.setState({openWarningDialog: true});
      else
        this.handleSubmit(event);
    }
    else
      this.handleSubmit(event);
  }

  handleCloseDialog = event => {
    event.persist();
    this.setState({openWarningDialog: false}, () => this.handleSubmit(event));
  }

  siteCompanyCurrentUser() {
    return this.isPickupRequestOrder() ?
    isEqual(get(this.editOrder, 'freightPickup.consignor.handler.companyId'), get(this.props, 'currentUser.companyId')) :
    isEqual(get(this.editOrder, 'freightDelivery.consignee.handler.companyId'), get(this.props, 'currentUser.companyId'))
  }

  UserSiteCustomerOrIsStaff() {
    return (
      isStaff() ||
      this.siteCompanyCurrentUser() ||
      isEqual(get(this.editOrder, 'customer.companyId'), get(this.props, 'currentUser.companyId'))
    )
  }

  allowAmendBasedOnPermissionAndStatus(isSiteCompanyCurrentUser) {
    if(isSiteCompanyCurrentUser)
      return ((!includes(this.getPermissionSettings(), 'grain_owner_and_site_acceptance_from_site') && includes(['open', 'confirmed'], get(this.editOrder, 'status'))) ||
      (includes(this.getPermissionSettings(), 'grain_owner_and_site_acceptance_from_site') && includes(['open', 'confirmed', 'planned'], get(this.editOrder, 'status'))))
    else return !isSiteCompanyCurrentUser
  }

  canAmendSite() {
    const orderOrMovementAgainstRequestOrder = this.isPickupRequestOrder() ? (get(this.editOrder, 'hasPlannedOrdersAgainstPickupOrder') || get(this.editOrder, 'hasPlannedMovementsAgainstPickupOrder')) : (get(this.editOrder, 'hasPlannedOrdersAgainstDeliveryOrder') || get(this.editOrder, 'hasPlannedMovementsAgainstDeliveryOrder'))
    return (this.allowAmendBasedOnPermissionAndStatus() && !orderOrMovementAgainstRequestOrder)
  }

  canAmendStorage(isSiteCompanyCurrentUser) {
    const orderOrMovementAgainstRequestOrder = this.isPickupRequestOrder() ? (get(this.editOrder, 'hasPlannedOrdersAgainstPickupOrder') || get(this.editOrder, 'hasPlannedMovementsAgainstPickupOrder')) : (get(this.editOrder, 'hasPlannedOrdersAgainstDeliveryOrder') || get(this.editOrder, 'hasPlannedMovementsAgainstDeliveryOrder'))
    const isUserCreator = isEqual(get(this.editOrder, 'createdById'), currentUser().id)
    return (this.allowAmendBasedOnPermissionAndStatus(isSiteCompanyCurrentUser) && ((isSiteCompanyCurrentUser || isUserCreator) && !orderOrMovementAgainstRequestOrder))
  }

  unsetCanClearSiteOption() {
    this.setState({canClearSiteOptions: false})
  }

  render() {
    const {disableSite, disableStorage} = this.state
    const siteCompanyId = this.isPickupRequestOrder() ? get(this.editOrder, 'freightPickup.consignor.handler.companyId') : get(this.editOrder, 'freightDelivery.consignee.handler.companyId')
    const userCompanyId = currentUserCompany().id
    const consigneesOfSelectedSiteCompany = this.state.consignees.filter(consignee => compact([userCompanyId, siteCompanyId]).includes(consignee.companyId))
    const consignorsOfSelectedSiteCompany = this.state.consignors.filter(consignor => compact([userCompanyId, siteCompanyId]).includes(consignor.companyId))
    const isAcceptanceRequired = this.isAcceptanceRequired();
    const isAcceptanceDisabled = isAcceptanceRequired || this.isAcceptanceDisabled();
    const entity = this.getOrderEntity();
    const isPickupOrder = this.state.fields.typeId.value === REQUEST_ORDER_TYPE.PICKUP_ORDER;
    const isDeliveryOrder = this.state.fields.typeId.value === REQUEST_ORDER_TYPE.DELIVERY_ORDER;
    const priceUnit = this.priceUnit()
    const isPickupWarehouseInvoiced= get(this.editOrder, 'isValidWarehouseInvoiceExists.0');
    const isDeliveryWarehouseInvoiced = get(this.editOrder, 'isValidWarehouseInvoiceExists.1');
    let requestedTonnage = this.state.fields.plannedTonnage.value;
    if (this.editOrder)
      requestedTonnage = parseFloat(this.state.fields.plannedTonnage.value) - parseFloat(get(this.editOrder, 'progressTonnage') || 0)
    const buttons = (
      <div className='cardForm-action col-md-12 top-15'>
        <CommonButton label='Cancel' default={true} variant='outlined' onClick={this.handleCancelButtonClick} />
        {
          !this.isEditForm &&
          <Tooltip title='Save template to quickly make orders' placement='top'>
            <span>
              <CommonButton label='Save Template' secondary onClick={this.handleSaveButtonClick} variant="contained" />
            </span>
          </Tooltip>
        }
        <CommonButton type='submit' label='Continue and Review' primary variant="contained" />
      </div>
    );
    return (
      <React.Fragment>
        <div ref={this.formRef}>
          <form onSubmit={this.handleSubmitClick} noValidate>
            <div className='cardForm'>
              {this.state.isPopulated && <div className='non-existent-component hide'></div>}
              {this.removeLoader && <div className='dom-for-allocation-loader hide'></div>}
              <h4 className='cardForm-title'>General</h4>
              <div className='cardForm-content'>
                <div className='col-md-5'>
                  {
                    !this.isEditForm &&
                      <QuickOrder
                        id="quickOrder"
                        onChange={this.handleQuickOrderChange}
                        service={this.getTemplatesService}
                        placeholder="Quick Order (Type to search...)"
                        style={{marginBottom: '25px', marginTop: '14px'}}
                      />
                  }
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonSelect
                      style={{ float: 'left' }}
                      id='typeId'
                      setRef={this.fieldRef['typeId']}
                      onChange={this.handleOrderTypeChange}
                      floatingLabelText={this.disableForm ? 'Select Type to proceed' : `${entity} Order Type`}
                      errorText={this.state.fields.typeId.errors[0]}
                      selectConfig={{ text: 'name', value: 'id' }}
                      items={[{id: 4, name: 'Pickup Order'}, {id: 5, name: 'Delivery Order'}]}
                      value={this.state.fields.typeId.value}
                      disabled={this.state.queryParamExists || this.isEditForm}
                    />
                  </div>
                  {(!this.isEditForm || (this.isEditForm && !get(this.editOrder, 'externalArgs'))) ? (
                    <div className='col-md-12 padding-reset form-wrap'>
                      <TextField
                        id='identifier'
                        inputRef={this.fieldRef['identifier']}
                        error={!isEmpty(this.state.fields.identifier.errors[0])}
                        placeholder='Please enter 14 digit unique number'
                        label='Order No'
                        value={this.state.fields.identifier.value}
                        helperText={this.state.fields.identifier.errors[0]}
                        onChange={this.handleIdentifierChange}
                        onBlur={this.handleBlur}
                        fullWidth
                        inputProps={{
                          maxLength: 14,
                        }}
                        variant='standard'
                      />
                    </div>
                  ) : (
                    <div className='col-md-12 padding-reset form-wrap'>
                      <CommonTextField id='identifier' label='Order No' value={this.state.fields.identifier.value} disabled />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='cardForm'>
              <h4 className='cardForm-title'>Customer</h4>
              <div className='cardForm-content'>
                <div className='col-md-5'>
                  <div className='col-md-12 form-wrap padding-reset'>
                    {
                      (this.isEditForm && get(this.state.fields.customer, 'company.displayName')) ? (
                        <CommonTextField
                          id="customer.companyId"
                          label="Customer Name"
                          value={get(this.state.fields.customer, 'company.displayName')}
                          disabled
                        />
                      ) :
                      <CommonAutoSelect
                        id="customer.companyId"
                        items={isEmpty(this.state.customerItems) ? this.getCustomers() : this.state.customerItems}
                        setRef={this.fieldRef["customer.companyId"]}
                        label="Customer Name"
                        value={this.state.fields.customer.companyId.value}
                        errorText={this.state.fields.customer.companyId.errors[0]}
                        style={{ float: 'left' }}
                        onChange={this.handleCustomerChange}
                        disabled={this.isEditForm}
                        dontAutoselectSingleItem
                        selectedItem={this.state.selectedCustomer}
                      />
                    }
                  </div>
                  <div className='col-md-12 form-wrap padding-reset'>
                    <CommonAutoSelect
                      items={uniqBy(this.state.customerContacts, 'id')}
                      setRef={this.fieldRef['customer.contactId']}
                      id='customer.contactId'
                      value={this.state.fields.customer.contactId.value}
                      style={{ float: 'left' }}
                      floatingLabelText='Customer Contact'
                      label='Customer Contact'
                      errorText={this.state.fields.customer.contactId.errors[0]}
                      onChange={this.handleSelectFieldChange}
                    />
                    {this.addCustomerContactButton()}
                  </div>
                  <div className='col-md-12 form-wrap padding-reset'>
                    <CommonAutoSelect
                      items={this.state.customerNgrs}
                      setRef={this.fieldRef['customer.ngrId']}
                      dataSourceConfig={{ text: 'ngrNumber', value: 'id' }}
                      id='customer.ngrId'
                      label='Customer NGR'
                      value={this.state.fields.customer.ngrId.value}
                      errorText={this.state.fields.customer.ngrId.errors[0]}
                      style={{ float: 'left' }}
                      onChange={this.handleSelectFieldChange}
                    />
                    {this.addCustomerNgrButton()}
                  </div>
                </div>
              </div>
            </div>
            <div className='cardForm'>
              <h4 className='cardForm-title'>{`${entity} Order Details`}</h4>
              <div className='cardForm-content'>
                <div className='col-md-5'>
                  <div className='col-md-12 form-wrap padding-reset'>
                    {this.isEditForm ? (
                      <CommonTextField
                        id='commodityId'
                        label='Commodity'
                        style={{ float: 'left' }}
                        disabled={true}
                        value={get(this, 'editOrder.commodity.displayName')}
                      />
                    ) : (
                      <CommodityAutoComplete
                        id='commodityId'
                        setRef={this.fieldRef['commodityId']}
                        onChange={this.handleCommodityChange}
                        floatingLabelText='Commodity'
                        commodityId={this.state.fields.commodityId.value}
                        style={{ float: 'left' }}
                        errorText={this.state.fields.commodityId.errors[0]}
                      />
                    )}
                  </div>
                  <div className='col-md-12 form-wrap padding-reset'>
                    <VarietyAutoComplete
                      id='varietyId'
                      setRef={this.fieldRef['varietyId']}
                      label='Variety (Optional)'
                      commodityId={this.state.fields.commodityId.value}
                      varietyId={this.state.fields.varietyId.value}
                      dependsOnCommodity
                      onChange={this.handleVarietyChange}
                      style={{ float: 'left' }}
                      errorText={this.state.fields.varietyId.errors[0]}
                      onBlur={this.handleBlur}
                    />
                  </div>
                  <div className='col-md-12 form-wrap padding-reset'>
                    <GradeAutoComplete
                      id="plannedGradeId"
                      setRef={this.fieldRef["plannedGradeId"]}
                      style={{float: 'left'}}
                      commodityId={this.state.fields.commodityId.value}
                      varietyId={this.state.fields.varietyId.value}
                      varietyWarning={msg => this.setFieldCustomErrors('varietyId', msg)}
                      gradeId={this.state.fields.plannedGradeId.value}
                      selectedGradeId={this.state.fields.plannedGradeId.value}
                      season={this.state.fields.season.value}
                      dependsOnCommodity
                      dependsOnSeason
                      onChange={this.handleGradeChange}
                      errorText={this.state.fields.plannedGradeId.errors[0]}
                      onBlur={this.handleBlur}
                    />
                  </div>
                  <div className='col-md-12 form-wrap padding-reset'>
                    <SeasonSelect
                      id='season'
                      setRef={this.fieldRef['season']}
                      onChange={this.handleSelectFieldChange}
                      season={this.state.fields.season.value}
                      style={{ float: 'left' }}
                      errorText={this.state.fields.season.errors[0]}
                    />
                  </div>
                </div>
                <div className="col-md-5 form-wrap padding-reset col-md-offset-1">
                  {
                    this.hasQuantityBasedCommodity() ?
                      <React.Fragment>
                        <div className="col-md-12">
                          <NumberField
                            id="quantity"
                            label={this.quantityLabel()}
                            setRef={this.fieldRef["quantity"]}
                            value={get(this.editOrder, 'quantity') || this.state.fields.quantity.value}
                            maxVal={99999.99}
                            helperText={this.state.fields.quantity.errors[0]}
                            onChange={this.handleQuantityFieldChange}
                            onBlur={this.handleFieldChange}
                            InputProps={{
                              endAdornment: <InputAdornment position="end" style={{color: 'rgb(162,162,162)'}}>{this.quantityUnit()}</InputAdornment>
                            }}
                          />
                        </div>
                        <div className="col-md-12 form-wrap" style={{paddingTop: '39px'}}>
                          <NumberField
                            id="plannedTonnage"
                            label={this.tonnageLabel}
                            setRef={this.fieldRef["plannedTonnage"]}
                            value={this.state.fields.plannedTonnage.value}
                            maxValue={999999999.99}
                            helperText={this.state.fields.plannedTonnage.errors[0]}
                            onChange={this.handleTonnageFieldChange}
                            onBlur={this.handleTonnageFieldChange}
                            InputProps={{
                              endAdornment: <InputAdornment position="end" style={{color: 'rgb(162,162,162)'}}>{MT_UNIT}</InputAdornment>
                            }}
                          />
                        </div>
                      </React.Fragment> :
                    <div className="col-md-12">
                      <CommonTextField
                        id="plannedTonnage"
                        label={this.tonnageLabel}
                        setRef={this.fieldRef["plannedTonnage"]}
                        value={this.state.fields.plannedTonnage.value}
                        maxValue={999999999.99}
                        helperText={this.state.fields.plannedTonnage.errors[0]}
                        onChange={this.handleTonnageFieldChange}
                        onBlur={this.handleTonnageFieldChange}
                        InputProps={{
                          endAdornment: <InputAdornment position="end" style={{color: 'rgb(162,162,162)'}}>{priceUnit}</InputAdornment>
                        }}
                      />
                    </div>
                  }
                  <div className="col-md-12 form-wrap" style={{paddingTop: '39px'}}>
                    <TransportModeSelect
                      id='transportMode'
                      setRef={this.fieldRef['transportMode']}
                      onChange={this.handleSelectFieldChange}
                      season={this.state.fields.transportMode.value}
                      errorText={this.state.fields.transportMode.errors[0]}
                    />
                  </div>
                  <div className="col-md-12 form-wrap" style={{paddingTop: '39px'}}>
                    <CommonSelect
                      id='toleranceId'
                      setRef={this.fieldRef['toleranceId']}
                      onChange={this.handleSelectFieldChange}
                      value={this.state.fields.toleranceId.value}
                      floatingLabelText='Tolerance'
                      errorText={get(this.state, 'fields.toleranceId.errors[0]', '')}
                      items={this.state.tolerances}
                      style={{ float: 'left' }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {
              (isPickupOrder || isDeliveryOrder) &&
              <div className='row'>
                {
                  isPickupOrder &&
                  <div className='col-md-6'>
                    <div className='cardForm'>
                      <h4 className='cardForm-title'>Pickup Details</h4>
                      <div className='cardForm-content'>
                        <div className='col-md-12 form-wrap'>
                          <CommonDatePicker
                            id='freightPickup.date'
                            setRef={this.fieldRef['freightPickup.date']}
                            floatingLabelText='Start Date'
                            onChange={this.handleSelectFieldChange}
                            errorText={this.state.fields.freightPickup.date.errors[0]}
                            value={this.state.fields.freightPickup.date.value}
                            style={{ float: 'left' }}
                            maxDate={this.pickUpDateMax()}
                          />
                        </div>
                        <div className='col-md-12 form-wrap'>
                          <CommonDatePicker
                            id='freightDelivery.date'
                            setRef={this.fieldRef['freightDelivery.date']}
                            floatingLabelText='End Date'
                            onChange={this.handleSelectFieldChange}
                            errorText={this.state.fields.freightDelivery.date.errors[0]}
                            value={this.state.fields.freightDelivery.date.value}
                            style={{ float: 'right' }}
                            minDate={this.deliveryDateMin()}
                          />
                        </div>
                        <div className='col-md-12 form-wrap' ref={this.fieldRef['freightPickup.consignor.handlerId']}>
                          {this.isEditForm ?
                          <Autocomplete
                            id="freightPickup.consignor.handlerId"
                            blurOnSelect
                            options={consignorsOfSelectedSiteCompany}
                            disabled={disableSite}
                            popupIcon={disableSite ? <i className="icon-lock-inline"></i> : undefined}
                            filterOptions={(options, params) => {
                              const filtered = autocompleteFilters(options, params);
                              return filtered;
                            }}
                            getOptionLabel={option => {
                                if (typeof option === 'string') {
                                  return option;
                                }
                                if (option.inputValue) {
                                  return option.name;
                                }
                                return option.name;
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="standard"
                                        label='Pickup Site'
                                        fullWidth
                                        helperText={this.state.fields.freightPickup.consignor.handlerId.errors[0]}
                                        error={this.state.fields.freightPickup.consignor.handlerId.errors[0]}
                                        inputProps={{
                                          ...params.inputProps,
                                          className: params.inputProps.className + ' black-text'
                                        }}
                              />
                            )}
                            classes={{inputRoot: this.props.classes.inputRoot}}
                            value={get(this.state, 'selectedConsignor', '')}
                            onInputChange={this.handleConsignorInputChange}
                            onChange={this.handleConsignorChange}
                          /> :
                          <SiteAsyncAutocomplete
                            limitTags={2}
                            label="Pickup Site"
                            id="freightPickup.consignor.handlerId"
                            onChange={item => this.handleConsignorChange(null, item)}
                            selected={get(this.state, 'selectedConsignor', '')}
                            minLength={3}
                            variant="standard"
                            classes={{inputRoot: this.props.classes.inputRoot}}
                            addSiteOption
                            addLabel
                            disabled={disableSite}
                            popupIcon={disableSite ? <i className="icon-lock-inline"></i> : undefined}
                            fullWidth
                            activeSitesOnly
                            errorText={this.state.fields.freightPickup.consignor.handlerId.errors[0]}
                            isAddNewSiteOptionEnabled={false}
                            fetchOnlySitesOfCompany={this.state.fields.customer.companyId.value !== currentUserCompany().id}
                            canClearSiteOptions={this.state.canClearSiteOptions}
                            unsetClearSiteOption={this.unsetCanClearSiteOption}
                          />
                         }
                        </div>
                        <div className="col-md-12 form-wrap">
                          <CommonAutoSelect
                            items={this.state.consignorSites}
                            id="freightPickup.consignor.site.locationId"
                            setRef={this.fieldRef["freightPickup.consignor.site.locationId"]}
                            label="Pickup Storage (Optional)"
                            value={this.state.fields.freightPickup.consignor.site.locationId.value}
                            errorText={this.state.fields.freightPickup.consignor.site.locationId.errors[0]}
                            style={{ float: 'right' }}
                            onChange={this.handleSiteChange}
                            selectedItem={this.state.selectedConsignorSite}
                            dontAutoselectSingleItem
                            top={this.manualComplete}
                            disabled={disableStorage}
                          />
                        </div>
                        {this.state.throughputOutloadCheckbox &&
                        <div className='col-md-12'>
                          <FormControlLabel
                            control={
                              <Checkbox
                                id='throughputOutloadOrder'
                                color="primary"
                                checked={this.state.throughputOutloadOrder}
                                onChange={this.handleThroughputOrderChange}
                                disabled={isPickupWarehouseInvoiced}
                              />
                            }
                            label="Is Throughput Order"
                          />
                        </div>
                        }
                      </div>
                    </div>
                  </div>
                }
                {
                  isDeliveryOrder &&
                  <div className='col-md-6'>
                    <div className='cardForm'>
                      <h4 className='cardForm-title'>Delivery Details</h4>
                      <div className='cardForm-content'>
                        <div className='col-md-12 form-wrap'>
                          <CommonDatePicker
                            id='freightPickup.date'
                            setRef={this.fieldRef['freightPickup.date']}
                            floatingLabelText='Start Date'
                            onChange={this.handleSelectFieldChange}
                            errorText={this.state.fields.freightPickup.date.errors[0]}
                            value={this.state.fields.freightPickup.date.value}
                            style={{ float: 'left' }}
                            maxDate={this.pickUpDateMax()}
                          />
                        </div>
                        <div className='col-md-12 form-wrap'>
                          <CommonDatePicker
                            id='freightDelivery.date'
                            setRef={this.fieldRef['freightDelivery.date']}
                            floatingLabelText='Delivery End Date'
                            onChange={this.handleSelectFieldChange}
                            errorText={this.state.fields.freightDelivery.date.errors[0]}
                            value={this.state.fields.freightDelivery.date.value}
                            style={{ float: 'right' }}
                            minDate={this.deliveryDateMin()}
                          />
                        </div>
                        <div className='col-md-12 form-wrap' ref={this.fieldRef['freightDelivery.consignee.handlerId']}>
                        { this.isEditForm ?
                          <Autocomplete
                            id="freightDelivery.consignee.handlerId"
                            blurOnSelect
                            options={consigneesOfSelectedSiteCompany}
                            getOptionLabel={option => {
                                if (typeof option === 'string') {
                                  return option;
                                }
                                if (option.inputValue) {
                                  return option.name;
                                }
                                return option.name;
                            }}
                            filterOptions={(options, params) => {
                              const filtered = autocompleteFilters(options, params);
                              return filtered;
                            }}
                            disabled={disableSite}
                            popupIcon={disableSite ? <i className="icon-lock-inline"></i> : undefined}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="standard"
                                        label='Delivery Site'
                                        fullWidth
                                        error={this.state.fields.freightDelivery.consignee.handlerId.errors[0]}
                                        helperText={this.state.fields.freightDelivery.consignee.handlerId.errors[0]}
                                        inputProps={{
                                          ...params.inputProps,
                                          className: params.inputProps.className + ' black-text'
                                        }}
                              />
                            )}
                            classes={{inputRoot: this.props.classes.inputRoot}}
                            value={get(this.state, 'selectedConsignee', '')}
                            onInputChange={this.handleConsigneeInputChange}
                            onChange={this.handleConsigneeChange}
                          /> :
                          <SiteAsyncAutocomplete
                            limitTags={2}
                            label="Delivery Site"
                            id="freightDelivery.consignee.handlerId"
                            onChange={item => this.handleConsigneeChange(null, item)}
                            selected={get(this.state, 'selectedConsignee', '')}
                            minLength={3}
                            variant="standard"
                            classes={{inputRoot: this.props.classes.inputRoot}}
                            addSiteOption
                            addLabel
                            disabled={disableSite}
                            popupIcon={disableSite ? <i className="icon-lock-inline"></i> : undefined}
                            fullWidth
                            activeSitesOnly
                            errorText={this.state.fields.freightDelivery.consignee.handlerId.errors[0]}
                            isAddNewSiteOptionEnabled={false}
                            fetchOnlySitesOfCompany={this.state.fields.customer.companyId.value !== currentUserCompany().id}
                            canClearSiteOptions={this.state.canClearSiteOptions}
                            unsetClearSiteOption={this.unsetCanClearSiteOption}
                          />
                        }
                        </div>
                        <div className="col-md-12 form-wrap">
                          <CommonAutoSelect
                            items={this.state.consigneeSites}
                            id="freightDelivery.consignee.site.locationId"
                            setRef={this.fieldRef["freightDelivery.consignee.site.locationId"]}
                            label="Delivery Storage (Optional)"
                            value={this.state.fields.freightDelivery.consignee.site.locationId.value}
                            errorText={this.state.fields.freightDelivery.consignee.site.locationId.errors[0]}
                            style={{ float: 'right' }}
                            onChange={this.handleSiteChange}
                            selectedItem={this.state.selectedConsigneeSite}
                            dontAutoselectSingleItem
                            top={this.manualComplete}
                            disabled={disableStorage}
                          />
                        </div>
                        {this.state.throughputInloadCheckbox &&
                        <div className='col-md-12'>
                          <FormControlLabel
                            control={
                              <Checkbox
                                id='throughputInloadOrder'
                                color="primary"
                                checked={this.state.throughputInloadOrder}
                                onChange={this.handleThroughputOrderChange}
                                disabled={isDeliveryWarehouseInvoiced}
                              />
                            }
                            label="Is Throughput Order"
                          />
                        </div>}
                      </div>
                    </div>
                  </div>
                }
              </div>
            }
            <div className='cardForm'>
              <h4 className='cardForm-title'>Order Conditions</h4>
              <div className='cardForm-content col-md-12'>
                <div className='col-md-5 form-wrap padding-reset'>
                  <CommonAutoSelect
                    items={this.props.generalConditionsAll}
                    setRef={this.fieldRef['generalConditionsSelector']}
                    style={{ float: 'left' }}
                    id='generalConditionsSelector'
                    value={this.state.generalConditionsSelector}
                    label='General'
                    onChange={this.handleConditionSelector}
                    dontAutoselectSingleItem
                  />
                  {this.addGeneralConditionButton()}
                  <CommonTextField
                    id='generalConditionViewer'
                    setRef={this.fieldRef['generalConditionViewer']}
                    value={this.state.selectedGeneralConditionText}
                    multiline={true}
                    rows={2}
                    disabled={true}
                    rowsMax={2}
                  />
                </div>
                <div className='cardForm-content col-md-1 arrow-button'>
                  <IconButton
                    disabled={!isNumber(this.state.generalConditionsSelector)}
                    onClick={this.appendGeneralConditions}
                    size="large">
                    <ArrowForward color={PRIMARY_COLOR_GREEN} />
                  </IconButton>
                </div>
                <div className='cardForm-content col-md-5'>
                  <div className='field-label'>Conditions (Optional)</div>
                  <CommonTextField
                    id='generalConditions'
                    setRef={this.fieldRef['generalConditions']}
                    className='text-area'
                    label=''
                    value={this.state.fields.generalConditions.value}
                    multiline={true}
                    rows={6}
                    rowsMax={6}
                    onBlur={e => this.appendCustomCondition(e, 'generalConditions')}
                  />
                </div>
              </div>
              <div className='cardForm-content col-md-12'>
                <div className='col-md-5 form-wrap padding-reset'>
                  <CommonAutoSelect
                    items={this.props.specialConditionsAll}
                    setRef={this.fieldRef['specialConditionsSelector']}
                    id='specialConditionsSelector'
                    value={this.state.specialConditionsSelector}
                    label='Special'
                    onChange={this.handleConditionSelector}
                    dontAutoselectSingleItem
                  />
                  {this.addSpecialConditionButton()}
                  <CommonTextField
                    id='specialConditionViewer'
                    setRef={this.fieldRef['specialConditionViewer']}
                    value={this.state.selectedSpecialConditionText}
                    multiline={true}
                    rows={2}
                    disabled={true}
                    rowsMax={2}
                    onBlur={this.handleBlur}
                  />
                </div>
                <div className='cardForm-content col-md-1 arrow-button'>
                  <IconButton
                    disabled={!isNumber(this.state.specialConditionsSelector) || this.isEditForm}
                    onClick={this.appendSpecialConditions}
                    size="large">
                    <ArrowForward color={PRIMARY_COLOR_GREEN} />
                  </IconButton>
                </div>
                <div className='cardForm-content col-md-5'>
                  <div className='field-label'>Conditions (Optional)</div>
                  <CommonTextField
                    id='specialConditions'
                    setRef={this.fieldRef['specialConditions']}
                    className='text-area'
                    label=''
                    value={this.state.fields.specialConditions.value}
                    multiline={true}
                    rows={6}
                    rowsMax={6}
                    onBlur={e => this.appendCustomCondition(e, 'specialConditions')}
                  />
                </div>
              </div>
            </div>
            <div className='cardForm'>
              <h4 className='cardForm-title'>Internal Notes (Optional)</h4>
              <div className='cardForm-content col-md-5'>
                <Notes
                  title=''
                  handler={this.noteHandler}
                  note={this.state.fields.note}
                  disabled={false}
                />
              </div>
            </div>
            <div style={{ textAlign: 'right' }}>{buttons}</div>
          </form>
          <Dialog open={this.state.preview} onClose={this.handleOpenPreview} scroll='paper' fullScreen>
            <DialogTitle>{`${entity} Order Preview`}</DialogTitle>
            <DialogContent>
              <RequestOrderPreview isPickupOrder={isPickupOrder} header={`${entity} Order`} entity={entity} previewFields={this.getPreviewFields()} ref={el => (this.componentRef = el)} />
            </DialogContent>
            <DialogActions style={{paddingBottom: "40px"}}>
              <CommonButton label='Back' key='closeButton' default onClick={this.handleClosePreview} variant='flat' />
              <CommonButton label='Submit' key='submitButton' primary={true} onClick={this.handleSubmit} variant='flat' />
            </DialogActions>
          </Dialog>
          <Dialog open={this.state.templateDialog} onClose={this.handleTemplateDialogClose} scroll='paper'>
            <DialogTitle>Order Template</DialogTitle>
            <DialogContent>
              <TextField
                id='templateName'
                label='Template Name'
                placeholder='Please enter'
                value={this.state.templateName.value || undefined}
                fullWidth
                error={!isEmpty(this.state.templateName.errors[0])}
                helperText={this.state.templateName.errors[0]}
                onChange={this.handleTemplateNameChange}
                maxLength='200'
                onBlur={this.handleTemplateNameBlur}
                variant="standard" />
            </DialogContent>
            <DialogActions>
              <CommonButton variant='flat' label='Cancel' key='closeButton' default onClick={this.handleTemplateDialogClose} />
              <CommonButton variant='flat' label='Save' key='saveButton' primary={true} onClick={this.saveTemplate} />
            </DialogActions>
          </Dialog>
          {
            this.state.consignorSideDrawer &&
            <SideDrawer
              isOpen={ this.state.consignorSideDrawer }
              title="Add Site"
              onClose={() => this.handleFarmSideDrawer("consignorSideDrawer", false)}
              app="condition"
              >
              <CreateCompanySite
                closeDrawer={() => this.handleFarmSideDrawer("consignorSideDrawer", false)}
                canAccessAny={true}
                companyId={getCountrySystemCompanyId()}
                handleFarmSubmit={(data) => this.handleFarmSubmit("consignorSideDrawer" ,data)}
                inputText={this.state.inputTextFarm}/>
            </SideDrawer>
          }
          {
            this.state.consigneeSideDrawer &&
            <SideDrawer
              isOpen={ this.state.consigneeSideDrawer }
              title="Add Site"
              onClose={() => this.handleFarmSideDrawer("consigneeSideDrawer", false)}
              app="condition"
              >
              <CreateCompanySite
                closeDrawer={() => this.handleFarmSideDrawer("consigneeSideDrawer", false)}
                canAccessAny={true}
                companyId={getCountrySystemCompanyId()}
                handleFarmSubmit={(data) => this.handleFarmSubmit("consigneeSideDrawer", data)}
                inputText={this.state.inputTextFarm}/>
            </SideDrawer>
          }
          {this.customerSideDrawer()}
          {
            this.props.isConditionSideDrawerOpened &&
            <SideDrawer
              isOpen={ this.state.generalConditionSideDrawerIsOpen }
              title="Add General Condition"
              onClose={ this.closeGeneralConditionSideDrawer }
              app="condition"
              >
              <CreateGeneralCondition
                selectedConditionType='freight'
                closeDrawer={this.closeGeneralConditionSideDrawer}
                onSuccess={this.setDefaultGeneralCondition}
              />
            </SideDrawer>
          }
          {
            this.props.isConditionSideDrawerOpened &&
            <SideDrawer
              isOpen={this.state.specialConditionSideDrawerIsOpen}
              title="Add Special Condition"
              onClose={this.closeSpecialConditionSideDrawer}
              app="condition"
              >
              <CreateSpecialCondition
                selectedConditionType='freight'
                closeDrawer={this.closeSpecialConditionSideDrawer}
                onSuccess={this.setDefaultSpecialCondition}
              />
            </SideDrawer>
          }
          {
            this.state.ngrEntity && this.props.isCreateNgrFormDisplayed &&
            <SideDrawer
              isOpen={this.state.ngrSideDrawerIsOpen}
              title="Add NGR"
              size="big"
              onClose={this.closeNgrSideDrawer}
              >
              <CreateCompanyNgr companyId={get(this.state, 'ngrEntity.id') || get(this.state, 'ngrEntity.company.id')} company={this.state.ngrEntity} canAccessAny={true} closeDrawer={this.closeNgrSideDrawer} onSuccess={this.getCustomerNgrs} bankAccountDetailsMandatory={false}/>
            </SideDrawer>
          }
          {
            this.state.showEmailDialog &&
            <CustomEmailDialog
              parties={this.state.emailPopupParties}
              selectedParties={this.getSelectedParties()}
              title="Email PDF copies to"
              partyEmails={this.getPartyEmails()}
              partyContacts={this.getPartyContacts()}
              subject={this.getEmailSubject()}
              noBody={true}
              footer={this.getFooterNote()}
              open={this.state.showEmailDialog}
              onClose={this.closeEmailDialog}
              isScheduled={!!this.sendButtonText}
              okText={this.sendButtonText}
              disableAcceptanceRequired={isAcceptanceDisabled}
              forceSetAcceptanceRequired
              isAcceptanceRequired={isAcceptanceRequired}
            />
          }
        </div>
        {this.state.openWarningDialog &&
        <div>
          <Dialog open onClose={() => this.setState({openWarningDialog: false})} scroll='paper'>
            <DialogTitle>Warning</DialogTitle>
            <DialogContent>
              <span>
                {`Pickup ${this.tonnageLabel} exceeds the Available Stocks ${this.tonnageLabel}`}<br></br>
                {`Available ${this.tonnageLabel} = ${this.state.customerTonnageAvailable} ${priceUnit}`}<br></br>
                {`Requested ${this.tonnageLabel} = ${requestedTonnage} ${priceUnit}`}<br></br>
              </span>
            </DialogContent>
            <DialogActions>
              <CommonButton label='Cancel' key='closeButton' default onClick={() => this.setState({openWarningDialog: false})} variant='flat' />
              <CommonButton label='Continue' key='submitButton' primary={true} onClick={this.handleCloseDialog} variant='flat' />
            </DialogActions>
          </Dialog>
        </div>
        }
      </React.Fragment>
    );
}
}

const mapDispatchToProps = dispatch => {
  return {
    handleAddEmployeeButtonClick: type => {
      if (type === 'farm') {
        dispatch(clickAddFarmEmployeeButton());
      } else {
        dispatch(clickAddEmployeeButton());
      }
    },
    handleAddCompanyNgrButtonClick: () => dispatch(clickAddCompanyNgrButton()),
    handleAddNgrButtonClick: () => dispatch(clickAddNgrButton()),
    dispatch
  };
};

const mapStateToProps = state => {
  var types = state.companies.orders.types;

  const userTrucksCount = state.companies.companies.company.trucks.userTrucks ? state.companies.companies.company.trucks.userTrucks.length : 0;
  const commodities = state.master.commodities.items || [];
  const varieties = state.master.varieties.items || [];
  let tolerances = [];
  if (!isEmpty(state.companies.orders.tolerances))
    tolerances = map(state.companies.orders.tolerances, partialRight(pick, ['id', 'name']));
  return {
    userTrucksCount,
    types,
    tolerances,
    serverErrors: state.companies.orders.serverErrors,
    currentUser: state.main.user.user,
    generalConditionsAll: state.companies.conditions.generalConditions,
    specialConditionsAll: state.companies.conditions.specialConditions,
    grades: state.master.grades.items || [],
    allDirectoryCompanies: state.companies.companies.companyParties,
    providers: state.companies.companies.companyParties,
    providerEmployees: state.companySettings.employees.items,
    contracts: state.companies.contracts.items,
    selectedOrder: state.companies.orders.selectedOrder,
    userCompanyId: state.main.user.user.companyId,
    userToken: state.main.user.token,
    commodities,
    varieties,
    isCreateNgrFormDisplayed: state.companies.companyNgrs.isCreateFormDisplayed,
    isCreateFormDisplayed: state.companies.ngrs.isCreateFormDisplayed,
    isConditionSideDrawerOpened: state.companies.conditions.isCreateFormDisplayed,
    isEmployeeSideDrawerOpened: state.companySettings.employees.isCreateFormDisplayed || state.companies.farmEmployees.isCreateFormDisplayed,
    isLoading: state.main.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RequestOrderForm));
