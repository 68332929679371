// eslint-disable-next-line no-unused-vars
import React from 'react';
import axios from 'axios';
import alertifyjs from 'alertifyjs';
import { apiURL, forceStopLoader, isLoading } from '../main';
import has from 'lodash/has';
import get from 'lodash/get';
import APIService from '../../services/APIService';
import isEmpty from "lodash/isEmpty";
import isArray from "lodash/isArray";
import { getFreshdeskURL, openURLInNewTab, addPageCacheQueryParamsToUrl, currentUserCompany } from "../../common/utils";
import packageJson from '../../../package.json';

const VERSION = packageJson?.version
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICE_PREVIEW_SUCCESS = 'GET_INVOICE_PREVIEW_SUCCESS';
export const ADD_INVOICE_FAILURE = 'ADD_INVOICE_FAILURE';
export const INVOICE_CREATE_SUCCESS = 'INVOICE_CREATE_SUCCESS';
export const GENERATE_INVOICE = 'Invoice generated!';
export const GET_PAGINATED_INVOICES_SUCCESS = 'GET_PAGINATED_INVOICES_SUCCESS';
export const INVOICE_PAYMENT_REJECT_DIALOG = 'INVOICE_PAYMENT_REJECT_DIALOG';
export const INVOICE_PAYMENT_VOID_DIALOG = 'INVOICE_PAYMENT_VOID_DIALOG';
export const GET_INVOICE_DETAILS = 'GET_INVOICE_DETAILS';
export const ADD_INVOICE_PAYMENT = 'Payment added successfully.';
export const GET_INVOICE_PAYMENTS_SUCCESS = 'GET_INVOICE_PAYMENTS_SUCCESS';
export const INVOICE_PAYMENT_CONFIRMED = 'Payment is confirmed.';
export const INVOICE_PAYMENT_REJECTED = 'Payment is rejected.';
export const GET_INVOICE_PAYMENT_SUMMARY = 'GET_INVOICE_PAYMENT_SUMMARY';

export const INVOICE_CONFIRMATION_SUCCESS = 'INVOICE_CONFIRMATION_SUCCESS';
export const INVOICE_REJECTION_SUCCESS = 'INVOICE_REJECTION_SUCCESS';
export const SET_SELECTED_INVOICE_ID = 'SET_SELECTED_INVOICE_ID';
export const UNSET_SELECTED_INVOICE_ID = 'UNSET_SELECTED_INVOICE_ID';
export const CLEAR_INVOICE = 'CLEAR_INVOICE';
export const TRIGGER_PDF_DOWNLOADING = 'TRIGGER_PDF_DOWNLOADING';
export const VOID_REASON_DIALOG = 'VOID_REASON_DIALOG';
export const VOID_AND_DUPLICATE_REASON_DIALOG = 'VOID_AND_DUPLICATE_REASON_DIALOG';
export const REMOVE_FROM_PAYMENT_RUN_DIALOG = 'REMOVE_FROM_PAYMENT_RUN_DIALOG';
export const CLICKED_OPTION = 'CLICKED_OPTION';
export const CAN_MARK_PAID = 'CAN_MARK_PAID';
export const AMEND_INVOICE = 'Invoice amended!';
export const PAYMENT_RUN_ITEMS = 'PAYMENT_RUN_ITEMS';
export const SET_SELECTED_PAYMENT_RUN = 'SET_SELECTED_PAYMENT_RUN';
export const CAN_DOWNLOAD_ABA_FOR_SELECTED_PAYMENT_RUN = 'CAN_DOWNLOAD_ABA_FOR_SELECTED_PAYMENT_RUN';
export const SET_SELECTED_INVOICE_PAYMENT = "SET_SELECTED_INVOICE_PAYMENT";
export const PAYMENT_RUN_VOID_DIALOG = "PAYMENT_RUN_VOID_DIALOG";
export const SELECTED_PAYMENT_RUN_ID = "SELECTED_PAYMENT_RUN_ID";


export const canMarkInvoicePaid = flag => ({
  type: CAN_MARK_PAID,
  flag
});
export const invoiceResponse = items => ({
  type: GET_INVOICES_SUCCESS,
  items
});
export const getPaginatedInvoicesResponse = paginatedInvoices => {
  return ({ type: GET_PAGINATED_INVOICES_SUCCESS, paginatedInvoices });
};

export const receiveInvoicePreview = invoicePreview => ({
  type: GET_INVOICE_PREVIEW_SUCCESS,
  invoicePreview,
});

export const invoiceDetails = item => ({
  type: GET_INVOICE_DETAILS,
  item,
});

export const invoicePaymentSummary = item => ({
  type: GET_INVOICE_PAYMENT_SUMMARY,
  item,
});

export const addInvoiceFailure = (errors) => {
  return ({ type: ADD_INVOICE_FAILURE, errors });
};

export const invoiceSuccessResponse = invoice => {
  return ({ type: INVOICE_CREATE_SUCCESS, invoice });
};

export const invoicePaymentsResponse = items => ({
  type: GET_INVOICE_PAYMENTS_SUCCESS,
  items
});

export const invoicePaymentRejectDialog = (item, showHide) => ({
  type: INVOICE_PAYMENT_REJECT_DIALOG,
  item,
  showHide,
});

export const invoicePaymentVoidDialog = showHide => ({
  type: INVOICE_PAYMENT_VOID_DIALOG,
  showHide,
});

export const voidReasonDialog = (item, flag) => ({
  type: VOID_REASON_DIALOG,
  item,
  flag,
});

export const voidAndDuplicateReasonDialog = (item, flag) => ({
  type: VOID_AND_DUPLICATE_REASON_DIALOG,
  item,
  flag
})

export const removeFromPaymentRunDialog = (itemType, flag) => ({
  type: REMOVE_FROM_PAYMENT_RUN_DIALOG,
  itemType,
  flag
});

export const setClickedOption = option => ({
  type: CLICKED_OPTION,
  option,
});

export const invoiceConfirmationResponse = invoice => {
  return ({ type: INVOICE_CONFIRMATION_SUCCESS, invoice });
};

export const invoiceRejectionResponse = invoice => {
  return ({ type: INVOICE_REJECTION_SUCCESS, invoice });
};

export const setSelectedInvoiceId = invoiceId => {
  return ({ type: SET_SELECTED_INVOICE_ID, invoiceId });
};

export const clearInvoice = invoiceId => {
  return ({ type: CLEAR_INVOICE, invoiceId });
};

export const unsetSelectedInvoice = invoiceId => {
  return ({ type: UNSET_SELECTED_INVOICE_ID, invoiceId });
};

export const triggerPdfDownloading = flag => {
  return ({ type: TRIGGER_PDF_DOWNLOADING, flag });
};

export const setPaymentRuns = paymentRuns => {
  return ({type: PAYMENT_RUN_ITEMS, paymentRuns})
}

export const setSelectedPaymentRun = paymentRun => {
  return ({ type: SET_SELECTED_PAYMENT_RUN, paymentRun });
};

export const canDownloadABAForSelectedPaymentRunId = flag => {
  return ({ type: CAN_DOWNLOAD_ABA_FOR_SELECTED_PAYMENT_RUN, flag });
}

export const setSelectedInvoicePayment = item => {
  return ({ type: SET_SELECTED_INVOICE_PAYMENT, item });
}

export const paymentRunVoidDialog = showHide => ({
  type: PAYMENT_RUN_VOID_DIALOG,
  showHide,
});

export const setSelectedPaymentRunId = paymentRunId => ({
  type: SELECTED_PAYMENT_RUN_ID,
  paymentRunId,
})


export const createInvoice = (data, freightInvoice=false, warehouseInvoice=false, successCallback=null ) => (dispatch, getState) => {
  const token = getState().main.user.token;
  dispatch(addInvoiceFailure(undefined));
  fetch(`${apiURL}/invoices/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${token}`,
    },
  }).then(response => response.json())
    .then((json) => {
      if (has(json, 'errors')) {
        if (has(json, 'errors.identifier')) {
          dispatch(forceStopLoader());
          dispatch(addInvoiceFailure(json.errors));
        } else {
          alertifyjs.error('An Error Occurred!');
        }
      } else {
        dispatch(invoiceSuccessResponse(json));
        dispatch(getInvoicePreview(json.id, false, get(json, 'isFreightInvoice', false) || freightInvoice, warehouseInvoice));
        if(successCallback)
          successCallback(json)
      }
    });
};

export const createAmendInvoice = (data, freightInvoice=false, warehouseInvoice=false, successCallback ) => (dispatch, getState) => {
  const token = getState().main.user.token;
  dispatch(addInvoiceFailure(undefined));
  fetch(`${apiURL}/invoices/amend/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${token}`,
    },
  }).then(response => response.json())
    .then((json) => {
      if (has(json, 'errors')) {
        if (has(json, 'errors.identifier')) {
          dispatch(forceStopLoader());
          dispatch(addInvoiceFailure(json.errors));
        } else {
          alertifyjs.error('An Error Occurred!');
        }
      } else {
        dispatch(invoiceSuccessResponse(json));
        dispatch(getInvoicePreview(json.id, false, get(json, 'isFreightInvoice', false) || freightInvoice, warehouseInvoice));
        if(successCallback)
          successCallback(json)
      }
    });
};

export const editInvoice = (data, invoiceId, contractId) => (dispatch, getState) => {
  const token = getState().main.user.token;
  dispatch(addInvoiceFailure(undefined));
  fetch(`${apiURL}/invoices/${invoiceId}/`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${token}`,
    },
  }).then(response => response.json())
    .then((json) => {
      if (has(json, 'errors')) {
        let error = get(json.errors, 0)
        alertifyjs.error(error || 'An Error Occurred!', 10);
      }
      else {
        if (contractId)
          window.location = '#/contracts/' + contractId + '/invoices';
        else
          window.location = '#/invoices/' + invoiceId + '/details';
        alertifyjs.success(AMEND_INVOICE, 2, () => { });
      }
    });
};

export const canCreateFreightInvoiceForOrder = (orderId) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.freights().orders(orderId).appendToUrl('can-create-freight-invoice/').get(token).then(
    res => {
      if (res.result) {
        document.location.hash = `#/freights/invoices/new?orderId=${orderId}`;
      } else if (!isEmpty(res.reasons)) {
        const reasons = '<li>' + res.reasons.join('</li><li>');
        let content = `<div className="">
                          <p>Freight Invoice cannot be created against this Freight Order because of the following reason(s):</p>
                          <ul>${reasons}</ul>
                          <div>
                              Please follow <a href=${getFreshdeskURL()} target='_blank'>FAQs</a> for more details
                          </div>
                        </div>`
        if (res.showAmend) {
          alertifyjs.confirm(
            'Permission Denied',
            content,
            () => document.location.hash = `#/freights/orders/${orderId}/edit`,
            () => { },
          ).set('labels', { ok: 'Amend Order', cancel: 'Cancel' });
        }
        else {
          alertifyjs.alert(
            'Permission Denied',
            content,
            () => { },
          );
        }
      }
    });
};

export const getInvoices = (contractId, unit, _forceStopLoader) => {
  return (dispatch, getState) => {
    const user = getState().main.user;
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };
    if(unit)
      config.headers['REFERER-UNIT'] = unit

    const url = contractId ? `${apiURL}/invoices/?contract_id=${contractId}` : `${apiURL}/invoices/`;
    axios.get(url, config)
      .then((response) => {
        dispatch(invoiceResponse(response.data));
        if (_forceStopLoader)
          dispatch(forceStopLoader());
      })
      .catch((error) => {
        throw (error);
      });
  };
};

export const getInvoicesWeb = (contractId, url, queryString, _forceStopLoader, reset, unit, orderId) => {
  return (dispatch, getState) => {
    if(reset)
      dispatch(getPaginatedInvoicesResponse({}))
    const user = getState().main.user;
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      }
    };
    if(unit)
      config.headers['REFERER-UNIT'] = unit
    let URL = url
    if(!url){
      if(contractId)
        URL = `${apiURL}/invoices/web?contract_id=${contractId}`
      else if(orderId)
        URL = `${apiURL}/invoices/web?order_id=${orderId}`
      else
        URL = `${apiURL}/invoices/web/`
    }
    else {
      if(contractId)
        URL = `${url}&contract_id=${contractId}`
      else if(orderId)
        URL = `${url}&order_id=${orderId}`
    }
    if (queryString)
      URL += queryString;

    URL = addPageCacheQueryParamsToUrl(URL)

    axios.get(URL, config)
      .then((response) => {
          dispatch(getPaginatedInvoicesResponse(response.data));
        if (_forceStopLoader)
          dispatch(forceStopLoader());
      })
      .catch((error) => {
        throw (error);
      });
  };
};

export const getFreightInvoices = (orderId) => {
  return (dispatch, getState) => {
    const user = getState().main.user;
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };
    const url = orderId ? `${apiURL}/invoices/?order_id=${orderId}` : `${apiURL}/invoices/`;
    axios.get(url, config)
      .then((response) => {
        dispatch(invoiceResponse(response.data));
      })
      .catch((error) => {
        throw (error);
      });
  };
};

export const getInvoicePreview = (invoiceId, openInNewTab = false, isFreightInvoice = false, isWarehouseInvoice = false) => {
  return (dispatch, getState) => {
    const user = getState().main.user;
    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Token ${user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };
    const url = isFreightInvoice ? `${apiURL}/invoices/${invoiceId}/freight-order-preview/` : isWarehouseInvoice ? `${apiURL}/invoices/${invoiceId}/warehouse-preview/`: `${apiURL}/invoices/${invoiceId}/preview/`;
    axios.get(url, config)
      .then((response) => {
        if (openInNewTab) {
          dispatch(forceStopLoader());
          var preview = window.open("about:blank", "_blank");
          preview.document.write(response.data);
          preview.document.close();
        } else {
          dispatch(forceStopLoader());
          dispatch(receiveInvoicePreview({ invoiceId: invoiceId, previewHTML: response.data }));
        }
      })
      .catch((error) => {
        throw (error);
      });
  };
};

export const deleteInvoice = (invoiceId) => (dispatch, getState) => {
  const user = getState().main.user;
  const config = {
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${user.token}`,
      'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'WEB-VERSION': VERSION,
      'REFERER-COUNTRY': localStorage.current_country
    },
  };
  const url = `${apiURL}/invoices/${invoiceId}/`;
  axios.delete(url, config)
    .then(() => {
    })
    .catch((error) => {
      throw (error);
    });
};

export const generateInvoice = (invoiceId, contractId, orderId = '', data = {}, callback) => (dispatch, getState) => {
  const url = `${apiURL}/invoices/${invoiceId}/generate/`;
  const token = getState().main.user.token;
  dispatch(isLoading('generateInvoice'))
  fetch(`${url}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${token}`,
    },
  }).then(response => response.json())
    .then((json) => {
      dispatch(forceStopLoader())
      if (has(json, 'errors')) {
        const errors = get(json, 'errors') || 'An Error Occurred!'
        alertifyjs.error(errors, 5);
      } else {
        if (contractId) {
          window.location = '#/contracts/' + contractId + '/invoices';
        } else if (orderId) {
          window.location = '#/freights/orders/' + orderId + '/freights/invoices';
        } else if (callback) {
          callback()
        } else {
          window.location = '#/invoices';
        }
        alertifyjs.success(GENERATE_INVOICE, 2, () => { });
      }
    });

};

export const getInvoiceDetails = invoiceId => {
  return (dispatch, getState) => {
    dispatch(invoiceDetails(null));
    APIService.invoices(invoiceId).get(getState().main.user.token, {'original-unit': true})
      .then(response => {
        dispatch(invoiceDetails(response));
      })
      .catch(error => {
        throw (error);
      });
  };
};

export const getInvoicePaymentSummary = invoiceId => {
  return (dispatch, getState) => {
    APIService.invoices(invoiceId).appendToUrl('payment-summary/').get(getState().main.user.token)
      .then(response => {
        dispatch(invoicePaymentSummary(response));
        dispatch(invoiceConfirmationResponse(response));
      })
      .catch(error => {
        throw (error);
      });
  };
};

export const getInvoicePayments = (invoiceId, url, _forceStopLoader = false) => (dispatch, getState) => {
  dispatch(invoicePaymentsResponse([]));
  const token = getState().main.user.token;
  const config = {
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${token}`,
      'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'WEB-VERSION': VERSION,
      'REFERER-COUNTRY': localStorage.current_country
    },
  };

  let _url = url;
  if (!_url && invoiceId)
    _url = APIService.invoices(invoiceId).appendToUrl('invoice-payments/').URL;

  axios.get(_url, config)
    .then(response => {
      dispatch(invoicePaymentsResponse(response.data));
      dispatch(invoiceConfirmationResponse(response.data));
      if (_forceStopLoader) {
        dispatch(forceStopLoader());
      }
    })
    .catch(error => {
      dispatch(invoicePaymentsResponse(error.response ? error.response.data : error.message));
    });
};

export const confirmInvoice = (invoiceId, reload = true) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.invoices(invoiceId)['confirm']().put({}, token)
    .then((json) => {
      if (!isEmpty(json.errors)) {
        this.setState({ errors: json.errors[0] });
      } else {
        alertifyjs.success('Invoice is accepted', 2, () => {
          dispatch(invoiceConfirmationResponse(json));
          if (reload) window.location.reload();
        });
      }
    });
};

export const rejectInvoice = (invoiceId, data, reload = true) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.invoices(invoiceId)['reject']().put(data, token)
    .then((json) => {
      if (!isEmpty(json.errors)) {
        this.setState({ errors: json.errors[0] });
      } else {
        alertifyjs.success('Invoice is rejected', 2, () => {
          dispatch(invoiceRejectionResponse(json));
          if (reload) window.location.reload();
        });
      }
    });
};

export const voidAcceptInvoice = (invoiceId, reload = true) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.invoices(invoiceId).appendToUrl('void/confirm/').put({}, token)
    .then((json) => {
      if (!isEmpty(json.errors)) {
        this.setState({ errors: json.errors[0] });
      } else {
        alertifyjs.success('Invoice is voided', 2, () => {
          dispatch(invoiceConfirmationResponse(json));
          if (reload) window.location.reload();
        });
      }
    });
};

export const voidRejectInvoice = (invoiceId, data, reload = true) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.invoices(invoiceId).appendToUrl('void/reject/').put(data, token)
    .then((json) => {
      if (!isEmpty(json.errors)) {
        this.setState({ errors: json.errors[0] });
      } else {
        alertifyjs.success('void request is rejected', 2, () => {
          dispatch(invoiceRejectionResponse(json));
          if (reload) window.location.reload();
        });
      }
    });
};

export const canAddPayment = invoiceId => (dispatch, getState) => {
  const token = getState().main.user.token;
  const invoice = APIService.invoices(invoiceId);
  invoice.appendToUrl(`action-options/?type=can-add-payment`);
  invoice.get(token).then(res => {
    let reasons = get(res[0], 'cannotAddPaymentReasons', undefined);
    if (!isEmpty(reasons)) {
      reasons = '<li>' + reasons.join('</li><li>');
      alertifyjs.alert(
        'Permission Denied',
        `<div>
            <p>Payment cannot be added because of the following reasons: </p>
            <ul>${reasons}</ul>
          </div>`,
        () => { }
      );
    } else {
      dispatch(setSelectedInvoiceId(invoiceId));
      dispatch(isLoading('invoiceAddPaymentForm'));
    }
  });
};

export const canMarkPaid = invoiceId => (dispatch, getState) => {
  const token = getState().main.user.token;
  const invoice = APIService.invoices(invoiceId);
  invoice.appendToUrl(`action-options/?type=can-mark-paid`);
  invoice.get(token).then(res => {
    if (!get(res, '0.canMarkPaid')) {
        alertifyjs.alert(
          'Permission Denied',
          `<div>
              <p>You cannot mark this invoice as paid because of one of the following reasons:</p>
                <ul>
                  <li>Payee is registered on the system.</li>
                  <li>Invoice is either in paid/void status.</li>
                </ul>
          </div>`,
          () => { }
        );
    } else {
      dispatch(setSelectedInvoiceId(invoiceId));
      dispatch(canMarkInvoicePaid(true));
    }
  });
};

export const canAmendInvoice = (invoiceId, invoiceType) => (dispatch, getState) => {
  const token = getState().main.user.token;
  const invoice = APIService.invoices(invoiceId);
  invoice.appendToUrl(`action-options/?type=can-amend`);
  invoice.get(token).then(res => {
    let reasons = get(res[0], 'cannotAmendReasons', undefined);
    let contractId = get(res[0], 'raisedForId', undefined);
    if (!isEmpty(reasons)) {
      reasons = '<li>' + reasons.join('</li><li>');
      alertifyjs.alert(
        'Permission Denied',
        `<div>
              <p>You cannot amend this invoice because of one of the following reasons:</p>
              <ul>${reasons}</ul>
          </div>`,
        () => { }
      );
    } else if (isArray(reasons) && isEmpty(reasons)) {
      dispatch(setSelectedInvoiceId(invoiceId));
      dispatch(getInvoiceDetails(invoiceId));
      if (invoiceType.includes("Freight"))
        window.location = `#/invoices/freights/${invoiceId}/edit`;
      else if (invoiceType.includes("Warehouse"))
        window.location = `#/invoices/warehouse/${invoiceId}/edit`;
      else if (invoiceType.includes("Brokerage"))
        window.location = `#/invoices/brokerage/${invoiceId}/edit`;
      else if (contractId)
        window.location = `#/invoices/${invoiceId}/edit`;

    }
  });
};

export const canVoidInvoice = (invoice, isDuplicated=false) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.invoices(invoice.id).appendToUrl('action-options/?type=can-void')
    .get(token).then(res => {
      let reasons = get(res[0], 'cannotRaiseVoidRequestReasons', undefined);
      if (!isEmpty(reasons)) {
        reasons = '<li>' + reasons.join('</li><li>');
        alertifyjs.alert(
          'Permission Denied',
          `<div><p>You cannot Void this Invoice because:</p><ul>${reasons}</ul></div>`,
          () => { }
        );
      } else if(invoice?.isPartOfValidPaymentRuns) {
        dispatch(removeFromPaymentRunDialog('invoice', true));
        if(isDuplicated){
          dispatch(invoiceDetails(invoice));
          dispatch(voidAndDuplicateReasonDialog(invoice.id, true))
        }
      } else if (isArray(reasons) && isEmpty(reasons)) {
        if(isDuplicated){
          dispatch(invoiceDetails(invoice));
          dispatch(voidAndDuplicateReasonDialog(invoice.id, true))
        }
        else
          dispatch(voidReasonDialog(invoice.id, true));
      }
    });
};

export const handleInvoicesOptionsMapper = (event, item, baseEntity, pdfUrl, mappingFrom) => dispatch => {
  dispatch(setClickedOption(item));
  if(mappingFrom === 'invoiceTable'){
    dispatch(clearInvoice());
    dispatch(setSelectedInvoiceId(null));
  }
  if (item.key === 'view_in_new_tab') {
    const url = `#/invoices/${baseEntity.id}/details`;
    openURLInNewTab(url);
  } else if (item.key === 'add_payment') {
    dispatch(canAddPayment(baseEntity.id));
    if(mappingFrom === 'invoiceTable'){
      dispatch(getInvoiceDetails(baseEntity.id));
    }
  } else if (item.key === 'view_payments') {
    document.location.hash = `#/invoices/${baseEntity.id}/details/payments`;
  } else if (item.key === 'download_pdf') {
    if (pdfUrl) {
      openURLInNewTab(pdfUrl);
    } else {
      dispatch(setSelectedInvoiceId(baseEntity.id));
      dispatch(triggerPdfDownloading(true));
    }
  } else if (item.key === 'void') {
    dispatch(canVoidInvoice(baseEntity));
    if(mappingFrom === 'invoiceTable'){
      dispatch(getInvoiceDetails(baseEntity.id));
    }
  } else if (item.key == 'mark_paid') {
    dispatch(canMarkPaid(baseEntity.id, true));
    if (mappingFrom === 'invoiceTable') {
      dispatch(getInvoiceDetails(baseEntity.id));
    }
  } else if (item.key === 'amend') {
    dispatch(canAmendInvoice(baseEntity.id, baseEntity.type));
  } else if (item.key === 'create_send') {
    dispatch(getInvoiceDetails(baseEntity.id));
  } else if (item.key === 'remove_from_payment_run' && !baseEntity?.paymentRunId)
    dispatch(getInvoiceDetails(baseEntity.id));
  else if(item.key === 'duplicate'){
    if(get(baseEntity, 'sentByCompanyId') != currentUserCompany()?.id)
      alertifyjs.alert(
        'Permission Denied',
        `<div><p>You cannot duplicate this Invoice because: <ul><li>You are not the creator of this invoice.</li></ul></div>`,
        () => { }
      );
    else
      dispatch(redirectToDuplicateInvoice(baseEntity));
  } else if (item.key === 'void_and_duplicate'){
    if(get(baseEntity, 'sentByCompanyId') != currentUserCompany()?.id)
      alertifyjs.alert(
        'Permission Denied',
        `<div><p>You cannot void and duplicate this Invoice because: <ul><li>You are not the creator of this invoice.</li></ul></div>`,
        () => { }
      );
    else
      dispatch(canVoidInvoice(baseEntity, true));
  }
};

export const redirectToDuplicateInvoice = invoice => {
  let queryParams = `copyFrom=${invoice.id}`
  if(invoice.type.includes("Commodity Contract"))
    document.location.hash = `#/invoices/new?${queryParams}`;
  else if(invoice.type.includes("Freight"))
    document.location.hash = `#/freights/invoices/new?${queryParams}`;
  else if(invoice.type.includes("Warehouse"))
    document.location.hash = `#/invoices/warehouse/new?${queryParams}`;
  else if(invoice.type.includes("Brokerage"))
    document.location.hash = `#/invoices/brokerage/new?${queryParams}`;
}

export const markPaymentRunVoid = (paymentRunId, requestReason) => (dispatch, getState) => {
  dispatch(isLoading('void-dialog'));
  const token = getState().main.user.token;
  APIService.invoices()
    .appendToUrl(`payment-run/${paymentRunId}/void/`)
    .put({reason : requestReason}, token)
    .then(() => {
      dispatch(forceStopLoader());
      window.location.reload();
    });
};

export const markPaymentRunPaid = (paymentRunId, sendEmail) => dispatch => {
  dispatch(setClickedOption(null));
  dispatch(isLoading('payment-run-paid'));
  APIService.invoices()
    .appendToUrl(`payment-run/${paymentRunId}/paid/?send_email=${sendEmail}`)
    .put(null)
    .then(() => {
      dispatch(forceStopLoader());
      window.location.reload();
    });
};

export const markPaymentRunUnPaid = paymentRunId => (dispatch) => {
  dispatch(setClickedOption(null));
  dispatch(isLoading('void-dialog'));
  APIService.invoices()
    .appendToUrl(`payment-run/${paymentRunId}/unpaid/`)
    .put()
    .then(res => {
      if (res.result) {
        window.location.reload();
      }
      else {
        const reasons = '<li>' + res.reasons.join('</li><li>');
        alertifyjs.alert(
          'Permission Denied',
          `<div className=""><p>You cannot mark unpaid for this payment run because:</p><ul>${reasons}</ul><div>Please follow <a href=${getFreshdeskURL()} target='_blank'>FAQs</a> for more details</div></div>`,
          () => {},
        );
      }
    });
};

export const canDownloadPaymentRunABA = paymentRunId => dispatch => {
  APIService.invoices()
    .appendToUrl(`payment-run/${paymentRunId}/can-download-aba/`)
    .get()
    .then(res => {
      if (res.result) {
        dispatch(canDownloadABAForSelectedPaymentRunId(true));
      }
      else {
        const reasons = '<li>' + res.reasons.join('</li><li>');
        alertifyjs.alert(
          'Permission Denied',
          `<div className=""><p>You cannot download ABA for this payment run because:</p><ul>${reasons}</ul><div>Please follow <a href=${getFreshdeskURL()} target='_blank'>FAQs</a> for more details</div></div>`,
          () => {},
        );
      }
    });
}

export const downloadPaymentRunABA = paymentRunId => dispatch => {
  dispatch(setClickedOption(null));
  dispatch(canDownloadABAForSelectedPaymentRunId(false));
  APIService.invoices()
    .appendToUrl(`payment-run/${paymentRunId}/aba/`)
    .get()
    .then()
};

export const updateBankProcessingDate = (paymentRunId, bankProcessingDate) => dispatch => {
  dispatch(setClickedOption(null));
  dispatch(isLoading('payment-run'));
  APIService.invoices()
    .appendToUrl(`payment-run/${paymentRunId}/`)
    .put({bankProcessingDate: bankProcessingDate})
    .then(() => {
      dispatch(forceStopLoader());
      window.location.reload();
    })
};

export const handlePaymentRunsOptionsMapper = (event, item, baseEntity) => dispatch => {
  const key = item.key;
  dispatch(setClickedOption(item));
  dispatch(setSelectedPaymentRun(baseEntity));
  if (key === 'view_in_new_tab') {
    openURLInNewTab(`#/invoices/payment-runs/${baseEntity.id}`);
  }
  else if (key === 'download_aba') {
    dispatch(canDownloadPaymentRunABA(baseEntity.id));
  }
};

export const canVoidInvoicePayment = item => dispatch => {
  dispatch(setSelectedInvoicePayment(item));
  APIService.invoices()
    .appendToUrl(`invoice-payments/${item.id}/can-void-payment/`)
    .get()
    .then(res => {
      if (res.result) {
        dispatch(invoicePaymentVoidDialog(true));
      }
      else {
        if (item?.isPartOfValidPaymentRuns && isEmpty(res.reasons))
          dispatch(removeFromPaymentRunDialog('payment', true));
        else {
        const reasons = '<li>' + res.reasons.join('</li><li>');
        alertifyjs.alert(
          'Permission Denied',
          `<div className=""><p>You cannot void this payment because:</p><ul>${reasons}</ul><div>Please follow <a href=${getFreshdeskURL()} target='_blank'>FAQs</a> for more details</div></div>`,
          () => {},
        );
        }
      }
    });
}

export const canMarkPaymentRunVoid = () => (dispatch, getState) => {
  dispatch(setClickedOption(null));
  let paymentRunId = getState().companies.invoices.selectedPaymentRunId;
  if (paymentRunId) {
    dispatch(setSelectedPaymentRunId(null));
    APIService.invoices()
      .appendToUrl(`payment-run/${paymentRunId}/can-void/`)
      .get()
      .then(res => {
        if (res.result) {
          dispatch(paymentRunVoidDialog(true));
        }
        else {
          const reasons = '<li>' + res.reasons.join('</li><li>');
          alertifyjs.alert(
            'Permission Denied',
            `<div className=""><p>You cannot void this payment run because:</p><ul>${reasons}</ul><div>Please follow <a href=${getFreshdeskURL()} target='_blank'>FAQs</a> for more details</div></div>`,
            () => {},
          );
        }
      });
  }
}
