import React from 'react';

import { connect } from 'react-redux';
import GooglePlacesAutoComplete from '../google-places/AutoComplete';
import alertifyjs from 'alertifyjs';
import { get, isEmpty, cloneDeep, set, some, mapValues, isEqual, forEach, pick, isNumber, includes, first, find, filter, trim, map, has, compact } from 'lodash';
import APIService from '../../services/APIService';
import {FIELD, REQUIRED_FIELD, BROKER, BHC_TYPE_ID, GROWER, DEFAULT_LAT, DEFAULT_LNG, DUPLICATE_BUSINESS_NAME_ERROR, AUSTRALIA_COUNTRY_ID} from '../../common/constants';
import {
  required,
  phoneMobileRegex,
  websiteRegex,
  fixLength,
  numRegex,
} from '../../common/validators';
import CommonButton from '../common/CommonButton';
import CommonTextField from '../common/CommonTextField';
import CommonSelect from '../common/select/CommonSelect';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import {getPaymentTerms} from '../../actions/companies/contracts';
import FileUpload from '../common/FileUpload';
import {BROKERAGE_FEE_TYPES, BROKERAGE_FREQUENCIES, CHARGED_AT_PERIODS} from '../contracts/fixtures';
import { positiveDecimalFilter } from '../../common/input-filters';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import {forceStopLoader, isLoading, loaded} from "../../actions/main";
import { transferCompanyAsset } from '../../actions/api/companies';
import { addCompanyNgr } from '../../actions/companies/company-ngrs';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CommonDatePicker from "../common/CommonDatePicker";
import moment from 'moment';
import { isSystemCompany, isTransactionParticipated, getCurrentCountry, isEntityNameDisabled, isSuperuser, isStaff } from '../../common/utils';
const CANNOT_ADD_OWN_COMPANY = 'You can not add your own company to your directory';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import ABNField from '../common/ABNField';
import PhoneField from '../common/PhoneField'
import NGRField from '../common/NGRField';


class NewCompanyFormYetToBeUpdatedToHandleCompanyUpdate extends React.Component {
  constructor(props) {
    super(props);
    const country = getCurrentCountry()
    this.state = {
      country: country,
      showPrimaryBrokerOption: true,
      isPrimaryBroker: false,
      fetchMaster: true,
      existingLogoUrl: null,
      weekdays: [
        {id: 0, displayName: 'Sunday'},
        {id: 1, displayName: 'Monday'},
        {id: 2, displayName: 'Tuesday'},
        {id: 3, displayName: 'Wednesday'},
        {id: 4, displayName: 'Thursday'},
        {id: 5, displayName: 'Friday'},
        {id: 6, displayName: 'Saturday'},
      ],
      fields: {
        abn: set(cloneDeep(REQUIRED_FIELD), 'validators', (country.config?.abn?.optional || isSuperuser()) ? [numRegex(), fixLength(country?.config?.abn?.length)] : [required(), numRegex(), fixLength(country?.config?.abn?.length)]),
        ngrNumber: set(cloneDeep(FIELD), 'validators', [fixLength(8), numRegex()]),
        businessName: cloneDeep(REQUIRED_FIELD),
        typeId: set(cloneDeep(REQUIRED_FIELD), 'validators', [required()]),
        entityName: cloneDeep(REQUIRED_FIELD),
        address: set(cloneDeep(REQUIRED_FIELD), 'validators', [required()]),
        mobile: set(cloneDeep(REQUIRED_FIELD), 'validators', [required(), phoneMobileRegex(null)]),
        website: set(cloneDeep(FIELD), 'validators', [websiteRegex()]),
        startOfWeek: {...cloneDeep(FIELD), value: 1},
        endOfWeek: {...cloneDeep(FIELD), value: 5},
        paymentTermId: set(cloneDeep(REQUIRED_FIELD), 'validators', [required()]),
        logo: {...cloneDeep(FIELD), value: {base64: '', name: ''}},
        brokerages: [{
          feeType: cloneDeep(FIELD),
          rate: cloneDeep(FIELD),
          totalFee: cloneDeep(FIELD),
          frequency: cloneDeep(FIELD),
          chargedAt: cloneDeep(FIELD),
          subscriptionStartDate: cloneDeep(FIELD),
        }],
      },
      companyTypes: [],
      allCompanyTypes: [],
      shouldFieldsBeDisabled: false,
      submitButtonLabel: 'Next',
      abnServerErrors: [],
      ngrServerErrors: [],
      ngrShareHolderCompanies: [],
      isFetchedFromPortal: false,
      transferNgrId: null,
      selectedCompany: null,
      fetchedNgrDetails: null,
      resetAddress: false,
      enableErrorDialog: false,
      existingCompanyDetails: undefined,
      fetchedByNGR: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFieldBlur = this.handleFieldBlur.bind(this);
    this.handleAbnBlur = this.handleAbnBlur.bind(this);
    this.handleNGRNumberBlur = this.handleNGRNumberBlur.bind(this);
    this.handleAddressBlur = this.handleAddressBlur.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleGenericSelectFieldChange = this.handleGenericSelectFieldChange.bind(this);
    this.handlePrimaryBusinessChange = this.handlePrimaryBusinessChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.cancelDrawer = this.cancelDrawer.bind(this);
    this.afterSubmit = this.afterSubmit.bind(this);
    this.handleStartOfWeekChange = this.handleStartOfWeekChange.bind(this);
    this.handleEndOfWeekChange = this.handleEndOfWeekChange.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.handleLogoUpload = this.handleLogoUpload.bind(this);
    this.handleLogoRemove = this.handleLogoRemove.bind(this);
    this.handleBrokerageFieldChange = this.handleBrokerageFieldChange.bind(this);
    this.handleAddExistingCompany = this.handleAddExistingCompany.bind(this);
    this.closeErrorDialog = this.closeErrorDialog.bind(this);
    this.fetchNgrDetails = this.fetchNgrDetails.bind(this);
  }

  componentDidMount(){
    if(this.state.fetchMaster) {
      this.setState({fetchMaster: false}, () => {this.fetchMaster();});
    }
    this.getNgrPortalDetails(this.props.userCompanyId);
  }

  UNSAFE_componentWillReceiveProps(props) {
    const newState = { ...this.state };
    forEach(props.serverErrors, (value, key) => {
        newState.fields[key].errors = value;
    });
    if(! isEmpty(this.state.fields.businessName.value) && includes(get(newState.fields.businessName, 'errors'), DUPLICATE_BUSINESS_NAME_ERROR)) {
      let queryParams = `?business_name=${encodeURIComponent(trim(this.state.fields.businessName.value))}`;

      APIService.companies().appendToUrl(queryParams).get().then(response => {
        this.setState({ enableErrorDialog: true, existingCompanyDetails: first(response) });
      });
    }
    this.setState(newState);
  }

  getNgrPortalDetails(id) {
    let portalDetails = {}
    APIService.companies(id).appendToUrl(`ngrs/credentials/`).get()
    .then(portalCredentials => {
      if(!portalCredentials || isEqual(get(portalCredentials, 'username'), ''))
        portalDetails = { isPortalLinked: false };
      else {
        portalDetails = portalCredentials;
        portalDetails.isPortalLinked = true;
      }
      this.setState({ngrPortalDetails: portalDetails})
    })
  }

  fetchMaster() {
    this.props.dispatch(getPaymentTerms());
    if(isEmpty(this.state.companyTypes)) {
      APIService.companies().types().get(this.props.token).then(types => {
        const companyTypes = filter(types, (type) => { return type.id !== BROKER && type.id !== BHC_TYPE_ID; });
        this.setState(
          {
            allCompanyTypes: types,
            companyTypes: companyTypes,
          }
        );
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.paymentTerms && !this.state.fields.paymentTermId.value) {
      this.setFieldValue('fields.paymentTermId', 10);
    }
    if (!isEqual(prevState.abnServerErrors, this.state.abnServerErrors)) {
      const errors = isEmpty(this.state.abnServerErrors) ?
                     this.state.fields.abn.errors :
                     this.state.abnServerErrors;

      this.setState(state => set(state, 'fields.abn.errors', errors));
    }
    if (!isEqual(prevState.ngrServerErrors, this.state.ngrServerErrors)) {
      const errors = isEmpty(this.state.ngrServerErrors) ? this.state.fields.ngrNumber.errors : this.state.ngrServerErrors;
      this.setState(state => set(state, 'fields.ngrNumber.errors', errors));
    }
    if (isEmpty(this.state.fields.ngrNumber.value) && !isEmpty(this.state.ngrShareHolderCompanies))
      this.setState({ngrShareHolderCompanies: [], ngrServerErrors: [], shouldFieldsBeDisabled: false});
    if (isEmpty(this.state.fields.ngrNumber.value) && isEmpty(this.state.ngrShareHolderCompanies) && isEmpty(this.state.fields.typeId.validators) && isEmpty(this.state.fields.abn.validators)) {
      const newState = { ...this.state };
      newState.shouldFieldsBeDisabled = false;
      newState.isFetchedFromPortal = false;
      newState.fields.abn.validators = [required(), numRegex(), fixLength(this.state.country.config.abn.length)];
      newState.fields.entityName.validators = [required()];
      newState.fields.businessName.validators = [required()];
      newState.fields.typeId.validators = [required()];
      newState.fields.address.validators = [required()];
      newState.fields.mobile.validators = [required(), phoneMobileRegex(null)];
      this.setState(newState);
    }
    if (this.state.resetAddress) {
      this.setState({ resetAddress: false });
    }
  }

  componentWillUnmount() {
    const newState = { ...this.state };
    newState.enableErrorDialog = false;
    newState.existingCompanyDetails = undefined;
    this.setState(newState);
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setAllFieldsErrors();
    if (!isEmpty(this.state.ngrShareHolderCompanies)) {
      /* eslint-disable spellcheck/spell-checker */
      let abns = compact(map(this.state.ngrShareHolderCompanies, detail => detail?.abn))
      let data = this.state.isFetchedFromPortal ? {abns: abns, ngrDetails: this.state.fetchedNgrDetails, ngrNumber: this.state.fields.ngrNumber.value} : {companyIds: compact(map(this.state.ngrShareHolderCompanies, company => company?.id))}
      APIService.companies(this.props.userCompanyId).appendToUrl('add-to-directory/').post(data, this.props.token)
      .then(() => {
        this.props.closeDrawer();
        alertifyjs.success('Companies were added successfully');
      })
     } else if (!this.getIsFormInvalid()) {
      this.props.submit(this.getSubmitData(), this.afterSubmit);
    }
  }

  afterSubmit(companyResponse) {
    this.props.closeDrawer();

    const companySubscribed = get(companyResponse, 'transactionParticipation')
    const restrictAddingNgrToCompany = companySubscribed || (companyResponse?.isRegistered && !companySubscribed && companyResponse?.typeId != GROWER)

    const canCreateNGR = this.state.fields.ngrNumber.value && !this.state.transferNgrId && !restrictAddingNgrToCompany

    if(this.props.skipCompanyRedirection ){
      if(canCreateNGR)
        this.props.addNewCompany(companyResponse, this.state.fetchedByNGR && this.state.fields.ngrNumber.value);
    }
    else {
      this.props.fetchAndSetCompanies();
      window.location = `#/companies/${companyResponse.id}/details`;
    }

    if(this.state.fields.ngrNumber.value && !this.state.transferNgrId && !restrictAddingNgrToCompany)
      this.createNgr(companyResponse?.id, companyResponse)

    if (this.state.transferNgrId && !get(companyResponse, 'transactionParticipation'))
      this.props.dispatch(transferCompanyAsset(companyResponse.id, {asset_id: this.state.transferNgrId, asset: 'ngr', include_parties: false}));
    const newState = { ...this.state };
    newState.enableErrorDialog = false;
    newState.existingCompanyDetails = undefined;
    newState.fields.businessName = cloneDeep(REQUIRED_FIELD);
    this.setState(newState);
  }

  createNgr = (companyId, companyResponse) => {
    let bankAccounts = []
    if (this.state.isFetchedFromPortal) {
      map(this.state.fetchedNgrDetails, detail => {
        bankAccounts.push({
          "accountName": detail?.bankAccountName || "",
          "accountNumber": detail?.bankAccountNumber || "",
          "bsbNumber": detail?.bsbId || "",
          "companyId": "",
          "shareholderPercent": detail?.payeeSplit || 100
        })
      })
    }
    else {
      bankAccounts = [{
        "accountName": "",
        "accountNumber": "",
        "bsbNumber": "",
        "companyId": "",
        "isPrimary": true,
        "shareholderPercent": 100
      }]
    }
    let ngrData = {
      "bankAccounts": bankAccounts,
      "isVerified": Boolean(this.state.isFetchedFromPortal && bankAccounts?.length > 0 && bankAccounts[0].accountNumber),
      "ngrType": "single",
      "ngrNumber": this.state.fields.ngrNumber.value,
      "shareAuthorityFile": [],
      "shareAuthorityUrl": []
    }
    APIService.companies(companyId)
      .ngrs()
      .post(ngrData, this.props.token)
      .then(item => {
        if (item?.id == null)
          this.props.dispatch(forceStopLoader());
        else {
          this.props.dispatch(addCompanyNgr(item));
          alertifyjs.success("NGR was successfully added.");
          this.props.dispatch(loaded());
          this.props.addNewCompany(companyResponse, this.state.fields.ngrNumber.value)
        }
      });
  }
  handleFieldBlur(event) {
    this.setFieldValue(`fields.${event.target.id}`, event.target.value);
  }

  handleAbnBlur(event) {
    this.setFieldValue(`fields.abn`, event.target.value, true, (state) => {
      if (state.fields.abn.value && !this.getIsFieldInvalid(state.fields.abn)) {
        this.resetRelatedStateValues('abn');
        this.getCompanyDataByAbnAndUpdateState();
      }
    });
  }

  handleNGRNumberBlur(event) {
    event.persist()
    this.setFieldValue(`fields.ngrNumber`, event.target.value, true, (state) => {
      if (state.fields.ngrNumber.value) {
        this.resetRelatedStateValues('ngrNumber');
        this.getCompanyDataByNgrAndUpdateState();
      }
    });
  }
  handleAddressBlur() {
    this.setFieldErrors(`fields.address`);
  }

  resetRelatedStateValues(field) {
    const newState = { ...this.state };

    newState.fields.entityName.value = '';
    newState.fields.businessName.value = '';
    newState.fields.mobile.value = '';
    newState.fields.address.value = {};
    newState.selectedCompany = null;
    const serverErrors = field === 'abn' ? newState.abnServerErrors : newState.ngrServerErrors;
    newState[serverErrors] = [];
    newState.ngrShareHolderCompanies = [];
    newState.resetAddress = true;
    newState.fields.typeId.value = '';

    this.setState(newState);
  }

  getCompanyDataByAbnAndUpdateState() {
    this.getCompaniesByAbn(this.state.fields.abn.value).then((items) => {
      this.props.dispatch(forceStopLoader())
      const company = get(items, '[0]', null);
      let {existingLogoUrl} = this.state;
      let fields = { ...this.state.fields };
      let shouldFieldsBeDisabled = false;
      let showPrimaryBrokerOption = false;
      let submitButtonLabel = 'Next';
      let abnServerErrors = [];
      let selectedCompany = null
      if (company) {
        existingLogoUrl = company.logoUrl;
        showPrimaryBrokerOption = false;
        selectedCompany = company
        shouldFieldsBeDisabled = isTransactionParticipated(company) || company.typeId === BHC_TYPE_ID;
        submitButtonLabel = 'Add To Directory';
        if (company.id !== this.props.userCompanyId) {
          Object.keys(fields).forEach((fieldKey) => {
            if(fieldKey != 'ngrNumber') {
            const value = fieldKey !== 'address' ? company[fieldKey] :
                          pick(company[fieldKey], ['address', 'latitude', 'longitude']);

            fields = set(fields, `${fieldKey}.value`, value);
            }
          });
          if (!isEmpty(this.state.fields.ngrNumber.value) && !company?.isRegistered && company?.typeId != GROWER) {
              fields = set(fields, `typeId.value`, GROWER);
              selectedCompany['typeId'] = GROWER
          }
        } else {
          abnServerErrors.push(CANNOT_ADD_OWN_COMPANY);
        }
      } else {
        this.props.dispatch(isLoading('abnEntityName', 100000000));
        this.getCompanyNameByAbn(this.state.fields.abn.value).then((response) => {
          showPrimaryBrokerOption = true;
          if (isEmpty(response.errors)) {
            Object.keys(response).forEach((fieldKey) => {
              if (fieldKey == 'address')
                fields = set(fields, `${fieldKey}.value.address`, response[fieldKey]);
              else
                fields = set(fields, `${fieldKey}.value`, response[fieldKey]);
            });
            if (!isEmpty(this.state.fields.ngrNumber.value))
              fields = set(fields, `typeId.value`, GROWER);
          } else {
            abnServerErrors = get(response, 'errors.abn', abnServerErrors);
          }
          this.props.dispatch(forceStopLoader());
          this.setState(state => ({ ...state, fields, abnServerErrors, showPrimaryBrokerOption }));
        });
      }
      this.setState(state => ({
        ...state,
        existingLogoUrl,
        fields,
        shouldFieldsBeDisabled,
        submitButtonLabel,
        abnServerErrors,
        showPrimaryBrokerOption,
        selectedCompany,
        fetchedByNGR: false
      }));
    });
  }

  getCompanyDataByNgrAndUpdateState(callback=null) {
    this.getCompaniesByNgr(this.state.fields.ngrNumber.value).then(items => {
      let systemNgrId = get(items, 'systemNgrId', null);
      items = systemNgrId ? get(items, 'companies') : items;
      if (isEmpty(items)) {
        this.setState({transferNgrId: systemNgrId}, () => {
          if(callback) callback();
        });
      } else {
        let {existingLogoUrl} = this.state;
        let fields = { ...this.state.fields };
        let shouldFieldsBeDisabled = false;
        let showPrimaryBrokerOption = false;
        let submitButtonLabel = 'Next';
        let ngrServerErrors = [];
        let ngrShareHolderCompanies = [];
        if (items.length == 1) {
          const company = get(items, '[0]', null);
          existingLogoUrl = company.logoUrl;
          showPrimaryBrokerOption = false;
          shouldFieldsBeDisabled = isTransactionParticipated(company) || company.typeId === BHC_TYPE_ID;
          submitButtonLabel = 'Add To Directory';

          if (company.id !== this.props.userCompanyId) {
            Object.keys(fields).forEach((fieldKey) => {
              if(fieldKey != 'ngrNumber') {
                const value = fieldKey !== 'address' ? company[fieldKey] : pick(company[fieldKey], ['address', 'latitude', 'longitude']);
                fields = set(fields, `${fieldKey}.value`, value);
              }
            });
          } else
            ngrServerErrors.push(CANNOT_ADD_OWN_COMPANY);
        } else {
          submitButtonLabel = 'Add To Directory';
          ngrShareHolderCompanies = items;
          const disableFields = ['abn', 'typeId', 'businessName', 'mobile', 'address', 'entityName'];
          map(disableFields, field => {
            fields[field].validators = []
            fields[field].errors = []
          })
        }

        this.setState(state => ({
          ...state,
          existingLogoUrl,
          fields,
          shouldFieldsBeDisabled,
          submitButtonLabel,
          ngrServerErrors,
          showPrimaryBrokerOption,
          ngrShareHolderCompanies,
          fetchedByNGR: true
        }));
      }
    });
  }

  fetchNgrDetails() {
    this.props.dispatch(isLoading('fetchNGRDetails'))
    APIService.companies().appendToUrl(`${get(this.state.ngrPortalDetails, 'companyId')}/ngrs/${this.state.fields.ngrNumber.value}/validate/`)
      .get().then(response => {
      if(has(response, 'error')) {
        this.props.dispatch(forceStopLoader())
        alertifyjs.error(response.error);
        return
      }
        let { ngrDetails } = response;
        if(ngrDetails.length > 1) {
          const newState = { ...this.state };
          let fields = { ...newState.fields };
          newState.ngrShareHolderCompanies = ngrDetails;
          newState.fetchedNgrDetails = ngrDetails
          newState.isFetchedFromPortal = true
          newState.submitButtonLabel = 'Add To Directory';

          const disableFields = ['abn', 'typeId', 'businessName', 'mobile', 'address', 'entityName'];
          map(disableFields, field => {
            fields[field].validators = []
            fields[field].errors = []
          })
          newState.fetchedByNGR = true
          this.setState(newState, () => this.props.dispatch(forceStopLoader()));
      } else {
        const newState = { ...this.state };
        newState.isFetchedFromPortal = true;
        newState.fetchedNgrDetails = ngrDetails
        newState.fields.abn.value = get(ngrDetails[0], 'abn');
        newState.fetchedByNGR = true
        this.setState(newState, () => {
          if (this.state.fields.abn.value)
            this.getCompanyDataByAbnAndUpdateState()
          else
            this.props.dispatch(forceStopLoader())
        });
      }
    })
  }

  handleFieldChange(event) {
    this.setFieldValue(`fields.${event.target.id}`, event.target.value, true);
  }

  handleAddressChange(data) {
    if (data.address) {
      this.setFieldValue('fields.address', {
        address: data.address,
        latitude: data.lat,
        longitude: data.lng,
      }, true);
    }
  }

  handleGenericSelectFieldChange(value, id) {
    this.setFieldValue(`fields.${id}`, value, true);
  }

  handlePrimaryBusinessChange(typeId) {
    if (isNumber(typeId) && this.state.fields.typeId.value != typeId) {
      const newState = {...this.state};
      if(typeId !== GROWER) {
        newState.isPrimaryBroker = false;
      }
      newState.fields.typeId.value = typeId;
      this.setState(newState);
    }
    this.setFieldErrors(`fields.typeId`);
  }

  handleStartOfWeekChange(value) {
    if (isNumber(value) && this.state.fields.startOfWeek.value != value) {
      const newState = {...this.state};
      newState.fields.startOfWeek.value = value;
      if(value == this.state.fields.endOfWeek.value) {
        newState.fields.endOfWeek.errors.push('Cannot be same as Start of Week');
      }
      else {
        newState.fields.endOfWeek.errors = [];
      }
      this.setState(newState);
    }
    this.setFieldErrors(`fields.startOfWeek`);
  }

  handleEndOfWeekChange(value) {
    if (isNumber(value) && this.state.fields.endOfWeek.value != value) {
      const newState = {...this.state};
      newState.fields.endOfWeek.value = value;
      if(this.state.fields.startOfWeek.value == value) {
        newState.fields.endOfWeek.errors.push('Cannot be same as Start of Week');
      }
      else {
        newState.fields.endOfWeek.errors = [];
      }
      this.setState(newState);
    }
  }

  setFieldValue(path, value, validateAfterSet = true, callback) {
    this.setState(
      state => set(state, `${path}.value`, value),
      () => {
        if (validateAfterSet) this.setFieldErrors(path);
        if (callback) callback(this.state);
      }
    );
  }

  setFieldErrors(path) {
    this.setState(state => set(state, `${path}.errors`, this.getFieldErrors(path)));
  }

  getFieldErrors(path) {
    const errors = [];
    const value = get(this.state, `${path}.value`);
    const validators = get(this.state, `${path}.validators`, []);

    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });

    return errors;
  }

  setAllFieldsErrors() {
    let newState = { ...this.state };

    forEach(newState.fields, (field, fieldKey) => {
      const path = `fields.${fieldKey}`;
      newState = set(newState, `${path}.errors`, this.getFieldErrors(path));
    });

    forEach(newState.fields.brokerages[0], (field, fieldKey) => {
      const path = `fields.brokerages[0].${fieldKey}`;
      newState = set(newState, `${path}.errors`, this.getFieldErrors(path));
    });

    this.setState(newState);
  }

  getIsFormInvalid() {
    return some(this.state.fields, (field) => {
      return this.getIsFieldInvalid(field);
    }) || some(this.state.fields.brokerages[0], (field) => {
      return this.getIsFieldInvalid(field);
    }) || !isEmpty(this.state.abnServerErrors);
  }

  getIsFieldInvalid(field) {
    return some(field.validators, (validator) => {
      return validator.isInvalid(field.value);
    });
  }

  handleAddExistingCompany() {
    this.props.submit(this.getRequiredCompanyDetails(this.state.existingCompanyDetails), this.afterSubmit);
  }

  getRequiredCompanyDetails(companyDetails) {
    let submitData = pick(companyDetails, [
      'abn', 'businessName', 'typeId', 'mobile',
      'website', 'startOfWeek', 'endOfWeek', 'paymentTermId',
    ])

    set(submitData, 'address' , pick(companyDetails.address, ['address', 'latitude', 'longitude']));
    return submitData;
  }

  getSubmitData() {
    const submitData = mapValues(this.state.fields, field => field.value);
    submitData.businessName = trim(submitData.businessName)
    submitData.countryId = this.state.country.id
    if(this.state.fields.brokerages[0].feeType.value && (this.state.fields.brokerages[0].rate.value || this.state.fields.brokerages[0].totalFee.value)) {
      submitData.brokerages = [mapValues(this.state.fields.brokerages[0], 'value')];
      submitData.brokerages[0].brokerCompanyId = this.props.user.companyId;
      submitData.brokerages[0].totalFee = submitData.brokerages[0].totalFee ? parseFloat(submitData.brokerages[0].totalFee) : null;
      submitData.brokerages[0].rate = submitData.brokerages[0].rate ? parseFloat(submitData.brokerages[0].rate) : null;
    } else {
      delete submitData.brokerages;
    }

    if(this.state.existingLogoUrl && !this.state.fields.logo.base64) {
      delete submitData.logo;
    }
    delete submitData.ngrNumber;
    if(get(submitData, 'address.address') && !get(submitData, 'address.latitude') && !get(submitData, 'address.longitude')){
      submitData.address.latitude = DEFAULT_LAT;
      submitData.address.longitude = DEFAULT_LNG;
    }
    if(isEntityNameDisabled())
      submitData.entityName;

    return submitData;
  }

  getCompaniesByAbn(abn) {
    return APIService.companies().get(this.props.token, undefined, { abn, properties: 'logo_url' });
  }

  getCompanyNameByAbn(abn) {
    return APIService.abn().get(undefined, undefined, { abn });
  }

  getCompaniesByNgr = ngrNumber => APIService.companies().get(this.props.token, undefined, { 'ngr_number': ngrNumber, properties: 'logo_url' });

  cancelDrawer(){
    this.props.closeDrawer();
  }

  handleLogoUpload(fileState){
    const newState = {...this.state};
    newState.fields.logo.value = fileState.file;
    this.setState(newState);
  }

  handleLogoRemove() {
    const newState = {...this.state};
    newState.fields.logo.value.base64 = null;
    newState.fields.logo.value.name = null;
    newState.existingLogoUrl = null;
    this.setState(newState);
  }

  shouldShowBrokerages() {
    return this.props.user && this.props.user.company.typeId === BROKER;
  }

  handleBrokerageFieldChange(value, id) {
    const newState = {...this.state};

    if (includes(['Fee Per MT', '% of Sale'], value)){
      newState.fields.brokerages[0].rate.validators = [required()];
      newState.fields.brokerages[0].totalFee.validators = [];
      newState.fields.brokerages[0].totalFee.errors = [];
      newState.fields.brokerages[0].totalFee.value = '';
      newState.fields.brokerages[0].frequency.validators = [];
      newState.fields.brokerages[0].frequency.errors = [];
      newState.fields.brokerages[0].frequency.value = '';
      newState.fields.brokerages[0].chargedAt.validators = [];
      newState.fields.brokerages[0].chargedAt.errors = [];
      newState.fields.brokerages[0].chargedAt.value = '';
      newState.fields.brokerages[0].subscriptionStartDate.validators = [];
      newState.fields.brokerages[0].subscriptionStartDate.errors = [];
      newState.fields.brokerages[0].subscriptionStartDate.value = null;
    } else if (value === 'Flat Fee'){
      newState.fields.brokerages[0].totalFee.validators = [required()];
      newState.fields.brokerages[0].frequency.validators = [required()];
      newState.fields.brokerages[0].subscriptionStartDate.validators = [required()];
      newState.fields.brokerages[0].chargedAt.validators = [required()];
      newState.fields.brokerages[0].rate.validators = [];
      newState.fields.brokerages[0].rate.errors = [];
      newState.fields.brokerages[0].rate.value = '';
      newState.fields.brokerages[0].subscriptionStartDate.value = moment().format('YYYY-MM-DD');
    } else {
      newState.fields.brokerages[0].rate.validators = [];
      newState.fields.brokerages[0].rate.errors = [];
      newState.fields.brokerages[0].rate.value = '';
      newState.fields.brokerages[0].totalFee.validators = [];
      newState.fields.brokerages[0].totalFee.errors = [];
      newState.fields.brokerages[0].totalFee.value = '';
      newState.fields.brokerages[0].frequency.validators = [];
      newState.fields.brokerages[0].frequency.errors = [];
      newState.fields.brokerages[0].frequency.value = '';
      newState.fields.brokerages[0].chargedAt.validators = [];
      newState.fields.brokerages[0].chargedAt.errors = [];
      newState.fields.brokerages[0].chargedAt.value = '';
      newState.fields.brokerages[0].subscriptionStartDate.validators = [];
      newState.fields.brokerages[0].subscriptionStartDate.errors = [];
      newState.fields.brokerages[0].subscriptionStartDate.value = null;
    }
    this.setState(newState, () => {this.setFieldValue(`fields.${id}`, value);});
  }

  handleSelectChange = (value, id) => {
    this.setFieldValue(`fields.${id}`, value, true);
  };

  handleSubscriptionDateChange = (value) => {
    this.setFieldValue('fields.brokerages[0].subscriptionStartDate', value);
  };

  handleIsPrimaryBrokerChange = () => event => {
    const newState = {...this.state};
    newState.isPrimaryBroker = event.target.checked;
    this.setState(newState);
  };

  closeErrorDialog = () => {
    this.setState({ enableErrorDialog: !this.state.enableErrorDialog });
  }
  enableFetchNgrDetails = () => !isSystemCompany() && get(this.state.ngrPortalDetails, 'isPortalLinked')

  isFetchNgrDisabled = () => {
    if (this.state.fields.ngrNumber.value)
      return !isEqual(this.state.fields.ngrNumber.value.length, 8)
    return true
  }

  render() {
    const ERROR_STYLE = { textAlign: 'left' };
    const BUTTON_STYLE = { margin: 12 };
    const companyType = find(this.state.allCompanyTypes,{id: this.state.selectedCompany?.typeId});
    const countryCode = localStorage.current_country
    return (
      <div>
        <div className="cardForm cardForm--drawer">
          <div className="cardForm-content row">
          { includes([AUSTRALIA_COUNTRY_ID], this.props.user.company?.countryId) &&
            <div className="col-sm-12 form-wrap-70">
              <NGRField
                label='NGR'
                helperText={this.state.fields.ngrNumber.errors[0]}
                onChange={this.handleFieldChange}
                onBlur={this.handleNGRNumberBlur}
                value={this.state.fields.ngrNumber.value}
                enableFetchNgrDetails={this.enableFetchNgrDetails()}
                isFetchNgrDisabled={this.isFetchNgrDisabled()}
                onNgrFetchNgrClick={this.fetchNgrDetails}
              />
            </div>
            }
            {!isEmpty(this.state.ngrShareHolderCompanies) &&
              <React.Fragment>
              {map(this.state.ngrShareHolderCompanies, (company, index) => (
                <div key={index} className="col-sm-12 form-wrap-70" style={{border: '2px solid grey', margin: '0 15px 10px 15px', padding: '8px',}}>
                  <div>
                    <Typography>Entity Name: {get(company, 'entityName')}</Typography>
                    <Typography>Business Name: {get(company, 'businessName')}</Typography>
                    <Typography>ABN: {get(company, 'abn')}</Typography>
                    <Typography>Primary Business: {get(company, 'type.displayName') || find(this.state.allCompanyTypes, {name: get(company, 'payeeType')})?.displayName}</Typography>
                  </div>
                </div>
              ))}
              </React.Fragment>
            }
            {isEmpty(this.state.ngrShareHolderCompanies) &&
            <React.Fragment>
            <div className="col-sm-12 form-wrap-70">
              <ABNField
                value={this.state.fields.abn.value}
                helperText= {get(this.state.fields.abn, 'errors[0]', '')}
                errorStyle={ERROR_STYLE}
                onBlur={this.handleAbnBlur}
                onChange={this.handleFieldChange}
                disabled={!isEmpty(this.props.selectedCompany)}
                onInput = {(e) =>{
                  e.target.value = e.target.value.toString().slice(0,11);
                }}
                fullWidth
                countryCode={countryCode}
              />
            </div>
            <div className="col-sm-12 form-wrap-70">
              <CommonTextField
                id="entityName"
                label="Entity Name"
                value={this.state.fields.entityName.value}
                disabled={!isSuperuser() && isEntityNameDisabled()}
                onChange={this.handleFieldChange}
                helperText= {get(this.state.fields.entityName, 'errors[0]', '')}
                errorStyle={ERROR_STYLE}
                fullWidth
              />
            </div>
            <div className="col-sm-12 form-wrap-70">
              <CommonTextField
                id="businessName"
                label="Business Name"
                placeholder="Please enter"
                value={this.state.fields.businessName.value}
                helperText= {get(this.state.fields.businessName, 'errors[0]', '')}
                errorStyle={ERROR_STYLE}
                onBlur={this.handleFieldBlur}
                onChange={this.handleFieldChange}
                disabled={this.state.shouldFieldsBeDisabled}
                maxLength="100"
                fullWidth
              />
            </div>
            <div className="col-sm-12 form-wrap-70" style={{padding: '0px 0px 15px 0px'}}>
              {this.state.shouldFieldsBeDisabled && this.state.existingLogoUrl ?
               <label style={{color: 'rgba(0, 0, 0, 0.38)', fontSize: '0.8rem', marginLeft: '14px'}}>Logo</label>
               : null}
              <FileUpload
                id="logo"
                floatingLabelText="Attach File"
                fullWidth={true}
                textFieldstyle={{float: 'left', color: 'black'}}
                buttonStyle={{border: '1px solid'}}
                onChange={this.handleLogoUpload}
                buttonText="Upload Logo"
                allowedExtensions="image/*"
                previewStyle={{marginTop: '10px', width: '150%', marginLeft: '-15px'}}
                onRemove={this.handleLogoRemove}
                fileCount={(this.state.fields.logo.base64 || this.state.existingLogoUrl) ? 2 : 0}
                disabled={this.state.shouldFieldsBeDisabled}
                showImagePreviewFromURL={this.state.existingLogoUrl}
              />
            </div>
            <div className="col-sm-12 form-wrap-70">
              {
                (!isStaff() && companyType) ?
                <CommonTextField
                  id="primaryBusiness"
                  label="Primary Business"
                  value={companyType.displayName}
                  disabled
                />
                :
                <CommonSelect
                  id="primaryBusiness"
                  floatingLabelText="Primary Business"
                  value={this.state.fields.typeId.value}
                  selectedItemId={this.state.fields.typeId.value}
                  errorText= {get(this.state.fields.typeId, 'errors[0]', '')}
                  selectConfig={{text: 'displayName', value: 'id'}}
                  onChange={this.handlePrimaryBusinessChange}
                  disabled={this.state.shouldFieldsBeDisabled}
                  items={isSystemCompany() ? this.state.allCompanyTypes : this.state.companyTypes}
                  errorStyle={ERROR_STYLE}
                />
              }
            </div>
            {
              this.props.user.company.typeId === BROKER && this.state.fields.typeId.value === GROWER && this.state.showPrimaryBrokerOption &&
              <div className="col-sm-12 form-wrap-70">
                <FormControlLabel
                  control={<Checkbox color="primary" value="primaryBroker" checked={this.state.isPrimaryBroker} onChange={this.handleIsPrimaryBrokerChange()} />}
                  label="Are you the primary Broker for this Farm?"
                />
              </div>
            }
            <div className="col-sm-12 form-wrap-70">
              <GooglePlacesAutoComplete
                id="address"
                floatingLabelText="Address"
                hintText="Please select an address from the suggestions"
                value={get(this.state.fields, 'address.value.address')}
                errorText={get(this.state.fields.address, 'errors[0]', '')}
                errorStyle={ERROR_STYLE}
                onBlur={this.handleAddressBlur}
                onChange={this.handleAddressChange}
                disabled={this.state.shouldFieldsBeDisabled}
                resetState={this.state.resetAddress}
                forceSelection
                fullWidth
              />
            </div>
            <div className="col-sm-12 form-wrap-70">
              <PhoneField
                id="mobile"
                label="Phone/Mobile"
                placeholder="Please enter 10 digit phone/mobile number"
                value={this.state.fields.mobile.value}
                helperText={get(this.state.fields.mobile, 'errors[0]', '')}
                errorStyle={ERROR_STYLE}
                onBlur={this.handleFieldBlur}
                onChange={this.handleFieldChange}
                disabled={this.state.shouldFieldsBeDisabled}
                fullWidth
                countryCode={countryCode}
              />
            </div>
            <div className="col-sm-12 form-wrap-70">
              <CommonTextField
                id="website"
                label="Website (Optional)"
                placeholder="Please enter"
                value={this.state.fields.website.value}
                helperText={get(this.state.fields.website, 'errors[0]', '')}
                errorStyle={ERROR_STYLE}
                onBlur={this.handleFieldBlur}
                onChange={this.handleFieldChange}
                disabled={this.state.shouldFieldsBeDisabled}
                fullWidth
              />
            </div>
            <div className="col-sm-12 form-wrap-70">
              <CommonSelect
                id="startOfWeek"
                floatingLabelText="Start of Week"
                value={this.state.fields.startOfWeek.value}
                selectedItemId={this.state.fields.startOfWeek.value}
                errorText= {get(this.state.fields.startOfWeek, 'errors[0]', '')}
                selectConfig={{text: 'displayName', value: 'id'}}
                onChange={this.handleStartOfWeekChange}
                disabled={this.state.shouldFieldsBeDisabled}
                items={this.state.weekdays}
                errorStyle={ERROR_STYLE}
              />
            </div>
            <div className="col-sm-12 form-wrap-70">
              <CommonSelect
                id="endOfWeek"
                floatingLabelText="End of Week"
                value={this.state.fields.endOfWeek.value}
                selectedItemId={this.state.fields.endOfWeek.value}
                errorText= {get(this.state.fields.endOfWeek, 'errors[0]', '')}
                selectConfig={{text: 'displayName', value: 'id'}}
                onChange={this.handleEndOfWeekChange}
                disabled={this.state.shouldFieldsBeDisabled}
                items={this.state.weekdays}
                errorStyle={ERROR_STYLE}
              />
            </div>
            <div className="col-sm-12 form-wrap-70">
              <CommonAutoSelect
                id="paymentTermId"
                onChange={this.handleGenericSelectFieldChange}
                label="Payment Term"
                dataSourceConfig={{text: 'name', value: 'id'}}
                value={this.state.fields.paymentTermId.value}
                errorText={get(this.state, 'fields.paymentTermId.errors[0]', '')}
                items={this.props.paymentTerms || []}
                disabled={this.state.shouldFieldsBeDisabled}
              />
            </div>
            {
              this.shouldShowBrokerages() ?
              <div className="col-sm-12 form-wrap-70">
                <div>
                  <CommonSelect
                    id="brokerages[0].feeType"
                    floatingLabelText="Brokerage Fee Type"
                    selectConfig={{text: 'name', value: 'id'}}
                    items={BROKERAGE_FEE_TYPES}
                    value={this.state.fields.brokerages[0].feeType.value}
                    onChange={this.handleBrokerageFieldChange}
                    style={{width: '100%'}}
                    includeEmptyOption
                  />
                </div>
                {
                  this.state.fields.brokerages[0].feeType.value === '% of Sale' ?
                  <div>
                    <TextField
                      id="brokerages[0].rate"
                      style={{width: '100%'}}
                      label="Rate"
                      placeholder="Rate"
                      helperText={get(this.state.fields.brokerages[0].rate, 'errors', '')}
                      error={this.state.fields.brokerages[0].rate.errors.length > 0}
                      onKeyDown={(event)=>positiveDecimalFilter(event, 2, 100)}
                      value={this.state.fields.brokerages[0].rate.value}
                      onChange={this.handleFieldChange}
                      variant="standard" />
                  </div>
                  : null
                }
                {
                  this.state.fields.brokerages[0].feeType.value === 'Fee Per MT' ?
                  <div>
                    <TextField
                      id="brokerages[0].rate"
                      style={{width: '100%'}}
                      label="Rate"
                      placeholder="Rate"
                      helperText={get(this.state.fields.brokerages[0].rate, 'errors', '')}
                      error={this.state.fields.brokerages[0].rate.errors.length > 0}
                      onKeyDown={(event)=>positiveDecimalFilter(event, 2, 9999)}
                      value={this.state.fields.brokerages[0].rate.value}
                      onChange={this.handleFieldChange}
                      variant="standard" />
                  </div>
                  : null
                }
                {
                  this.state.fields.brokerages[0].feeType.value === 'Flat Fee' ?
                  <div style={{display: "flex", flexDirection: "column", rowGap: "15px"}}>
                    <CommonSelect
                      id="brokerages[0].frequency"
                      floatingLabelText="Frequency"
                      selectConfig={{text: 'name', value: 'id'}}
                      items={BROKERAGE_FREQUENCIES}
                      value={this.state.fields.brokerages[0].frequency.value}
                      errorText={get(this.state.fields.brokerages[0].frequency, 'errors[0]', undefined)}
                      onChange={this.handleSelectChange}
                      style={{width: '100%'}}
                    />
                    <TextField
                      id="brokerages[0].totalFee"
                      style={{width: '100%'}}
                      label="Fee"
                      placeholder="Fee"
                      onKeyDown={(event)=>positiveDecimalFilter(event, 2,99999.99)}
                      value={this.state.fields.brokerages[0].totalFee.value}
                      helperText={get(this.state.fields.brokerages[0].totalFee, 'errors', '')}
                      error={this.state.fields.brokerages[0].totalFee.errors.length > 0}
                      onChange={this.handleFieldChange}
                      InputProps={{
                        startAdornment: <InputAdornment position="start" style={{color: 'rgb(162,162,162)'}}>$</InputAdornment>
                      }}
                      variant="standard" />
                    <CommonSelect
                        id="brokerages[0].chargedAt"
                        floatingLabelText="Charged At"
                        selectConfig={{text: 'name', value: 'id'}}
                        items={CHARGED_AT_PERIODS}
                        value={this.state.fields.brokerages[0].chargedAt.value}
                        errorText={get(this.state.fields.brokerages[0].chargedAt, 'errors[0]', undefined)}
                        onChange={this.handleSelectChange}
                        style={{width: '100%'}}
                    />
                    <CommonDatePicker
                        id="subscriptionStartDate"
                        floatingLabelText="Periodic Brokerage Start Date"
                        errorText={get(this.state.fields.brokerages[0].subscriptionStartDate, 'errors[0]', '')}
                        value={this.state.fields.brokerages[0].subscriptionStartDate.value}
                        onChange={this.handleSubscriptionDateChange}
                    />
                  </div>
                  : null
                }
              </div>
              : null
            }
          </React.Fragment>
          }
          </div>
          <div className="col-sm-12 cardForm-action top15 padding-reset">
            <CommonButton
              type='button'
              label='Cancel'
              default
              variant="outlined"
              onClick={this.cancelDrawer}
              style={BUTTON_STYLE}
            />
            <CommonButton
              type='submit'
              label={this.state.submitButtonLabel}
              primary={true}
              variant="contained"
              onClick={this.handleSubmit}
              style={BUTTON_STYLE}
            />
          </div>
        </div>

        {
          this.state.enableErrorDialog && (
            <Dialog open={this.state.enableErrorDialog} onClose={this.closeErrorDialog}>
              <DialogTitleWithCloseIcon onClose={this.closeErrorDialog}>
                Business name already exists
              </DialogTitleWithCloseIcon>
              <DialogContent>
                Company with this Business Name already exists on AgriChain with another ABN. 
                Do you want to add the existing Company or update Business Name to add another company?
                <Typography variant="h5" style={{margin: "15px 0px", fontSize: "20px"}}>
                  Existing Company Details
                </Typography>
                <div className='row'>
                  <div className='col-md-3' style={{margin: "5px 0px", fontSize: '15px'}}>
                    ABN
                  </div>
                  <div className='col-md-3' style={{margin: "5px 0px", fontSize: '15px'}}>
                    {get(this.state.existingCompanyDetails, 'abn')}
                  </div>
                  <div className='col-md-3' style={{margin: "5px 0px", fontSize: '15px'}}>
                    Business Name
                  </div>
                  <div className='col-md-3' style={{margin: "5px 0px", fontSize: '15px'}}>
                    {get(this.state.existingCompanyDetails, 'businessName')}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-3' style={{margin: "5px 0px", fontSize: '15px'}}>
                    Entity Name
                  </div>
                  <div className='col-md-3' style={{margin: "5px 0px", fontSize: '15px'}}>
                    {get(this.state.existingCompanyDetails, 'entityName')}
                  </div>
                  <div className='col-md-3' style={{margin: "5px 0px", fontSize: '15px'}}>
                    Primary Business
                  </div>
                  <div className='col-md-3' style={{margin: "5px 0px", fontSize: '15px'}}>
                    {get(this.state.existingCompanyDetails, 'type.displayName')}
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <Button type='button' color='primary' variant='outlined' onClick={this.closeErrorDialog}>
                  Update Business Name
                </Button>
                <Button type='button' color='primary' variant='outlined' onClick={this.handleAddExistingCompany}>
                  Add Existing Company
                </Button>
              </DialogActions>
            </Dialog>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.main.user.user,
    paymentTerms: state.companies.contracts.paymentTerms,
    serverErrors: state.companies.companies.errors
  };
};

export default connect(mapStateToProps)(NewCompanyFormYetToBeUpdatedToHandleCompanyUpdate);
