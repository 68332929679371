import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { PRIMARY_COLOR_GREEN } from '../../common/constants';
import { get, has, map, isEmpty, isArray, includes, isNumber, isFunction, isEqual, find, isNaN, pickBy, filter, every, sumBy } from 'lodash';
import moment from 'moment';
import {
  TIME_DISPLAY_FORMAT,
  EMPTY_VALUE,
} from '../../common/constants';
import { connect } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import withStyles from '@mui/styles/withStyles';
import TableHead from "@mui/material/TableHead/TableHead";
import { returnFloatFromString, getCountryFormats, getCountryConfig, formatPrice } from '../../common/utils';
import { toNumFromCurrencyValue, toCurrencyValueFromNum, toNumFromTonnageValue } from './utils'
import { EPR_ITEM_TYPE, GRAIN_LEVY_ITEM_TYPE, FREIGHT_CONTRACT_ITEM_TYPE, CARRY_ITEM_TYPE } from "./Constants";
import alertifyjs from "alertifyjs";
import IconButton from '@mui/material/IconButton';
import Create from '@mui/icons-material/Create';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SideDrawer from '../common/SideDrawer';
import AdjustedPrices from './AdjustedPrices';
import QuantityAdjustments from './QuantityAdjustments';
import Visibility from '@mui/icons-material/Visibility';
import { forceStopLoader } from '../../actions/main';


class InvoiceSubTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textLevyOpen: false,
      textEPROpen: false,
      value: undefined,
      item: [],
      index: undefined,
      adjustmentDetailsOpen: false,
      showQuantityAdjustments: false,
      selectedItem: undefined,
    };
    this.getTextField = this.getTextField.bind(this);
    this.handleSubTotalValue = this.handleSubTotalValue.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentDidUpdate() {
    if (get(this.props, 'title') === 'Title Transfers') {
      let tt = find(this.props.items, {itemId: get(this.state.selectedItem, 'itemId')});
      if (!isEqual(tt, this.state.selectedItem)) {
        this.setState({selectedItem: tt});
      }
    }
    if (this.props.isLoading && this.props.title === 'Levy') {
      const { dispatch } = this.props;
      dispatch(forceStopLoader());
    }
  }

  getValueCommon = (value, key, showAsPercentage, roundOffRate=true, currency) => {
    if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(value)) {
      return moment(value, 'YYYY-MM-DD').format(getCountryFormats().date);
    }

    if (/^[0-9]{2}:[0-9]{2}:[0-9]{2}$/.test(value)) {
      return moment(value, 'HH:mm:ss').format(TIME_DISPLAY_FORMAT);
    }

    if (isNumber(value)) {
      const currencySymbol= this.props.currency || currency ;
      if (includes(['rate', 'adjustedPrice'], key) && !roundOffRate) {
        value = value.toString();
        var valueArray = value.split(".");
        if (valueArray.length === 1)
          value = value + ".00";
        else if (valueArray.length > 1)
          value = valueArray[1].length == 1 ? `${value}0` : value;
        valueArray = value.split("-");
        return valueArray.length > 1 ? `-${currencySymbol} ${valueArray[1]}` : `${currencySymbol} ${value}`;
      }
      if (key === 'rate' && showAsPercentage) {
        return value.toFixed(2) + " %";
      }
      if (includes(['rate', 'subTotal', 'gst', 'total', 'rateExGST', 'totalExGST', 'totalIncGST', 'oversRate', 'totalRate'], key)) {
        if(key == 'subTotal' && (this.state.textLevyOpen || this.state.textEPROpen))
          return value.toFixed(2);
        return formatPrice(value.toFixed(2), 'N/A', currencySymbol)
      }
      return value.toFixed((includes(key, 'specs') || includes(key, 'Specs')) ? 1 : 2);

    }

    return value || EMPTY_VALUE;
  };

  handleBlur(item) {
    if ('subTotal' != document.activeElement.id) {
      this.props.handleSubTotalChange(item, this.state.value);
      this.setState({ textLevyOpen: false, textEPROpen: false });
    }
  }

  getTextField(val, index, item, column) {
    const newState = {...this.state};
    if (val == 'Levy')
      newState.textLevyOpen = true;
    else
      newState.textEPROpen = true;
    this.setState(newState, () => {
      this.setState({index:index, value:this.getValue(item, column)});
    } );
  }

  getValue = (item, column) => {
    if (item) {
      if (column.valueFunction && isFunction(column.valueFunction)) {
        let val = column.valueFunction(item, column);
        if (val) {
          if(isNumber(val) && !isNaN(parseFloat(val)))
            val = returnFloatFromString(val)
          return val;
        }
      }
    }

    var value = EMPTY_VALUE;
    if (has(item, column.key)) {
      value = get(item, column.key);
    } else if (isFunction(column.default)) {
      value = column.default(item);
    } else if (has(column, 'default')) {
      value = column.default;
    }
    let showAsPercentage = get(item, 'description', '').includes("% of Sale Value");
    let valueCommon = this.getValueCommon(value, column.key, showAsPercentage, get(item, 'roundOffRate'), item?.currency);
    if (column.key === 'total' && item.gst === 'N/A') {
      valueCommon = valueCommon + ' *';
    }
    if(isNumber(valueCommon) && !isNaN(parseFloat(valueCommon)))
      valueCommon = returnFloatFromString(valueCommon, get(item, 'roundOffRate') ? 2 : 5)
    return valueCommon;
  };

  isCheckBoxDisabled = item => {
    if(this.props.disableCheckBox)
      return true
    if(item.itemType === FREIGHT_CONTRACT_ITEM_TYPE && item.tonnage === 0)
      return true

    if(includes(['grainlevy', 'epr', 'carry'], item.itemType) && !find(this.props.selectedTransactions, {identifier: item.freightMovementIdentifier || item.titleTransferIdentifier || item.loadIdentifier}))
      return true
    return false
  }

  handleSubTotalValue(event, item) {
    let val = event.target.value;
    this.setState({ value: val ?  `-${val}` : '', item: item });
  }

  handleDisabledCheckboxClick(item) {
    if (item && item.itemType === FREIGHT_CONTRACT_ITEM_TYPE && item.tonnage === 0)
      alertifyjs.alert(
        'Invalid Freight Movement',
        `<div>
              <p>Freight Movement(s) cannot be selected because : </p>
                <ul>
                    <li>Freight Movement has 0 tonnage</li>
                </ul>
           </div>`,
        () => { }
      );
  }

  closeAdjustmentSideDrawer() {
    this.setState({adjustmentDetailsOpen: false, selectedItem: undefined});
  }

  openAdjustmentSideDrawer(item) {
    const newState = {...this.state};
    newState.adjustmentDetailsOpen = true;
    newState.showQuantityAdjustments = false
    newState.selectedItem = item;
    this.setState(newState);
  }

  showQuantityAdjustments = item => this.setState({showQuantityAdjustments: true, selectedItem: item, adjustmentDetailsOpen: false})
  closeQuantityAdjustments = () => this.setState({showQuantityAdjustments: false, selectedItem: undefined})

  getPropsItem() {
    const adjustments = includes([GRAIN_LEVY_ITEM_TYPE, EPR_ITEM_TYPE, CARRY_ITEM_TYPE], get(this.state.selectedItem, 'itemType')) ? this.state.selectedItem : get(this.state.selectedItem, 'adjustmentDetails');
    let _adjustments
    if(adjustments)
      _adjustments = {...adjustments}
    else
      return adjustments

    if(_adjustments?.hasSpecAdjustments) {
      _adjustments.specs = pickBy(_adjustments.specs, val => val.price)
    }
    return _adjustments
  }

  handleShrinkageChange(item, value) {
    this.props.handleShrinkageChange(item, value);
  }

  handleReceivalFeesChange(item, value) {
    this.props.handleReceivalFeesChange(item, value);
  }

  handleCustomAdjustments(item, adjustments) {
    this.props.handleCustomAdjustments(item, adjustments);
  }

  handleLevyStandardDeliveryRateChange(item, adjustments) {
    this.props.handleLevyStandardDeliveryRateChange(item, adjustments)
  }

  getQuantitySpecAdjustments = item => pickBy(item?.adjustmentDetails?.specs, val => val.quantity)

  getTitle = () => {
    const { title, selected, items } = this.props
    let _title = title
    let _selected = selected?.length ? selected : filter(items, {invoice: true})
    if(isEmpty(items))
      return _title
    if(isArray(_selected) && isArray(items))
      return `${_title} (${_selected.length}/${items.length})`
    if(isArray(items))
      return `${_title} (${items.length})`
    return _title
  }

  isAllSelected = () => Boolean(this.props.items?.length && !find(this.props.items, {invoice: false}));
  isSomeSelected = () => {
    const selected = filter(this.props.items, {invoice: true})
    return Boolean(selected.length && selected.length < this.props.items.length)
  }
  isDisabledHeaderCheckbox = () => !this.props.items.length || every(this.props.items, this.isCheckBoxDisabled)

  handleHeaderCheckboxChange = event => {
    event.persist() // filtering checkbox enabled action items and sending them all at once to update selected items
    const filteredItems = this.props.items.filter(item => !this.isCheckBoxDisabled(item))
    this.props.handleCheckboxChange(filteredItems[0], this.props.columns[0], true, filteredItems)(event);

  }

  render() {
    let columns = this.props.columns.length;
    let includeLevyAdjustments = get(this.props.items, '0.levyAdjustmentIncluded');
    const isContract = Boolean(this.props.contractId)
    const title = this.getTitle()
    const selectedItems = filter(this.props.items, {invoice: true})
    return (
      <React.Fragment>
        <TableRow style={{ backgroundColor: '#DCDCDC' }} className={this.props.title}>
          <TableCell style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'left', width: '50px', padding: 0 }}>
            <Checkbox
              indeterminate={this.isSomeSelected()}
              checked={this.isAllSelected()}
              disabled={this.isDisabledHeaderCheckbox()}
              onChange={this.handleHeaderCheckboxChange}
            />
          </TableCell>
          <TableCell colSpan={columns - 1} style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'left', padding: 0 }}>
            {
              this.props.title === 'Levy' ?
                <>
                  <span style={{display: 'inline-block', paddingTop: '10px'}}>{title}</span>
                  <span style={{float: 'right', paddingRight: '12px'}}>
                    <>
                      <Checkbox checked={includeLevyAdjustments} onChange={this.props.toggleLevyAdjustments}/>
                      <span style={{display: 'inline-block', paddingTop: '10px'}}>Include Levy Adjustments</span>
                    </>
                  </span>
                </> :
              title
            }
          </TableCell>
        </TableRow>
        {
          isArray(this.props.items) && !isEmpty(this.props.items) ?
            <React.Fragment>
              <React.Fragment>
                {
                  this.props.items.map((item, index) => {
                    const isCheckBoxDisabled = this.isCheckBoxDisabled(item);
                    return (
                      <TableRow
                        className={this.props.title + ' row-with-data'}
                        key={index}
                        onClick={() => isCheckBoxDisabled ? this.handleDisabledCheckboxClick(item) : null}
                      >
                        {
                          this.props.columns.map((column, i) => {
                            return (
                              <TableCell className={column.className} key={(column.key || '') + i} style={{ whiteSpace: 'normal !important' }}>
                                <span style={{display: 'flex', alignItems: 'center'}}>
                                  {
                                    (column.checkbox ?
                                     <Checkbox
                                       checked={item.invoice}
                                       onChange={this.props.handleCheckboxChange(item, column)}
                                       disabled={isCheckBoxDisabled}
                                     />
                                     :
                                     (column.key == 'subTotal' && (this.props.title == 'Levy' || this.props.title == 'EPR') ?
                                      ((this.state.textLevyOpen && this.state.index == index)|| (this.state.textEPROpen && this.state.index == index))  ?
                                      <TextField
                                        id='subTotal'
                                        onBlur={() => this.handleBlur(item)}
                                        value={this.state.value.toString().replace("-", "")}
                                        onChange={this.handleSubTotalValue}
                                        variant="standard"
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position='start'>
                                              {`-${this.props.currency}`}
                                            </InputAdornment>
                                          )
                                        }}
                                      />
                                      :
                                      <div>
                                        {this.getValue(item, column)}
                                        <IconButton
                                          onClick={() => this.getTextField(this.props.title, index, item, column)}
                                          size="large">
                                          <Create fontSize="small" />
                                        </IconButton>
                                      </div>
                                      : this.getValue(item, column)
                                     )
                                    )
                                  }
                                  {
                                    get(column, 'showDetails') &&
                                      <IconButton
                                        onClick={() => this.openAdjustmentSideDrawer(item)}
                                        size="small">
                                        <Visibility fontSize="inherit"/>
                                      </IconButton>
                                  }
                                  {
                                    get(column, 'showQuantityAdjustments') && !isEmpty(this.getQuantitySpecAdjustments(item)) &&
                                      <IconButton
                                        onClick={() => this.showQuantityAdjustments(item)}
                                        size="small">
                                        <Visibility fontSize="inherit"/>
                                      </IconButton>
                                  }
                                </span>
                              </TableCell>
                            );
                          })
                        }
                      </TableRow>
                    );
                  })
                }
              </React.Fragment>
              <React.Fragment>
                {
                  isContract &&
                    <TableRow>
                      <TableCell colSpan={4} />
                      <TableCell sx={{fontWeight: 'bold', fontSize: '12px', padding: '8px'}}>
                        {
                          ['Title Transfers', 'Freight Movements', 'Levy', 'EPR'].includes(this.props.title) &&
                            `${sumBy(selectedItems, item => toNumFromTonnageValue(item.tonnage)).toFixed(2)} ${this.props.unit}`
                        }
                        </TableCell>
                      <TableCell colSpan={3} />
                      <TableCell sx={{fontWeight: 'bold', fontSize: '12px', padding: '8px'}}>
                        {toCurrencyValueFromNum(sumBy(selectedItems, item => toNumFromCurrencyValue(this.getValue(item, this.props.columns.slice(-3, -2)[0]), this.props.currency)), this.props.currency)}
                      </TableCell>
                      <TableCell sx={{fontWeight: 'bold', fontSize: '12px', padding: '8px'}}>
                        {toCurrencyValueFromNum(sumBy(selectedItems, item => toNumFromCurrencyValue(this.getValue(item, this.props.columns.slice(-2, -1)[0]), this.props.currency)), this.props.currency)}
                      </TableCell>
                      <TableCell sx={{fontWeight: 'bold', fontSize: '12px', padding: '8px'}}>
                        {toCurrencyValueFromNum(sumBy(selectedItems, item => toNumFromCurrencyValue(this.getValue(item, this.props.columns.slice(-1)[0]), this.props.currency)), this.props.currency)}
                      </TableCell>
                    </TableRow>
                }
              </React.Fragment>
            </React.Fragment> :
          <TableRow className={this.props.title}>
            <TableCell colSpan="100" className='no-record-column'>{(this.props.noRecordFoundText) ? this.props.noRecordFoundText : 'No records found.'}</TableCell>
          </TableRow>
        }
        {
          this.state.adjustmentDetailsOpen &&
            <SideDrawer
              isOpen
              title='Rate Breakdown'
              onClose={() => this.closeAdjustmentSideDrawer()}
              size="big"
            >
              <AdjustedPrices
                handleReceivalFeesChange={(item, value) => this.handleReceivalFeesChange(item, value)}
                handleShrinkageChange={(item, value) => this.handleShrinkageChange(item, value)}
                handleCustomAdjustments={(item, adjustments) => this.handleCustomAdjustments(item, adjustments)}
                handleLevyStandardDeliveryRateChange={(item, adjustments)=> this.handleLevyStandardDeliveryRateChange(item, adjustments)}
                closeDrawer={() => this.closeAdjustmentSideDrawer()}
                item={this.getPropsItem()}
                unit={this.props.unit}
                currency={this.props.currency}
                selectedMovementItems={filter(this.props.selectedTransactions, item => item?.itemType == 'freightcontract')}
              />
            </SideDrawer>
        }
        {
          this.state.showQuantityAdjustments &&
            <SideDrawer
              isOpen
              title='Quantity Adjustments'
              onClose={this.closeQuantityAdjustments}
              size="big"
            >
              <QuantityAdjustments
                closeDrawer={this.closeQuantityAdjustments}
                item={this.state.selectedItem}
                unit={this.props.unit}
              />
            </SideDrawer>
        }
      </React.Fragment>
    );
  }
}

const styles = {
  root: {
    color: PRIMARY_COLOR_GREEN,
    '&$checked': {
      color: PRIMARY_COLOR_GREEN,
    },
  },
  checked: {},
};

class InvoiceTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countryConfig: getCountryConfig(),
      menuOpen: null,
      selectedMenu: {
        item: null,
        index: null
      },
      titleTransfers: this.props.titleTransfers,
      customItems: this.props.customItems,
      freightMovements: this.props.freightMovements,
      blendedGradeLoads: this.props.blendedGradeLoads,
      chemicalApplicationItems: this.props.chemicalApplicationItems,
      subscriptions: this.props.subscriptions,
      loads: this.props.loads,
      checkedItem: null,
    };
    this.wrapperRef = React.createRef();
  }

  componentDidUpdate() {
    if (!isEqual(this.props.titleTransfers, this.state.titleTransfers))
      this.setState({ titleTransfers: this.props.titleTransfers });
    if (!isEqual(this.props.customItems, this.state.customItems))
      this.setState({ customItems: this.props.customItems });
    if (!isEqual(this.props.freightMovements, this.state.freightMovements))
      this.setState({ freightMovements: this.props.freightMovements });
    if (!isEqual(this.props.subscriptions, this.state.subscriptions))
      this.setState({ subscriptions: this.props.subscriptions });
    if (!isEqual(this.props.loads, this.state.loads))
      this.setState({ loads: this.props.loads });
    if (!isEqual(this.props.blendedGradeLoads, this.state.blendedGradeLoads))
      this.setState({ blendedGradeLoads: this.props.blendedGradeLoads })
    if (!isEqual(this.props.chemicalApplicationItems, this.state.chemicalApplicationItems))
      this.setState({ chemicalApplicationItems: this.props.chemicalApplicationItems })
  }

  handleCheckboxChange = (item, column, isSelectAll=false, actionItems='') => event => {
    let isChecked = event.target.checked;
    if (isSelectAll && actionItems) {
      this.props[column.onChange](item, isChecked, isSelectAll, actionItems);
    }
    else{
      if ((item.itemType == FREIGHT_CONTRACT_ITEM_TYPE || item.itemType == 'warehouse') && item.tonnage === 0 && isChecked)
        isChecked = false;
      else if (item.itemType === GRAIN_LEVY_ITEM_TYPE || item.itemType === EPR_ITEM_TYPE)
        item.invoice = isChecked;
      this.setState({ checkedItem: item }, () => {
        this.props[column.onChange](item, isChecked);
      });
    }
  };

  handleSubTotalChange = (selectedItem, value) => {
    if ((selectedItem.itemType === GRAIN_LEVY_ITEM_TYPE || selectedItem.itemType === EPR_ITEM_TYPE) && value != undefined) {
      let GST = this.state.countryConfig?.invoicing?.gstRate;
      let valueTotal = parseFloat(value) || 0;
      selectedItem.subTotal = valueTotal;
      if (selectedItem.gst != 'N/A') {
        selectedItem.gst = parseFloat((valueTotal * GST));
        selectedItem.total = parseFloat((valueTotal + selectedItem.gst));
      }
      else { selectedItem.total = valueTotal; }
      this.props.handleSubTotal(selectedItem);
    }
  };

  handleShrinkageChange(item, value) {
    this.props.handleShrinkageChange(item, value);
  }

  handleReceivalFeesChange(item, value) {
    this.props.handleReceivalFeesChange(item, value);
  }

  handleCustomAdjustments(item, adjustments) {
    this.props.handleCustomAdjustments(item, adjustments);
  }

  handleLevyStandardDeliveryRateChange(item, adjustments) {
    this.props.handleLevyStandardDeliveryRateChange(item, adjustments)
  }

  render() {
    const columnData = map(this.props.columns, column => ({ id: column.key, numeric: false, disablePadding: false, label: column.header, className: column.className }))
    const hideLevy = this.state.countryConfig?.invoicing?.hideLevy
    const hideEPR = this.state.countryConfig?.invoicing?.hideEpr
    const selectedTransactions = filter(this.props.selectedItems, item => includes(['freightcontract', 'titletransfer', 'load'], item?.itemType))
    const freightMovementItems = filter(this.state.freightMovements, fm => !fm.isBlendingFees);
    const blendingFeesItems = filter(this.state.freightMovements, fm => fm.isBlendingFees);
    return (
      <div ref={this.wrapperRef} style={{ margin: '15px 0' }}>
        <div style={{ overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                {columnData.map((column, index) => {
                  return (
                    <TableCell
                      className={column.className || ''}
                      key={index}
                    >
                      {column.label}
                    </TableCell>
                  );
                }, this)}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.props.isBlendedContract && !this.props.useContractPriceForInvoicing &&
                  <InvoiceSubTable
                    title='Freight Movements'
                    items={this.state.blendedGradeLoads}
                    selected={filter(this.props.selectedItems, selectedItem => selectedItem.itemType === 'load' && selectedItem.isBlended && !selectedItem.isChemicalItem)}
                    columns={this.props.columns}
                    handleCheckboxChange={this.handleCheckboxChange}
                    handleSubTotalChange={this.handleSubTotalChange}
                    handleShrinkageChange={(item, value) => this.handleShrinkageChange(item, value)}
                    handleReceivalFeesChange={(item, value) => this.handleReceivalFeesChange(item, value)}
                    handleCustomAdjustments={(item, value) => this.handleCustomAdjustments(item, value)}
                    unit={this.props.unit}
                    currency={this.props.currency}
                    contractId={this.props.contractId}
                  />
              }
              {
                !this.props.hideFreightItems &&
                  <InvoiceSubTable
                    title='Freight Movements'
                    items={freightMovementItems}
                    selected={filter(this.props.selectedItems, selectedItem => selectedItem.itemType === 'freightcontract' && !selectedItem.isBlendingFees)}
                    columns={this.props.columns}
                    handleCheckboxChange={this.handleCheckboxChange}
                    handleSubTotalChange={this.handleSubTotalChange}
                    handleShrinkageChange={(item, value) => this.handleShrinkageChange(item, value)}
                    handleReceivalFeesChange={(item, value) => this.handleReceivalFeesChange(item, value)}
                    handleCustomAdjustments={(item, value) => this.handleCustomAdjustments(item, value)}
                    unit={this.props.unit}
                    currency={this.props.currency}
                    contractId={this.props.contractId}
                  />
              }
              {
                this.props.isBlendedContract && this.props.useContractPriceForInvoicing &&
                  <InvoiceSubTable
                    title='Blending Fee'
                    items={blendingFeesItems}
                    selected={filter(this.props.selectedItems, selectedItem => selectedItem.itemType === 'freightcontract' && selectedItem.isBlendingFees)}
                    columns={this.props.columns}
                    handleCheckboxChange={this.handleCheckboxChange}
                    handleSubTotalChange={this.handleSubTotalChange}
                    handleShrinkageChange={(item, value) => this.handleShrinkageChange(item, value)}
                    handleReceivalFeesChange={(item, value) => this.handleReceivalFeesChange(item, value)}
                    handleCustomAdjustments={(item, value) => this.handleCustomAdjustments(item, value)}
                    unit={this.props.unit}
                    currency={this.props.currency}
                    contractId={this.props.contractId}
                  />
              }
              {
                this.props.isBlendedContract &&
                  <InvoiceSubTable
                    title='Applications'
                    items={this.state.chemicalApplicationItems}
                    selected={filter(this.props.selectedItems, selectedItem => selectedItem.itemType === 'load' && selectedItem.isBlended && selectedItem.isChemicalItem)}
                    columns={this.props.columns}
                    handleCheckboxChange={this.handleCheckboxChange}
                    handleSubTotalChange={this.handleSubTotalChange}
                    unit={this.props.unit}
                    currency={this.props.currency}
                    contractId={this.props.contractId}
                  />
              }
              {
                this.props.contracts && this.props.contracts.length !== 0 &&
                  <InvoiceSubTable
                    title='Contracts'
                    items={this.props.contracts}
                    columns={this.props.columns}
                    handleCheckboxChange={this.handleCheckboxChange}
                    handleSubTotalChange={this.handleSubTotalChange}
                    disableCheckBox
                    unit={this.props.unit}
                    contractId={this.props.contractId}
                  />
              }
              {
                this.state.subscriptions && this.state.subscriptions.length !== 0 &&
                  <InvoiceSubTable
                    title='Periodic Brokerage Fees'
                    items={this.state.subscriptions}
                    columns={this.props.columns}
                    handleCheckboxChange={this.handleCheckboxChange}
                    handleSubTotalChange={this.handleSubTotalChange}
                    unit={this.props.unit}
                    currency={this.props.currency}
                    contractId={this.props.contractId}
                  />
              }
              {this.state.titleTransfers && this.state.titleTransfers.length !== 0 &&
               <InvoiceSubTable
                 title='Title Transfers'
                 items={this.state.titleTransfers}
                 selected={filter(this.props.selectedItems, {itemType: 'titletransfer'})}
                 columns={this.props.columns}
                 handleCheckboxChange={this.handleCheckboxChange}
                 handleSubTotalChange={this.handleSubTotalChange}
                 handleShrinkageChange={(item, value) => this.handleShrinkageChange(item, value)}
                 handleReceivalFeesChange={(item, value) => this.handleReceivalFeesChange(item, value)}
                 handleCustomAdjustments={(item, value) => this.handleCustomAdjustments(item, value)}
                 unit={this.props.unit}
                 currency={this.props.currency}
                 contractId={this.props.contractId}
               />}
              {this.state.loads && this.state.loads.length !== 0 &&
               <InvoiceSubTable
                  title='Canola Loads'
                  items={this.state.loads}
                  columns={this.props.columns}
                  handleCheckboxChange={this.handleCheckboxChange}
                  handleSubTotalChange={this.handleSubTotalChange}
                  handleShrinkageChange={(item, value) => this.handleShrinkageChange(item, value)}
                  handleReceivalFeesChange={(item, value) => this.handleReceivalFeesChange(item, value)}
                  handleCustomAdjustments={(item, value) => this.handleCustomAdjustments(item, value)}
                  currency={this.props.currency}
                  contractId={this.props.contractId}
               />}
              {this.props.inloads && this.props.inloads.length !== 0 &&
               <InvoiceSubTable
                 title='Inloads'
                 items={this.props.inloads}
                 columns={this.props.columns}
                 handleCheckboxChange={this.handleCheckboxChange}
                 handleSubTotalChange={this.handleSubTotalChange}
                 unit={this.props.unit}
                 currency={this.props.currency}
                 contractId={this.props.contractId}
               />}
              {this.props.outloads && this.props.outloads.length !== 0 &&
               <InvoiceSubTable
                 title='Outloads'
                 items={this.props.outloads}
                 columns={this.props.columns}
                 handleCheckboxChange={this.handleCheckboxChange}
                 handleSubTotalChange={this.handleSubTotalChange}
                 unit={this.props.unit}
                 currency={this.props.currency}
                 contractId={this.props.contractId}
               />}
              {this.props.throughputInloads && this.props.throughputInloads.length !== 0 &&
               <InvoiceSubTable
                 title='Throughput Inloads'
                 items={this.props.throughputInloads}
                 columns={this.props.columns}
                 handleCheckboxChange={this.handleCheckboxChange}
                 handleSubTotalChange={this.handleSubTotalChange}
                 unit={this.props.unit}
                 currency={this.props.currency}
                 contractId={this.props.contractId}
               />}
              {this.props.throughputOutloads && this.props.throughputOutloads.length !== 0 &&
               <InvoiceSubTable
                 title='Throughput Outloads'
                 items={this.props.throughputOutloads}
                 columns={this.props.columns}
                 handleCheckboxChange={this.handleCheckboxChange}
                 handleSubTotalChange={this.handleSubTotalChange}
                 unit={this.props.unit}
                 currency={this.props.currency}
                 contractId={this.props.contractId}
               />}
              {this.props.storages && this.props.storages.length !== 0 &&
               <InvoiceSubTable
                 title='Storage Fees'
                 items={this.props.storages}
                 columns={this.props.columns}
                 handleCheckboxChange={this.handleCheckboxChange}
                 handleSubTotalChange={this.handleSubTotalChange}
                 unit={this.props.unit}
                 currency={this.props.currency}
                 contractId={this.props.contractId}
               />}
              {this.props.transfers && this.props.transfers.length !== 0 &&
               <InvoiceSubTable
                 title='Transfers'
                 items={this.props.transfers}
                 columns={this.props.columns}
                 handleCheckboxChange={this.handleCheckboxChange}
                 handleSubTotalChange={this.handleSubTotalChange}
                 unit={this.props.unit}
                 currency={this.props.currency}
                 contractId={this.props.contractId}
               />}
              {this.props.stockSwaps && this.props.stockSwaps.length !== 0 &&
               <InvoiceSubTable
                 title='Stock Swaps'
                 items={this.props.stockSwaps}
                 columns={this.props.columns}
                 handleCheckboxChange={this.handleCheckboxChange}
                 handleSubTotalChange={this.handleSubTotalChange}
                 unit={this.props.unit}
                 currency={this.props.currency}
                 contractId={this.props.contractId}
               />}
              {this.props.regradeReseasons && this.props.regradeReseasons.length !== 0 &&
               <InvoiceSubTable
                 title='Regrade Reseasons'
                 items={this.props.regradeReseasons}
                 columns={this.props.columns}
                 handleCheckboxChange={this.handleCheckboxChange}
                 handleSubTotalChange={this.handleSubTotalChange}
                 unit={this.props.unit}
                 currency={this.props.currency}
                 contractId={this.props.contractId}
               />}
              {get(this.props, 'noshow', true) &&
               <InvoiceSubTable
                 title='Custom Items'
                 items={this.state.customItems}
                 selected={filter(this.props.selectedItems, {itemType: 'customitem'})}
                 columns={this.props.columns}
                 handleCheckboxChange={this.handleCheckboxChange}
                 handleSubTotalChange={this.handleSubTotalChange}
                 unit={this.props.unit}
                 currency={this.props.currency}
                 contractId={this.props.contractId}
               />}
              {this.props.carryItems && this.props.carryItems.length !== 0 &&
               <InvoiceSubTable
                 title='Carry'
                 items={this.props.carryItems}
                 selected={filter(this.props.selectedItems, {itemType: 'carryitem'})}
                 selectedTransactions={selectedTransactions}
                 columns={this.props.columns}
                 handleCheckboxChange={this.handleCheckboxChange}
                 handleSubTotalChange={this.handleSubTotalChange}
                 unit={this.props.unit}
                 currency={this.props.currency}
                 contractId={this.props.contractId}
               />}
              {!hideLevy && this.props.grainLevies && this.props.grainLevies.length !== 0 &&
               <InvoiceSubTable
                 title='Levy'
                 items={this.props.grainLevies}
                 selected={filter(this.props.selectedItems, {itemType: 'grainlevy'})}
                 selectedTransactions={selectedTransactions}
                 columns={this.props.columns}
                 handleCheckboxChange={this.handleCheckboxChange}
                 handleSubTotalChange={this.handleSubTotalChange}
                 toggleLevyAdjustments={this.props.toggleLevyAdjustments}
                 handleLevyStandardDeliveryRateChange={(item, adjustments) => this.handleLevyStandardDeliveryRateChange(item, adjustments)}
                 isLoading={this.props.isLoading}
                 dispatch={this.props.dispatch}
                 unit={this.props.unit}
                 currency={this.props.currency}
                 contractId={this.props.contractId}
               />}
              {!hideEPR && this.props.eprs && this.props.eprs.length !== 0 &&
               <InvoiceSubTable
                 title='EPR'
                 items={this.props.eprs}
                 selected={filter(this.props.selectedItems, {itemType: 'epr'})}
                 selectedTransactions={selectedTransactions}
                 columns={this.props.columns}
                 handleCheckboxChange={this.handleCheckboxChange}
                 handleSubTotalChange={this.handleSubTotalChange}
                 unit={this.props.unit}
                 currency={this.props.currency}
                 contractId={this.props.contractId}
               />}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.main.isLoading,
});

export default withStyles(styles)(connect(mapStateToProps)(InvoiceTable));
